import {html} from "lit-element";
import CtvLitElementBase from "@camtv/front-base-ui/lit/element-base"
import CtvDialogModal from "@camtv/front-base-ui/lit/dialog-modal"
import CtvDialogPopup from "@camtv/front-base-ui/lit/dialog-popup"
import CtvLoadWaiter from '@camtv/front-base-ui/lit/load-waiter'
import CtvDialogModalBodyModule from "@camtv/front-base-ui/lit/dialog-modal-body-module"
import Inputmask from "inputmask"
import Validate from "@camtv/front-base-lib/utilities/validate"

import '@camtv/front-base-css/main.scss'

export default  class CtvStoDialog_StepKycSurvey extends CtvDialogModalBodyModule {

	static get DomTag() { return  'ctv-sto-dialog-step-kyc-survey'; }

	get StepName() { return  'KYC_SURVEY'; }
	get StepLabel() { return  this.Ln('Questionario'); }

	
	GetTranslations() {
		return {
			"Questionario": {
				"it": "Questionario",
				"en": "Survey",
				"de": "Fragebogen",
				"fr": "Enquête",
				"es": "Encuesta"
			},				
			"Le Autorità di Regolamentazione Finanziaria ci impongono la raccolta delle seguenti informazioni.": {
				"it": "Le Autorità di Regolamentazione Finanziaria ci impongono la raccolta delle seguenti informazioni.",
				"en": "Financial Regulatory Authorities require us to collect the following information.", 
				"de": "Die Finanzaufsichtsbehörden verlangen von uns, die folgenden Informationen zu sammeln.",
				"fr": "Les autorités de régulation financière nous demande de collecter les informations ci-dessous",
				"es": "Las autoridades de regulación financiera nos exigen que solicitemos la siguiente información."
			},
			"Telefono": {
				"it": "Telefono",
				"en": "Phone",
				"de": "Telefon",
				"fr": "Téléphone",
				"es": "Teléfono"
			},
			"Data di nascita": {
				"it": "Data di nascita",
				"en": "Date of birth",
				"de": "Geburtsdatum",
				"fr": "Date de naissance",
				"es": "Fecha de nacimiento"
			},
			"Codice Fiscale": {
				"it": "Codice Fiscale",
				"en": "Fiscal Code",
				"de": "Steuernummer",
				"fr": "Code fiscal",
				"es": "CIF"
			},
			"Qual è la fonte di origine dei tuoi fondi?": {
				"it": "Qual è la fonte di origine dei tuoi fondi?",
				"en": "What is the source of your funds?",
				"de": "Was ist die Quelle Ihrer Finanzmittel?",
				"fr": "Quel est votre source de fonds?",
				"es": "¿Cuál es el origen de tus fondos?"
			},
			"Seleziona": {
				"it": "Seleziona",
				"en": "Select",
				"de": "Auswahl",
				"fr": "Sélectionner",
				"es": "Seleccionar"
			},
			"Stipendio": {
				"it": "Stipendio",
				"en": "Salary",
				"de": "Einkommen",
				"fr": "Salaire",
				"es": "Salario"
			},
			"Risparmi": {
				"it": "Risparmi",
				"en": "Savings",
				"de": "Ersparnisse",
				"fr": "Economies",
				"es": "Ahorros"
			},
			"Prestito": {
				"it": "Prestito",
				"en": "Loan",
				"de": "Kredit",
				"fr": "Prêt",
				"es": "Préstamo"
			},
			"Attività professionale": {
				"it": "Attività professionale",
				"en": "Professional occupation",
				"de": "Berufliche Tätigkeit",
				"fr": "Travail professionnel",
				"es": "Actividad profesional"
			},
			"Investimenti": {
				"it": "Investimenti",
				"en": "Investments",
				"de": "Investitionen",
				"fr": "Investissements",
				"es": "Inversiones"
			},
			"Vendita di immobili": {
				"it": "Vendita di immobili",
				"en": "Sale of real estate",
				"de": "Verkauf von Immobilien",
				"fr": "Vente de biens immobiliers",
				"es": "Venta de inmuebles"
			},
			"Eredità": {
				"it": "Eredità",
				"en": "Heredity",
				"de": "Erbschaft",
				"fr": "Hérédité",
				"es": "Herencia"
			},
			"Qual è il tuo stato occupazionale?": {
				"it": "Qual è il tuo stato occupazionale?",
				"en": "What is your employment status?",
				"de": "Was ist Ihr Beschäftigungsstatus?",
				"fr": "Quelle est votre situation d’emploi?",
				"es": "¿Cuál es tu situación laboral?"
			},
			"Impiegato": {
				"it": "Impiegato",
				"en": "Employee",
				"de": "Angestellt",
				"fr": "Employé",
				"es": "Empleado/a"
			},
			"Disoccupato": {
				"it": "Disoccupato",
				"en": "Unemployed",
				"de": "Arbeitslos",
				"fr": "Sans emploi",
				"es": "Desempleado/a"
			},
			"Pensionato": {
				"it": "Pensionato",
				"en": "Retired",
				"de": "Pensioniert",
				"fr": "Retraité",
				"es": "Jubilado/a"
			},
			"Studente": {
				"it": "Studente",
				"en": "Student",
				"de": "Student",
				"fr": "Etudiant",
				"es": "Estudiante"
			},
			"Lavoratore autonomo": {
				"it": "Lavoratore autonomo",
				"en": "Self employed",
				"de": "Selbstständig",
				"fr": "Auto-entrepreneur",
				"es": "Trabajador/a autónomo/a"
			},
			"Sei una persona politicamente esposta?": {
				"it": "Sei una persona politicamente esposta?",
				"en": "Are you a politically exposed person?",
				"de": "Sind Sie eine politisch exponierte Person?",
				"fr": "Êtes-vous une personne exposée politiquement?",
				"es": "¿Eres una persona políticamente expuesta?"
			},
			"Si": {
				"it": "Si",
				"en": "Yes",
				"de": "Ja",
				"fr": "Oui",
				"es": "Sí"
			},
			"No": {
				"it": "No",
				"en": "No",
				"de": "Nein",
				"fr": "Non",
				"es": "No"
			},
			"Annulla": {
				"it": "Annulla",
				"en": "Cancel",
				"de": "Abbrechen",
				"fr": "Annuler",
				"es": "Cancelar"
			},
			"Invia Dati": {
				"it": "Invia Dati",
				"en": "Send Data",
				"de": "Daten Abschicken",
				"fr": "Envoyer des données",
				"es": "Enviar datos"
			},
			"Normativa antiriciclaggio": {
				"it": "Normativa antiriciclaggio",
				"en": "Anti-money laundering legislation",
				"de": "Gesetz zur Bekämpfung der Geldwäsche",
				"fr": "Législation anti-blanchiment",
				"es": "Legislación contra el blanqueo de capitales"
			},
			"Data non valida": {
				"it": "Data non valida",
				"en": "Invalid date value",
				"de": "Ungültiges Datum",
				"fr": "Date invalide",
				"es": "Fecha invalida"
			},
			"Prefisso": {
				"it": "Prefisso",
				"en": "Area code",
				"de": "Vorwahl",
				"fr": "Indicatif régional",
				"es": "Codigo de AREA"
			}
		}
	}

	constructor(ParentModalDialog) {
		super(ParentModalDialog);
	}

	render() {
		let dlg = this.GetModalDialog();
		let data = this.GetModalDialog().Data;
		return html`
			<h3 class="title">${this.Ln("Normativa antiriciclaggio")}</h3>
			<p class="description">
				${this.Ln("Le Autorità di Regolamentazione Finanziaria ci impongono la raccolta delle seguenti informazioni.")}
			</p>
			<form @submit="${(evt) => { evt.preventDefault(); this._OnNext() }}">
				

				<div class="form-row">
					<div class="ctv-input-field select-field ">
						<label class="active">${this.Ln("Qual è la fonte di origine dei tuoi fondi?")}</label>
						<select 
							class="ProofOfFunds" 
							data-validate="required" 
							@click="${(evt) => { evt.target.parentNode.classList.remove("error"); }}"
							>	
							<option selected disabled value="">${this.Ln("Seleziona")}</option>	
							<option value="SALARY" >${this.Ln("Stipendio")}</option>
							<option value="SAVINGS" >${this.Ln("Risparmi")}</option>
							<option value="LOANS" >${this.Ln("Prestito")}</option>
							<option value="PROFESSIONA_JOB" >${this.Ln("Attività professionale")}</option>
							<option value="INVESTMENTS" >${this.Ln("Investimenti")}</option>
							<option value="REAL_ESTATE" >${this.Ln("Vendita di immobili")}</option>
							<option value="HEREDITY" >${this.Ln("Eredità")}</option>
						</select>
						<div class="error">Messagge Error!</div>
					</div>							
				</div>	

				<div class="form-row">
					<div class="ctv-input-field select-field ">
						<label class="active">${this.Ln("Qual è il tuo stato occupazionale?")}</label>
						<select 
							class="Occupation" 
							data-validate="required" 
							@click="${(evt) => { evt.target.parentNode.classList.remove("error"); }}"
							>	
							<option selected disabled value="">${this.Ln("Seleziona")}</option>	
							<option value="EMPLOYED">${this.Ln("Impiegato")}</option>
							<option value="UNEMPLOYED">${this.Ln("Disoccupato")}</option>
							<option value="RETIRED">${this.Ln("Pensionato")}</option>
							<option value="STUDENT">${this.Ln("Studente")}</option>
							<option value="SELF_EMPLOYED">${this.Ln("Lavoratore autonomo")}</option>
						</select>
						<div class="error">Messagge Error!</div>
					</div>							
				</div>

				<div class="form-row">
					<div class="ctv-input-field select-field ">
						<label class="active">${this.Ln("Sei una persona politicamente esposta?")}</label>
						<select 
							class="PoliticallyExposed" 
							data-validate="required" 
							@click="${(evt) => { evt.target.parentNode.classList.remove("error"); }}"
							>	
							<option selected disabled value="">${this.Ln("Seleziona")}</option>	
							<option value="YES">${this.Ln("Si")}</option>
							<option value="NO">${this.Ln("No")}</option>
						</select>
						<div class="error">Messagge Error!</div>
					</div>							
				</div>	

			</form>
			`
	}

	async OnShow() {
		let dlg = this.GetModalDialog();
		dlg.SetRightLink()
		dlg.SetButton(this.Ln("Invia Dati"),() => { this._OnNext() })		
		dlg.show_back_link = false;
		dlg.SetPostFooter(true)
		
		Inputmask().mask(document.querySelectorAll("input"));

		// let selAreaCode = this.querySelector("select.AreaCode")
		// dlg.AreaCodes.forEach((item) => {
		// 	let option = document.createElement("option")
		// 	option.value = item.iso_code;
		// 	option.innerHTML = "+" + item.name
		// 	selAreaCode.append(option)
		// })

		// let area_code = Types.ToString(dlg.Data.area_code).trim().toUpperCase()
		// if (selAreaCode.querySelector("[value='"+area_code+"']") != null)
		// 	selAreaCode.value = area_code
		// this.querySelector("input.Phone").value = Types.ToString(dlg.Data.telephone)
		// this.querySelector("input.BornDate").value = dlg.EnsureValidDateOrEmpty(dlg.Data.born_date)
		this.querySelector("select.ProofOfFunds").value = Types.ToString(dlg.Data.proof_of_funds)
		this.querySelector("select.Occupation").value = Types.ToString(dlg.Data.occupation)
		this.querySelector("select.PoliticallyExposed").value = Types.ToString(dlg.Data.politically_exposed)										
	}

	async _SaveAndValidateData(IsAdmin) {
		let dlg = this.GetModalDialog();
		if (IsAdmin != true && Validate.ValidateForm(this.querySelector("form"), true, false, (inputField, errorMessage) => { dlg._SetFieldError(inputField, errorMessage) })!= true)
			return false;
				
		// let dt = dlg.FormattedStringToDate(this.querySelector("input.BornDate").value)
		// if (dt==null || isFinite(dt) ==false || dt< new Date(1920,0,1) || dt > new Date(2003,0,1)) {
		// 	dlg._SetFieldError(this.querySelector("input.BornDate"), this.Ln("Data non valida"))
		// 	return false;
		// }		
		
		// dlg.Data.area_code = this.querySelector("select.AreaCode").value;		
		// dlg.Data.telephone = this.querySelector("input.Phone").value;		
		// dlg.Data.born_date = dlg.FormattedStringToDate(this.querySelector("input.BornDate").value);
		dlg.Data.proof_of_funds = this.querySelector("select.ProofOfFunds").value;
		dlg.Data.occupation = this.querySelector("select.Occupation").value;
		dlg.Data.politically_exposed = this.querySelector("select.PoliticallyExposed").value;

		dlg.Data.step = this.StepName

		return true;
	}

	async _OnNext() {

		let dlg = this.GetModalDialog();

		if (await this._SaveAndValidateData() != true)
			return false;

		if(await dlg.SaveStep() == false)
			return false;
				
		dlg.Wizard_PageNext()
	}	

}
CtvStoDialog_StepKycSurvey.RegisterElement();

