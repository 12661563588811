import {html} from "lit-element";
import CtvLitElementBase from "@camtv/front-base-ui/lit/element-base"
import CtvDialogModal from "@camtv/front-base-ui/lit/dialog-modal"
import CtvDialogPopup from "@camtv/front-base-ui/lit/dialog-popup"
import CtvLoadWaiter from '@camtv/front-base-ui/lit/load-waiter'
import Cookies from "@camtv/front-base-lib/utilities/cookies"

import CtvKycDialog from "./sto-dialog"

import CtvStoDialog_StepEmailInput from "./sto-dialog-step-email-input"
import CtvStoDialog_StepKycPersonalData from "./sto-dialog-step-kyc-personal-data.js"
import CtvStoDialog_StepKycIDDocFront from "./sto-dialog-step-kyc-iddoc-front"
import CtvStoDialog_StepKycIDDocBack from "./sto-dialog-step-kyc-iddoc-back"
import CtvStoDialog_StepKycUtilityBill from "./sto-dialog-step-kyc-utility-bill"
import CtvStoDialog_StepKycSurvey from "./sto-dialog-step-kyc-survey"
import CtvStoAdminDialog_Review from "./sto-admin-dialog-review"

import '@camtv/front-base-css/main.scss'

import "./sto-dialog.scss"
import Types from "@camtv/front-base-lib/utilities/types";

export default class CtvStoAdminDialog extends CtvKycDialog {

    static get DomTag() { return  'ctv-kyc-admin-dialog'; }

	GetTranslations() {
		return {
			"KYC Admin Review": {
				"it": "KYC Admin Review",
				"en": "KYC Admin Review",
				"de": "KYC Admin Review",
				"fr": "Examen de l’administrateur des connaissances des clients (KYC)",
				"es": "KYC Admin Review"
			},
			"Annulla": {
				"it": "Annulla",
				"en": "Cancel",
				"de": "Abbrechen",
				"fr": "Annuler",
				"es": "Cancelar"
			},
			"Salva": {
				"it": "Salva",
				"en": "Save",
				"de": "Speichern",
				"fr": "Enregistrer",
				"es": "Guardar"
			},
			"Salvataggio in corso...": {
				"it": "Salvataggio in corso...",
				"en": "Saving...",
				"de": "Speicherung läuft",
				"fr": "Enregistrement en cours...",
				"es": "Guardando..."
			},
			"L'operazione non è andata a buon fine. Riprova o contatta l'assistenza {SUPPORT}<br>Numero Verde supporto di Cam.TV 800 303 640.": {
				"it": "L'operazione non è andata a buon fine. Riprova o contatta l'assistenza {SUPPORT}<br>Numero Verde supporto di Cam.TV 800 303 640.",
				"en": "The operation was not successful. Please try again or contact support {SUPPORT}.",
				"de": "Der Vorgang war nicht erfolgreich. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support {SUPPORT}",
				"fr": "L’opération a échoué. Veuillez réessayer ou contactez le support {SUPPORT}",
				"es": "La operación no se ha realizado. Inténtalo de nuevo o contacta con soporte {SUPPORT}"
			}
		}
	}

	constructor() {
		super()		

		this.show_help_link = false;

		this.title_label =`<span>${this.Ln("KYC Admin Review")}</span>`	
		
		//this.modules[7] = new CtvStoAdminDialog_Review(this)
	}

	async Init() {	
		
		this.modules = []
		this.modules.push(new CtvStoDialog_StepEmailInput(this));
		this.modules.push(new CtvStoDialog_StepKycPersonalData(this));
		this.modules.push(new CtvStoDialog_StepKycIDDocFront(this));
		this.modules.push(new CtvStoDialog_StepKycIDDocBack(this));		
		this.modules.push(new CtvStoDialog_StepKycUtilityBill(this));
		this.modules.push(new CtvStoDialog_StepKycSurvey(this));
		//this.modules.push(new CtvStoAdminDialog_Review(this));

		let elBody = this.querySelector(".ctv-dialog-body");
		this.RenderObject(this.RenderBodyCustom(), elBody)

		setTimeout(()=>{

			for(let i=1;i<this.modules.length;i++) {
				//body.append(this.modules[i])				 
				this.modules[i].force_file_load=true		

				let sep = document.createElement("div")
				sep.classList.add("separator")

				switch(i){
					case 1:
					case 5:
						this.querySelector(".col-left").append(this.modules[i])					
						this.querySelector(".col-left").append(sep)
					break;
					case 2:
					case 3:
					case 4:
						this.querySelector(".col-right").append(this.modules[i])
						this.querySelector(".col-right").append(sep)
					break;
					case 6:
						//this.querySelector(".row2").append(this.modules[i])
					break;
				}
			}

			this.UpdateKycEvaluation()
			setTimeout(async ()=>{
				for(let i=1;i<this.modules.length;i++) 
					await this.modules[i].OnShow()
				
				this.SetRightLink(this.Ln("Annulla"), this.Cancel)
				this.SetButton(this.Ln("Salva"), async () => { await this._OnSubmitReview() })
				this.show_back_link = false;

				
			},10)		
		},50)
	
	}

	UpdateKycEvaluation() {
		let data = {};
		try
		{
			data = JSON.parse(this.Data.admin_review_json)
		}
		catch {
			data = {}
		}

		switch (data.Result) {
			case "ACCEPTED":
				this.querySelector(".KYC_EVALUATION").innerHTML = "ACCETTATO"
				break
			case "REJECTED":
				this.querySelector(".KYC_EVALUATION").innerHTML = "RIGETTATO"
				break
			defalut:
				this.querySelector(".KYC_EVALUATION").innerHTML = "NON VALUTATO"
				break
		}
	}

	RenderBodyCustom() {
		return html`		
			<div class="grid-wrapper">
				<div class="row1">
					<div class="col-left">
					</div>
					<div class="col-right">
					</div>
				</div>
				<div class="row2">
					<div class="kyc-status-footer">
						<div><strong>KYC:</strong> <span class="KYC_EVALUATION">NON VALUTATO</span></div>
						<button class="ctv-button outline" @click="${()=>{ this._EditEvaluation() }}">Modifica valutazione</button>
					</div>
				</div>
			</div>				
		`
	}

	async _OnSubmitReview() {

		this.waiter = this.ShowWaiter(this.Ln("Salvataggio in corso..."))
		try {

			for(let i=1;i<this.modules.length;i++) {
				if(typeof(this.modules[i]._SaveAndValidateData) != 'function')
					continue;
				if (await this.modules[i]._SaveAndValidateData(true) != true) {
					this.waiter.remove();
					return false;
				}
			}

			let res = await window.Controller.Api.Kyc.Admin.Save(this.AuthToken, this.Data)
			
			this.waiter.remove();
			this.Ok();
		}
		catch(Ex) {			
			console.log(Ex)
			this.waiter.remove();
			// Qua va la gestione dell'errore con il BodyModule di errore, ora mostro un popup ma è da cambiare con ShowModule
			await CtvDialogPopup.ShowMessageBox("Ooops", this.Ln("L'operazione non è andata a buon fine. Riprova o contatta l'assistenza {SUPPORT}<br>Numero Verde supporto di Cam.TV 800 303 640.")
				.replace("{SUPPORT}", `<a target="_blank"  style="color:#219FFF" data-lang="${this.lang}" href="mailto:support@camtv.io">support@camtv.io</a>`)
			)
		}
	}

	async _EditEvaluation() {
		let dlg = new CtvStoAdminDialog_Review()
		dlg.Data = this.Data

		if (await dlg.Show() == "OK") {
			this.UpdateKycEvaluation()
		}
	}
}
CtvStoAdminDialog.RegisterElement();
