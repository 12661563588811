import {html} from "lit-element";
import CtvLitElementBase from "@camtv/front-base-ui/lit/element-base"
import CtvDialogModal from "@camtv/front-base-ui/lit/dialog-modal"
import CtvDialogPopup from "@camtv/front-base-ui/lit/dialog-popup"
import CtvLoadWaiter from '@camtv/front-base-ui/lit/load-waiter'
import CtvDialogModalBodyModule from "@camtv/front-base-ui/lit/dialog-modal-body-module"
import ethereum_address from "ethereum-address";

import {mnemonicToSeed}  from 'bip39'
import {generateMnemonic}  from 'bip39'
import {setDefaultWordlist} from 'bip39'
import  EthereumHDKey  from 'ethereumjs-wallet/dist/hdkey'
import QRCode from 'qrcode';


import '@camtv/front-base-css/main.scss'

export default  class CtvStoDialog_StepTokenWalletChoice extends CtvDialogModalBodyModule {

	static get DomTag() { return  'ctv-kyc-dialog-step-token-wallet-choice'; }

	get StepName() { return  'PAYOUT_IBAN_INPUT'; }	
	get StepLabel() { return  this.Ln('Token Wallet'); }	


	static get properties() {
        return { 
			phase: {type: String},	
        }
    }

	constructor(ParentModalDialog) {
		super(ParentModalDialog);

		this.phase = "CHOICE"

		let mnemonic = generateMnemonic(128)
		this.vMnemonics = mnemonic.split(' ')
	}

	GetTranslations() {
		return {
			"Token Wallet": {
				"it": "Token Wallet",
				"en": "Token Wallet",
				"de": "Token Wallet",
				"fr": "Token Wallet",
				"es": "Token Wallet"
			},
			"Dati per l'accredito dei tuoi CAM Token": {
				"it": "Dati per l'accredito dei tuoi CAM Token",
				"en": "Data for crediting your CAM Token",
				"de": "Daten zur Gutschrift deiner CAM-Token",
				"fr": "Données pour créditer vos CAM Token",
				"es": "Datos para acreditar sus CAM Token"
			},
			"Complimenti hai quasi finito!": {
				"it": "Complimenti hai quasi finito!",
				"en": "Congratulations you are almost done!",
				"de": "Herzlichen Glückwunsch, du bist fast fertig!",
				"fr": "Félicitations, vous avez presque terminé!",
				"es": "¡Felicidades, casi has terminado!"
			},
			"Ora indica l'indirizzo del Wallet ETH sul quale desideri ricevere i tuoi CAM Token": {
				"it": "Ora indica l'indirizzo del Wallet ETH sul quale desideri ricevere i tuoi CAM Token",
				"en": "Now indicate the address of the ETH Wallet on which you wish to receive your CAM Token",
				"de": "Gib nun die Adresse des ETH Wallets an, auf dem du deine CAM Tokens erhalten möchtest",
				"fr": "Indiquez maintenant l'adresse de l'ETH Wallet sur lequel vous souhaitez recevoir vos CAM Tokens",
				"es": "Ahora indique la dirección de el Wallet ETH en la que desea recibir sus tokens CAM"
			},
			"Ho già un wallet ETH, inviateli al seguente indirizzo": {
				"it": "Ho già un wallet ETH, inviateli al seguente indirizzo",
				"en": "I already have an ETH wallet, send it to me at the following address",
				"de": "Ich habe bereits ein ETH Wallet, sende es an folgende Adresse",
				"fr": "J'ai déjà un wallet ETH, envoyez-les à l'adresse suivante",
				"es": "Ya tengo un wallet ETH, envíalos a la siguiente dirección"
			},
			"Non ho un Wallet ETH": {
				"it": "Non ho un Wallet ETH",
				"en": "I don't have an ETH Wallet",
				"de": "Ich habe kein ETH Wallet",
				"fr": "Je n'ai pas de Wallet ETH",
				"es": "No tengo un Wallet ETH"
			},			
			"Creazione Wallet": {
				"it": "Creazione Wallet",
				"en": "Wallet creation",
				"de": "Wallet-Erstellung",
				"fr": "Création de Wallet",
				"es": "Creación del Wallet"
			},
			"Indirizzo Wallet": {
				"it": "Indirizzo Wallet",
				"en": "Wallet address",
				"de": "Wallet-Adresse",
				"fr": "Adresse du portefeuille",
				"es": "Dirección del Wallet"
			},			
			"Conferma la password del tuo Wallet ETH": {
				"it": "Conferma la password del tuo Wallet ETH",
				"en": "Confirm your ETH Wallet password",
				"de": "Bestätige dein ETH Wallet-Passwort",
				"fr": "Confirmez le mot de passe de votre Wallet ETH",
				"es": "Confirme su contraseña de ETH Wallet"
			},
			"Copia su un foglio le seguenti parole rispettando l’ordine visualizzato. Formeranno la password per accedere al tuo wallet.": {
				"it": "Copia su un foglio le seguenti parole rispettando l’ordine visualizzato. Formeranno la password per accedere al tuo wallet.",
				"en": "Copy the following words onto a sheet respecting the order displayed. They will form the password to access your wallet.",
				"de": "Schreibe die folgenden Wörter in der angezeigten Reihenfolge ein Blatt Papier auf. Sie bilden das Passwort für den Zugriff auf dein Wallet.",
				"fr": "Copiez les mots suivants sur une feuille de papier dans l'ordre affiché. Ils formeront le mot de passe pour accéder à votre wallet.",
				"es": "Copie las siguientes palabras en una hoja de papel en el orden en que se muestran. Formarán la contraseña para acceder a su wallet."
			},
			"ATTENZIONE!": {
				"it": "ATTENZIONE!",
				"en": "WARNING!",
				"de": "WARNUNG!",
				"fr": "ATTENTION!",
				"es": "¡ADVERTENCIA!"
			},
			"Assicurati di non perdere questa lista altrimenti non avrai più accesso al tuo Wallet ETH.": {
				"it": "Assicurati di non perdere questa lista altrimenti non avrai più accesso al tuo Wallet ETH.",
				"en": "Make sure you don't lose this list otherwise you will no longer have access to your ETH Wallet.",
				"de": "Achte darauf, dass du diese Liste nicht verlierst, sonst hast du keinen Zugriff mehr auf dein ETH Wallet.",
				"fr": "Assurez-vous de ne pas perdre cette liste, sinon vous n'aurez plus accès à votre Wallet ETH.",
				"es": "Asegúrese de no perder esta lista; de lo contrario, ya no tendrá acceso a su ETH Wallet."
			},
			"Stampa": {
				"it": "Stampa",
				"en": "Print",
				"de": "Drucken",
				"fr": "Imprimer",
				"es": "Impresión"
			},
			"Clicca sulle parole rispettando l’ordine con il quale le hai copiate.": {
				"it": "Clicca sulle parole rispettando l’ordine con il quale le hai copiate.",
				"en": "Click on the words respecting the order in which you copied them.",
				"de": "Klicke auf die Wörter in der Reihenfolge, in der du sie aufgeschrieben hast.",
				"fr": "Cliquez sur les mots en respectant l'ordre dans lequel vous les avez copiés.",
				"es": "Haga clic en las palabras respetando el orden en que las copió."
			},
			"Indirizzo pubblico cui saranno inviati i CAM Token": {
				"it": "Indirizzo pubblico cui saranno inviati i CAM Token",
				"en": "Public address where the CAM Token will be sent",
				"de": "Öffentliche Adresse, an die CAM-Token gesendet werden",
				"fr": "Adresse publique à laquelle les CAM Token seront envoyés",
				"es": "Dirección pública a la que se enviarán los CAM Token"
			},
			"Avanti": {
				"it": "Avanti",
				"en": "Next",
				"de": "Weiter",
				"fr": "Suivant",
				"es": "Siguiente"
			},
			"Indirizzo Ethereum non valido": {
				"it": "Indirizzo Ethereum non valido",
				"en": "Ethereum address not valid",
				"de": "Ungültige Ethereum-Adresse",
				"fr": "Adresse Ethereum invalide",
				"es": "Dirección de Ethereum no válida"
			},
			"Stampa Mnemonics del Wallet": {
				"it": "Stampa Mnemonics del Wallet",
				"en": "Wallet mnemonics",
				"de": "Brieftasche Mnemonik-Druck",
				"fr": "Wallet Mnémoniques Imprimer",
				"es": "Wallet Mnemotécnica Imprimir"
			},
			"Parole chiave del Wallet dei CAM Token": {
				"it": "Parole chiave del Wallet dei CAM Token",
				"en": "CAM Token Wallet Keywords",
				"de": "Schlüsselwörter der CAM Token Wallet",
				"fr": "Mots-clés du portefeuille de CAM Token",
				"es": "Palabras clave de CAM Token Wallet"
			},
			"Conserva il foglio in un luogo sicuro perché servirà per sbloccare il tuo wallet in caso di bisogno, se lo smarrisci perderai irrimediabilmente i tuoi CAM Token.": {
				"it": "Conserva il foglio in un luogo sicuro perché servirà per sbloccare il tuo wallet in caso di bisogno, se lo smarrisci perderai irrimediabilmente i tuoi CAM Token.",
				"en": "Keep the sheet in a safe place because it will be used to unlock your wallet if you need to. If you lose it you will irretrievably lose your CAM Token.",
				"de": "Bewahre das Blatt an einem sicheren Ort auf, da es verwendet wird, um dein Wallet im Bedarfsfall zu entsperren. Wenn du es verlierst, verlierst du deinen CAM-Token irreparabel.",
				"fr": "Conservez la feuille en lieu sûr car elle servira à déverrouiller votre portefeuille en cas de besoin, si vous la perdez vous perdrez irrémédiablement vos jetons CAM.",
				"es": "Guarde la hoja en un lugar seguro porque se usará para desbloquear su billetera en caso de necesidad, si la pierde perderá irreparablemente sus Tokens CAM."
			},
			"Importante per la gestione del tuo wallet ETH":{
				"it": "Importante per la gestione del tuo wallet ETH",
				"en": "Important for managing your ETH wallet",
				"de": "Wichtig für die Verwaltung deines ETH-Wallets",
				"fr": "Important pour la gestion de votre wallet ETH",
				"es": "Importante para administrar su wallet ETH"
			},
			"Usando queste parole nell'ordine indicato puoi gestire il tuo wallet ETH con all'interno i tuoi CAM Token per esempio con il software MetaMask o MyEtherWallet.": {
				"it": "Usando queste parole nell'ordine indicato puoi gestire il tuo wallet ETH con all'interno i tuoi CAM Token per esempio con il software MetaMask o MyEtherWallet.",
				"en": "Using these words in the order indicated you can manage your ETH wallet with your CAM Token inside for example, with the MetaMask or MyEtherWallet software.",
				"de": "Wenn du diese Wörter in der angegebenen Reihenfolge verwendest, kannst du dein ETH-Wallet mit deinen CAM-Token verwalten, beispielsweise mit der MetaMask- oder MyEtherWallet-Software.",
				"fr": "En utilisant ces mots dans l'ordre indiqué, vous pouvez gérer votre wallet ETH avec vos jetons CAM à l'intérieur, par exemple avec le logiciel MetaMask ou MyEtherWallet.",
				"es": "Usando estas palabras en el orden indicado, puede administrar su wallet ETH con sus tokens CAM adentro, por ejemplo, con el software MetaMask o MyEtherWallet."
			}		
		}
	}

	render() {
		switch(this.phase) {
			case "CHOICE":
			default:
				return this._RenderChoice()
				break;
			case "MNEMONICS":
				return this._RenderMnemonics()
				break;
			case "MNEMONICS_CONFIRM":
				return this._RenderMnemonicsConfirm()
				break;		
			case "ADDRESS_REVIEW":
				return this._RenderAddressReview()
				break;												
		}
	}

	_RenderChoice() {
		return html`
		<div class="success">
			<h3 class="title">${this.Ln("Dati per l'accredito dei tuoi CAM Token")}</h3>
			<p class="description">
				${this.Ln("Complimenti hai quasi finito!")}
				<br>
				${this.Ln("Ora indica l'indirizzo del Wallet ETH sul quale desideri ricevere i tuoi CAM Token")}
			</p>
			<div class="ctv-input-field">
				<div class="options column">
					<div class="ctv-input-field">
						<input type="radio" name="wallet" id="yes-wallet" value="" checked="checked">
						<label for="yes-wallet">
							${this.Ln("Ho già un wallet ETH, inviateli al seguente indirizzo")}
						</label>
					</div>
					<div class="ctv-input-field" style="order: 0;visibility: visible;display: block;">
						<input id="" class="EthAddress"
							type="text"
							required
							/>
						<div class="error">Messagge Error!</div>					
					</div>			
					<div class="ctv-input-field">
						<input type="radio" name="wallet" id="no-wallet" value="">
						<label for="no-wallet">
							${this.Ln("Non ho un Wallet ETH")}
						</label>
					</div>
				</div>
			</div>
			
		</div>
		`
	}

	_RenderMnemonics() {
		return html`
		<div class="success">			
			<h3 class="title">${this.Ln("Creazione Wallet")}</h3>
			<p class="description">
				${this.Ln("Copia su un foglio le seguenti parole rispettando l’ordine visualizzato. Formeranno la password per accedere al tuo wallet.")}
			</p>
			<div class="warning-box">
				<h3 class="title">
					<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M13 14H11V9H13M13 18H11V16H13M1 21H23L12 2L1 21Z" /></svg>
					${this.Ln("ATTENZIONE!")}
				</h3>
				<p class="description">${this.Ln("Assicurati di non perdere questa lista altrimenti non avrai più accesso al tuo Wallet ETH.")}</p>
			</div>
			<div class="word-list order">
				${this.vMnemonics.map((mn)=> html`<span>${mn}</span>`)}
			</div>
			<a @click="${this._OnPrintMnemonics}" class="print-button">
				<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M18,3H6V7H18M19,12A1,1 0 0,1 18,11A1,1 0 0,1 19,10A1,1 0 0,1 20,11A1,1 0 0,1 19,12M16,19H8V14H16M19,8H5A3,3 0 0,0 2,11V17H6V21H18V17H22V11A3,3 0 0,0 19,8Z" /></svg>
				<span>${this.Ln("Stampa")}</span>
			</a>
		</div>
		`
	}

	_RenderMnemonicsConfirm() {
		this.vShuffledMnemonics = this.vMnemonics.map((a) => ({sort: Math.random(), value: a}))
										.sort((a, b) => a.sort - b.sort)
										.map((a) => a.value)
		return html`
		<div class="success">
			<h3 class="title">${this.Ln("Conferma la password del tuo Wallet ETH")}</h3>
			<p class="description">
				${this.Ln("Clicca sulle parole rispettando l’ordine con il quale le hai copiate.")}
			</p>
			<div class="word-list">
				${this.vShuffledMnemonics.map((mn)=> html`<span @click="${(el)=>{ this._OnShuffledMnemonicsClick(el.currentTarget) }}">${mn}</span>`)}
			</div>			
		</div>
		`
	}

	_RenderAddressReview() {
		return html`
		<div class="success">
			<h3 class="title">${this.Ln("Indirizzo Wallet")}</h3>
			<p class="description">
				${this.Ln("Indirizzo pubblico cui saranno inviati i CAM Token")}
			</p>
			<div class="camtoken-qr">
				<div>${this.token_wallet_address}</div>
				<img src="${this.token_wallet_address_qrcode}">
			</div>
		</div>
		`
	}

	async OnShow() {
		let dlg = this.GetModalDialog();
		dlg.SetRightLink("")
		dlg.SetButton(this.Ln("Avanti"),()=>{ 
			this._OnNext()
		})	
		dlg.show_back_link = false;
		dlg.SetPostFooter(true)
		
		if (ethereum_address.isAddress(dlg.Data.token_wallet_address) == true) {
			this.token_wallet_address = dlg.Data.token_wallet_address
			this.token_wallet_address_qrcode = await QRCode.toDataURL(this.token_wallet_address, { errorCorrectionLevel: 'H' })
			this.phase = "ADDRESS_REVIEW"
		}
		else
			this.phase = "MNEMONICS"		

		setTimeout(()=>{
			if (this.querySelector(".EthAddress") != null)
				this.querySelector(".EthAddress").value = dlg.Data.token_wallet_address
		},100)
	}

	async _OnNext() {
		let dlg = this.GetModalDialog();
		switch(this.phase) {
			case "CHOICE":
			default:
				if (this.querySelector("#no-wallet").checked != true) {	
					let elAddress = this.querySelector(".EthAddress")				

					if (ethereum_address.isAddress(elAddress.value) == false) {
						dlg._SetFieldError(elAddress,this.Ln("Indirizzo Ethereum non valido"))
						return;
					}
					this.token_wallet_address = elAddress.value
					this.token_wallet_address_qrcode = await QRCode.toDataURL(this.token_wallet_address, { errorCorrectionLevel: 'H' })
					this.phase = "ADDRESS_REVIEW"								
					
				}
				else
					this.phase = "MNEMONICS"

				dlg.SetButton(this.Ln("Avanti"),()=>{ 
					this._OnNext()
				})						
				break;
			case "MNEMONICS":
					dlg.SetButton()							
					this.phase = "MNEMONICS_CONFIRM"
					break;
			case "MNEMONICS_CONFIRM":
					let seed = await mnemonicToSeed(this.vMnemonics.join(" "))
					let hdWallet = EthereumHDKey.fromMasterSeed(seed)
					let wallet = hdWallet.derivePath("m/44'/60'/0'/0/0" ).getWallet()
					this.token_wallet_address = "0x"+ wallet.getAddress().toString("hex")
					this.token_wallet_address_qrcode = await QRCode.toDataURL(this.token_wallet_address, { errorCorrectionLevel: 'H' })
					this.phase = "ADDRESS_REVIEW"
					dlg.SetButton(this.Ln("Avanti"),()=>{ 
						this._OnNext()
					})						
					break;		
			case "ADDRESS_REVIEW":
					dlg.Data.token_wallet_address = this.token_wallet_address

					dlg.Wizard_PageNext()
					break;												
		}
	}

	async _OnShuffledMnemonicsClick(el) {

		if (this.clickedwordsequence == null)
			this.clickedwordsequence = []
		
		this.clickedwordsequence.push(el.innerText)

		let menmonicssequence = this.vMnemonics.join("_")
		let clickedwordsequencesequence = this.clickedwordsequence.join("_")

		if (menmonicssequence.startsWith(clickedwordsequencesequence) == true) {
			el.style.backgroundColor = "#FF7D00"
			if (menmonicssequence == clickedwordsequencesequence)
			await this._OnNext()
		}
		else 
			this.clickedwordsequence.pop()



	}

	_OnPrintMnemonics() {
		var divToPrint = this.querySelector('.word-list');
		var newWin=window.open('',this.Ln('Stampa Mnemonics del Wallet'));
		newWin.document.open();
		newWin.document.write(`
			<html>
				<head>
					<style>
						body { 
							font-family: sans-serif; text-align: center; 
							counter-reset: section;

						}
						span {
							display: inline-block;
							font-size: 20px;
							padding: 15px;
							margin: 5px 20px;
							border: 1px solid #CCCCCCF0;
							background: #f8f8f880;
							border-radius:5px;
							width: 20%;
							text-align:center;
							position:relative;
						}
						span::before {
							counter-increment: section;
							content: counter(section) ". ";
							position: absolute;
							top: -1px;
							border-radius: 3px;
							font-size: 13px;
							padding: 2px;
							left: 1px;
							color: #7e7e7e;
						}
						p{
							text-align:left;
						}
					</style>
				</head>
				<body onload="window.print()">
					<h1>${this.Ln("Parole chiave del Wallet dei CAM Token")}</h1>
					<p>${this.Ln("Conserva il foglio in un luogo sicuro perché servirà per sbloccare il tuo wallet in caso di bisogno, se lo smarrisci perderai irrimediabilmente i tuoi CAM Token.")}</p>
					${divToPrint.innerHTML}
					<h2>${this.Ln("Importante per la gestione del tuo wallet ETH")}</h2>
					<p>${this.Ln("Usando queste parole nell'ordine indicato puoi gestire il tuo wallet ETH con all'interno i tuoi CAM Token per esempio con il software MetaMask o MyEtherWallet.")}</p>
					<p>MetaMask - https://metamask.io/</p>
					<p>MyEtherWallet - https://www.myetherwallet.com/</p>
				</body>
			</html>
		`);
		newWin.document.close();
		setTimeout(function(){newWin.close();},10);
	}
}
CtvStoDialog_StepTokenWalletChoice.RegisterElement();
