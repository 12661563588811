import {html} from "lit-element";
import CtvLitElementBase from "@camtv/front-base-ui/lit/element-base"
import CtvDialogModal from "@camtv/front-base-ui/lit/dialog-modal"
import CtvDialogPopup from "@camtv/front-base-ui/lit/dialog-popup"
import CtvLoadWaiter from '@camtv/front-base-ui/lit/load-waiter'
import CtvDialogModalBodyModule from "@camtv/front-base-ui/lit/dialog-modal-body-module"

import '@camtv/front-base-css/main.scss'

export default  class CtvStoDialog_StepPaymentSuccess extends CtvDialogModalBodyModule {

	static get DomTag() { return  'ctv-kyc-dialog-step-payment-success'; }


	PAYMENT_SUCCESS
	constructor(ParentModalDialog) {
		super(ParentModalDialog);
	}

	GetTranslations() {
		return {
			"Pagamento avvenuto con successo": {
				"it": "Pagamento avvenuto con successo",
				"en": "Payment successful",
				"de": "Zahlung erfolgreich",
				"fr": "Paiement réussi",
				"es": "Pago exitoso"
			},
			"Grazie! Abbiamo ricevuto {{ Amount }} {{ CryptoType }} per l’acquisto di {{ TokenAmount }} CAM Token. Appena completeremo le nostre verifiche ti invieremo una email al tuo indirizzo {{ email }} con le istruzioni su come completare la procedura per ricevere i tuoi CAM Token.": {
				"it": "Grazie! Abbiamo ricevuto {{ Amount }} {{ CryptoType }} per l’acquisto di {{ TokenAmount }} CAM Token. Appena completeremo le nostre verifiche ti invieremo una email al tuo indirizzo {{ email }} con le istruzioni su come completare la procedura per ricevere i tuoi CAM Token.",
				"en": "Thank you! We received {{Amount}} {{ CryptoType }} for the purchase of {{ TokenAmount }} CAM Token. As soon as we complete our checks we will send you an email to your {{ email }} address with instructions on how to complete the procedure to receive your CAM Token.",
				"de": "Vielen Dank! Wir haben {{ Amount }} {{ CryptoType }} für den Kauf von {{ TokenAmount }} CAM-Token erhalten. Sobald wir unsere Überprüfungen abgeschlossen haben, senden wir dir eine E-Mail an {{ email }} mit Anweisungen wie du den Vorgang abschließt, um deine CAM Token zu erhalten.",
				"fr": "Merci! Nous avons reçu {{ Amount }} {{ CryptoType }} pour l'achat de {{ TokenAmount }} CAM Token. Dès que nous aurons terminé nos vérifications, nous vous enverrons un e-mail à {{ email }} avec des instructions sur la façon de terminer la procédure pour recevoir vos CAM Token.",
				"es": "¡Gracias! Recibimos {{ Amount }} {{ CryptoType }} por la compra de {{ TokenAmount }} CAM Token. Tan pronto como completemos nuestros controles, le enviaremos un correo electrónico a {{ email }} con instrucciones sobre cómo completar el procedimiento para recibir sus CAM Token."
			},
			"Appena completeremo tutte le verifiche riceverai una mail all’indirizzo {{ email }} per completare la procedura e ricevere i tuoi CAM Token.": {
				"it": "Appena completeremo tutte le verifiche riceverai una mail all’indirizzo {{ email }} per completare la procedura e ricevere i tuoi CAM Token.",
				"en": "As soon as we complete all the checks, you will receive an email at {{email}} to complete the procedure and receive your CAM Token.",
				"de": "Sobald wir alle Prüfungen abgeschlossen haben, erhältst du eine E-Mail unter {{ email }}, um den Vorgang abzuschließen und deine CAM-Token zu erhalten.",
				"fr": "Dès que nous aurons terminé toutes les vérifications, vous recevrez un e-mail à {{ email }} pour terminer la procédure et recevoir vos CAM Token.",
				"es": "Tan pronto como completemos todas las comprobaciones, recibirá un correo electrónico a {{ email }} para completar el procedimiento y recibir sus CAM Token."
			}
		}
	}

	render() {
		let dlg = this.GetModalDialog();
		return html`
		<div class="success">
			<div class="success-icon">
				<svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg"><path d="m54 30c0 13.254834-10.745166 24-24 24s-24-10.745166-24-24 10.745166-24 24-24c2.28 0 4.5.33 6.6.93l4.71-4.71c-3.48-1.44-7.29-2.22-11.31-2.22-16.5685425 0-30 13.4314575-30 30 0 7.9564947 3.16070521 15.5871121 8.78679656 21.2132034 5.62609134 5.6260914 13.25670874 8.7867966 21.21320344 8.7867966s15.5871121-3.1607052 21.2132034-8.7867966c5.6260914-5.6260913 8.7867966-13.2567087 8.7867966-21.2132034m-42.27-5.76-4.23 4.26 13.5 13.5 30-30-4.23-4.26-25.77 25.77z" fill="#5fb900"/></svg>
			</div>
			<h3 class="title center">${this.Ln("Pagamento avvenuto con successo")}</h3>
			<p class="description">
				${this.Ln("Grazie! Abbiamo ricevuto {{ Amount }} {{ CryptoType }} per l’acquisto di {{ TokenAmount }} CAM Token. Appena completeremo le nostre verifiche ti invieremo una email al tuo indirizzo {{ email }} con le istruzioni su come completare la procedura per ricevere i tuoi CAM Token.",
				{
					Amount: dlg.Data.crypto_payment_paid_amount,
					CryptoType: dlg.Data.crypto_payment_type,
					TokenAmount: dlg.Data.crypto_payment_paid_amount,
					email: dlg.Data.email,
				})}
			</p>

			<p class="description">
				${this.Ln("Appena completeremo tutte le verifiche riceverai una mail all’indirizzo {{ email }} per completare la procedura e ricevere i tuoi CAM Token.",{email: dlg.Data.email})}
			</p>
		</div>
		`
	}

	async OnShow() {
		let dlg = this.GetModalDialog();
		dlg.SetRightLink("")
		dlg.SetButton(null)		
		dlg.show_back_link = true;		
	}
}
CtvStoDialog_StepPaymentSuccess.RegisterElement();
