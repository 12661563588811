import {html} from "lit-element";
import CtvLitElementBase from "@camtv/front-base-ui/lit/element-base"
import CtvDialogModal from "@camtv/front-base-ui/lit/dialog-modal"
import CtvDialogPopup from "@camtv/front-base-ui/lit/dialog-popup"
import CtvLoadWaiter from '@camtv/front-base-ui/lit/load-waiter'
import Cookies from "@camtv/front-base-lib/utilities/cookies"


import CtvStoDialog_StepEmailInput from "./sto-dialog-step-email-input"
import CtvStoDialog_StepAuthCodeInput from "./sto-dialog-step-authcode-input"

import CtvStoDialog_StepPaymentModeChoice from "./sto-dialog-step-payment-mode-choice"
import CtvStoDialog_StepPaymentSubmit from "./sto-dialog-step-payment-submit"
import CtvStoDialog_StepPaymentSuccess from "./sto-dialog-step-payment-success"
import CtvStoDialog_StepPayOutIbanInput from "./sto-dialog-step-payout-iban-input"
import CtvStoDialog_StepTokenWalletChoice from "./sto-dialog-step-token-wallet-choice"
import CtvStoDialog_StepTokenContractSignature from "./sto-dialog-step-token-contract-signature"
import CtvStoDialog_StepThankYouPage from "./sto-dialog-step-thanyou-page"

import '@camtv/front-base-css/main.scss'

import "./sto-dialog.scss"
import Types from "@camtv/front-base-lib/utilities/types";

export default class CtvTestDialog extends CtvDialogModal {

    static get DomTag() { return  'ctv-sto-test-dialog'; }

	GetTranslations() {
		return {

		}
	}

	constructor() {
		super()		

		this.show_help_link = false;

		this.title_label =`<span>${this.Ln("Crea account")}</span>`

		this.Data = {
			email: "",
			code: "",
		}	

	}

	async Init() {		
		await this.Wizard_Initialize()
	}

	InitSteps(){
		this.modules = []
		this.modules.push(new CtvStoDialog_StepPaymentModeChoice(this));
		this.modules.push(new CtvStoDialog_StepPaymentSubmit(this));
		this.modules.push(new CtvStoDialog_StepPaymentSuccess(this));
		this.modules.push(new CtvStoDialog_StepPayOutIbanInput(this));
		this.modules.push(new CtvStoDialog_StepTokenWalletChoice(this));
		this.modules.push(new CtvStoDialog_StepTokenContractSignature(this));	
		this.modules.push(new CtvStoDialog_StepThankYouPage(this));	
	}

	Wizard_OnPageShow(PageNumber) {
		this.ShowModule(this.modules[PageNumber])
	}

	async Show() {
		this.InitSteps();
		return super.Show();
	}

	_SetFieldError(inputField, errorMessage) {
		if (inputField.tagName.toLowerCase() == "select")
			errorMessage = this.Ln("Effettua una scelta")

		inputField.parentNode.classList.add("error")
		let errLabel = inputField.parentNode.querySelector(".error");
		if (errLabel != null)
			errLabel.innerHTML = errorMessage;
	}

	async _OnNext() {
		this.Wizard_PageNext()
	}	


	async SaveStep() {
		return true;
	}		

	SetPostFooter() {
		let el = document.createElement("a")
		el.href= "#"
		el.classList.add("NEXT_BUTTON")
		el.innerText="Avanti"
		el.onclick = (e)=>{
			e.preventDefault()
			this._OnNext()			
		}

		if (this.querySelector(".NEXT_BUTTON") != null)
			this.querySelector(".NEXT_BUTTON").remove()

		this.querySelector(".ctv-dialog-shadow").append(el)	
	}

	CopyNodeContent (evt) {
		window.getSelection().removeAllRanges();
		const range = document.createRange();
		range.selectNode(evt.currentTarget);
		window.getSelection().addRange(range);
		document.execCommand("copy");
		window.getSelection().removeAllRanges();

		CtvToast.Show(this.Ln("Copiato"), null, 3000);
	}
}
CtvTestDialog.RegisterElement();
