import {html} from "lit-element";
import CtvLitElementBase from "@camtv/front-base-ui/lit/element-base"
import CtvDialogModal from "@camtv/front-base-ui/lit/dialog-modal"
import CtvDialogPopup from "@camtv/front-base-ui/lit/dialog-popup"
import CtvLoadWaiter from '@camtv/front-base-ui/lit/load-waiter'
import Cookies from "@camtv/front-base-lib/utilities/cookies"


import CtvStoDialog_StepEmailInput from "./sto-dialog-step-email-input"
import CtvStoDialog_StepAuthCodeInput from "./sto-dialog-step-authcode-input"


import '@camtv/front-base-css/main.scss'

import "./sto-dialog.scss"
import Types from "@camtv/front-base-lib/utilities/types";

export default class CtvAuthDialog extends CtvDialogModal {

    static get DomTag() { return  'ctv-sto-auth-dialog'; }

	GetTranslations() {
		return {
			"del documento d'identità": {
				"it": "del documento d'identità",
				"en": "of the identity document",
				"de": "des Ausweises",
				"fr": "du document d’identité",
				"es": "del documento de identidad"
			},
			"della patente di guida": {
				"it": "della patente di guida",
				"en": "of the driving license",
				"de": "des Führerscheins",
				"fr": "du permis de conduire",
				"es": "del permiso de conducir"
			},
			"del passaporto": {
				"it": "del passaporto",
				"en": "of the passport",
				"de": "des Reisepasses",
				"fr": "du passeport",
				"es": "del pasaporte"
			},
			"Crea account": {
				"it": "Crea account",
				"en": "Create account",
				"de": "Account erstellen",
				"fr": "Créer un compte",
				"es": "Crear cuenta"
			},
			"Effettua una scelta": {
				"it": "Effettua una scelta",
				"en": "Make a choice",
				"de": "Treffen Sie eine Auswahl",
				"fr": "Faites un choix",
				"es": "Toma una decisión"
			},
			"Caricamento dati": {
				"it": "Caricamento dati",
				"en": "Loading data",
				"de": "Daten werden geladen",
				"fr": "Chargement des données",
				"es": "Cargando datos"
			},
			"Il caricamento dei dati non è andato a buon fine. Riprova più tardi o contatta l'assistenza.": {
				"it": "Il caricamento dei dati non è andato a buon fine. Riprova più tardi o contatta l'assistenza.",
				"en": "An error occurred while loading the data. Please try again later or contact support.",
				"de": "Das Hochladen der Daten ist fehlgeschlagen. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Support",
				"fr": "Une erreur est apparue pendant le chargement des données. Veuillez réessayer plus tard ou contactez le support",
				"es": "Se ha producido un error al cargar los datos. Inténtalo más tarde o contacta con el servicio de asistencia."
			},
			"Salvataggio in corso": {
				"it": "Salvataggio in corso",
				"en": "Saving",
				"de": "Speicherung läuft",
				"fr": "Sauvegarde",
				"es": "Guardando"
			},
			"L'operazione non è andata a buon fine. Riprova o contatta l'assistenza {SUPPORT}<br>Numero Verde supporto di Cam.TV 800 303 640.": {
				"it": "L'operazione non è andata a buon fine. Riprova o contatta l'assistenza {SUPPORT}<br>Numero Verde supporto di Cam.TV 800 303 640.",
				"en": "The operation was not successful. Please try again or contact {SUPPORT}",
				"de": "Der Vorgang war nicht erfolgreich. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support {SUPPORT}",
				"fr": "L’opération a échoué. Veuillez réessayer ou contactez le support {SUPPORT}",
				"es": "La operación no se ha realizado. Inténtalo de nuevo o contacta con soporte {SUPPORT}"
			},
			"Invio nuovo codice di sicurezza":{
				"it": "Invio nuovo codice di sicurezza",
				"en": "Sending new security code",
				"de": "Einen neuen Sicherheitscode senden",
				"fr": "Envoi du nouveau code de sécurité",
				"es": "Envío de nuevo código de seguridad"
			},
			"Verifica in corso":{
				"it": "Verifica in corso",
				"en": "Verifying",
				"de": "Verifizierung läuft",
				"fr": "Vérification en cours",
				"es": "Verificando"
			}
		}
	}

	constructor() {
		super()		

		this.show_help_link = false;

		this.title_label =`<span>${this.Ln("Crea account")}</span>`

		this.Data = {
			email: "",
			code: "",
			auth_token: "",
		}	

	}

	async Init() {		

		this.ShowModule(new CtvStoDialog_StepEmailInput(this))
	}

	async Show() {
		return super.Show();
	}

	async SubmitEmail(CodeResent) {
		
		if (CodeResent == true)
			this.waiter = this.ShowWaiter(this.Ln("Invio nuovo codice di sicurezza"))		
		else
			this.waiter = this.ShowWaiter(this.Ln("Salvataggio in corso"))		
		try {

			let res = await window.Controller.Api.Kyc.RegisterEmail(this.Data.email, this.lang);

			let deltaT = 1
			if (CodeResent == true)
				deltaT = 1500
			
			setTimeout(()=> {
				this.waiter.remove();
				this.ShowModule(new CtvStoDialog_StepAuthCodeInput(this))			
			},deltaT)
		}
		catch(Ex) {			
			this.waiter.remove();
			// Qua va la gestione dell'errore con il BodyModule di errore, ora mostro un popup ma è da cambiare con ShowModule
			await CtvDialogPopup.ShowMessageBox("Ooops", this.Ln("L'operazione non è andata a buon fine. Riprova o contatta l'assistenza {SUPPORT}<br>Numero Verde supporto di Cam.TV 800 303 640.")
				.replace("{SUPPORT}", `<a target="_blank"  style="color:#219FFF" data-lang="${this.lang}" href="mailto:support@camtv.io">support@camtv.io</a>`)
			)
		}
	}

	async SubmitAuthCode() {
		this.waiter = this.ShowWaiter(this.Ln("Verifica in corso"))		
		try {

			let res = await window.Controller.Api.Kyc.CodeCheck(this.Data.email,this.Data.code);

			this.waiter.remove();
			this.Data.auth_token = res.AUTH_TOKEN

			this.Ok()
			
			return true;
		}
		catch(Ex) {			
			this.waiter.remove();
		}

		return false;
	}	

	_SetFieldError(inputField, errorMessage) {
		if (inputField.tagName.toLowerCase() == "select")
			errorMessage = this.Ln("Effettua una scelta")

		inputField.parentNode.classList.add("error")
		let errLabel = inputField.parentNode.querySelector(".error");
		if (errLabel != null)
			errLabel.innerHTML = errorMessage;
	}
}
CtvAuthDialog.RegisterElement();
