import {html} from "lit-element";
import CtvLitElementBase from "@camtv/front-base-ui/lit/element-base"
import CtvDialogModal from "@camtv/front-base-ui/lit/dialog-modal"
import CtvDialogPopup from "@camtv/front-base-ui/lit/dialog-popup"
import CtvLoadWaiter from '@camtv/front-base-ui/lit/load-waiter'
import CtvDialogModalBodyModule from "@camtv/front-base-ui/lit/dialog-modal-body-module"
import IBAN from "iban"
import '@camtv/front-base-css/main.scss'

export default  class CtvStoDialog_StepPayOutIbanInput extends CtvDialogModalBodyModule {

	static get DomTag() { return  'ctv-kyc-dialog-step-payout-iban-input'; }

	get StepName() { return  'PAYOUT_IBAN_INPUT'; }	
	get StepLabel() { return  this.Ln('IBAN Accredito'); }	

	constructor(ParentModalDialog) {
		super(ParentModalDialog);
	}

	GetTranslations() {
		return {
			"IBAN per l'accredito Royalties": {
				"it": "IBAN per l'accredito Royalties",
				"en": "IBAN for royalties crediting",
				"de": "IBAN für Tantiemengutschrift",
				"fr": "IBAN pour créditer les Royalties",
				"es": "IBAN para acreditación de Royalties"
			},
			"Ora inserisci l’IBAN di un tuo conto bancario sul quale ti saranno accreditate le Royalties di Cam.TV": {
				"it": "Ora inserisci l’IBAN di un tuo conto bancario sul quale ti saranno accreditate le Royalties di Cam.TV",
				"en": "Enter the IBAN of your bank account to which the Royalties of Cam.TV will be credited",
				"de": "Gib nun die IBAN Ihres Bankkontos ein, auf dem die Cam.TV Lizenzgebühren gutgeschrieben werden",
				"fr": "Entrez maintenant l'IBAN de votre compte bancaire sur lequel les Royalties Cam.TV seront créditées",
				"es": "Ahora ingrese el IBAN de su cuenta bancaria en la que se acreditarán las Royalties de Cam.TV"
			},
			"IBAN": {
				"it": "IBAN",
				"en": "IBAN",
				"de": "IBAN",
				"fr": "IBAN",
				"es": "IBAN"
			},
			"Avanti": {
				"it": "Avanti",
				"en": "Next",
				"de": "Weiter",
				"fr": "Suivant",
				"es": "Siguiente"
			},
			"IBAN non valido": {
				"it": "IBAN non valido",
				"en": "IBAN not valid",
				"de": "Ungültige IBAN",
				"fr": "IBAN invalide",
				"es": "IBAN no válido"
			},
			"IBAN Accredito": {
				"it": "IBAN Accredito",
				"en": "IBAN Accreditation",
				"de": "IBAN-Akkreditierung",
				"fr": "Accréditation IBAN",
				"es": "IBAN de acreditación"
			}			
		}
	}

	render() {
		return html`
		<div class="success">
			<h3 class="title">${this.Ln("IBAN per l'accredito Royalties")}</h3>
			<p class="description">${this.Ln("Ora inserisci l’IBAN di un tuo conto bancario sul quale ti saranno accreditate le Royalties di Cam.TV")}</p>
			<form @submit="${(evt) => { evt.preventDefault(); this._OnNext() }}">
				<div class="form-row">
					<div class="ctv-input-field ">
						<input class="IBAN" id="ctv-kyc-dialog-step-payout-iban-input_iban-input" 
							type="text"
							required
							@focus="${(evt) => { evt.target.parentNode.classList.remove("error"); }}"
							/>
						<label for="ctv-kyc-dialog-step-payout-iban-input_iban-input">${this.Ln("IBAN")}</label>
						<div class="error">Messagge Error!</div>					
					</div>			
				</div>
			</form>
		</div>
		`
	}

	async OnShow() {
		let dlg = this.GetModalDialog();
		dlg.SetRightLink("")
		dlg.SetButton("Avanti",()=>{ this._OnNext() })		
		dlg.show_back_link = false;
		dlg.SetPostFooter(false)

		this.querySelector(".IBAN").value = dlg.Data.payout_iban

		if(dlg.Data.payout_iban != null && dlg.Data.payout_iban != ""){
			this.querySelector(".IBAN").disabled = true;
		}
	}

	async _OnNext() {
		let dlg = this.GetModalDialog();
		let elIBAN = this.querySelector(".IBAN")
		if (IBAN.isValid(elIBAN.value) == false) {
			dlg._SetFieldError(elIBAN,this.Ln("IBAN non valido"))
			return 
		}

		dlg.Data.payout_iban = elIBAN.value

		if(await dlg.SaveStep() == false)
			return false;
		
		dlg.Wizard_PageNext()

	}
}
CtvStoDialog_StepPayOutIbanInput.RegisterElement();
