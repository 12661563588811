import {html} from "lit-element";
import CtvLitElementBase from "@camtv/front-base-ui/lit/element-base"
import CtvDialogModal from "@camtv/front-base-ui/lit/dialog-modal"
import CtvDialogPopup from "@camtv/front-base-ui/lit/dialog-popup"
import CtvLoadWaiter from '@camtv/front-base-ui/lit/load-waiter'
import CtvToast from "@camtv/front-base-ui/lit/toast";

import CtvStoDialog_StepWelcome from "./sto-dialog-step-welcome"
import CtvStoDialog_StepInvestAmountInput from "./sto-dialog-step-invest-amount-input"
import CtvStoDialog_StepKycPersonalData from "./sto-dialog-step-kyc-personal-data"
import CtvStoDialog_StepKycIDDocFront from "./sto-dialog-step-kyc-iddoc-front"
import CtvStoDialog_StepKycIDDocBack from "./sto-dialog-step-kyc-iddoc-back"
import CtvStoDialog_StepKycUtilityBill from "./sto-dialog-step-kyc-utility-bill"
import CtvStoDialog_StepKycSurvey from "./sto-dialog-step-kyc-survey"
import CtvStoDialog_StepPaymentModeChoice from "./sto-dialog-step-payment-mode-choice"
import CtvStoDialog_StepPaymentSubmit from "./sto-dialog-step-payment-submit"
import CtvStoDialog_StepPaymentSuccess from "./sto-dialog-step-payment-success"
import CtvStoDialog_StepPayOutIbanInput from "./sto-dialog-step-payout-iban-input"
import CtvStoDialog_StepTokenWalletChoice from "./sto-dialog-step-token-wallet-choice"
import CtvStoDialog_StepTokenContractSignature from "./sto-dialog-step-token-contract-signature"
import CtvStoDialog_StepThankYouPage from "./sto-dialog-step-thanyou-page"
import CtvStoDialog_StepVoucherKycSubmitted from "./sto-dialog-step-voucher-kyc-submitted"


import CtvStoDialogPostFooter from "./sto-dialog-post-footer"


import '@camtv/front-base-css/main.scss'

import "./sto-dialog.scss"
import Types from "@camtv/front-base-lib/utilities/types";



export default class CtvKycDialog extends CtvDialogModal {

    static get DomTag() { return  'ctv-kyc-dialog'; }

	GetTranslations() {
		return {
			"del documento d'identità": {
				"it": "del documento d'identità",
				"en": "of the identity document",
				"de": "des Ausweises",
				"fr": "du document d’identité",
				"es": "del documento de identidad"
			},
			"della patente di guida": {
				"it": "della patente di guida",
				"en": "of the driving license",
				"de": "des Führerscheins",
				"fr": "du permis de conduire",
				"es": "del permiso de conducir"
			},
			"del passaporto": {
				"it": "del passaporto",
				"en": "of the passport",
				"de": "des Reisepasses",
				"fr": "du passeport",
				"es": "del pasaporte"
			},
			"Crea account": {
				"it": "Crea account",
				"en": "Create account",
				"de": "Account erstellen",
				"fr": "Créer un compte",
				"es": "Crear cuenta"
			},
			"Effettua una scelta": {
				"it": "Effettua una scelta",
				"en": "Make a choice",
				"de": "Treffen Sie eine Auswahl",
				"fr": "Faites un choix",
				"es": "Toma una decisión"
			},
			"Caricamento dati": {
				"it": "Caricamento dati",
				"en": "Loading data",
				"de": "Daten werden geladen",
				"fr": "Chargement des données",
				"es": "Cargando datos"
			},
			"Il caricamento dei dati non è andato a buon fine. Riprova più tardi o contatta l'assistenza {SUPPORT}<br>Numero Verde supporto di Cam.TV 800 303 640.": {
				"it": "Il caricamento dei dati non è andato a buon fine. Riprova più tardi o contatta l'assistenza {SUPPORT}<br>Numero Verde supporto di Cam.TV 800 303 640.",
				"en": "The data upload was unsuccessful. Please try again later or contact {SUPPORT}",
				"de": "Das Hochladen der Daten ist fehlgeschlagen. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Support {SUPPORT}",
				"fr": "Une erreur est apparue pendant le chargement des données. Veuillez réessayer plus tard ou contactez le support {SUPPORT}",
				"es": "Se ha producido un error al cargar los datos. Inténtalo más tarde o contacta con el servicio de asistencia {SUPPORT}"
			},
			"Salvataggio in corso": {
				"it": "Salvataggio in corso",
				"en": "Saving",
				"de": "Speicherung läuft",
				"fr": "Sauvegarde",
				"es": "Guardando"
			},
			"L'operazione non è andata a buon fine. Riprova o contatta l'assistenza {SUPPORT}<br>Numero Verde supporto di Cam.TV 800 303 640.": {
				"it": "L'operazione non è andata a buon fine. Riprova o contatta l'assistenza {SUPPORT}<br>Numero Verde supporto di Cam.TV 800 303 640.",
				"en": "The operation was not successful. Try again or contact {SUPPORT}",
				"de": "Der Vorgang war nicht erfolgreich. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support {SUPPORT}",
				"fr": "L’opération a échoué. Veuillez réessayer ou contactez le support {SUPPORT}",
				"es": "La operación no se ha realizado. Inténtalo de nuevo o contacta con soporte {SUPPORT}"
			},
			"Creazione nuova richiesta...": {
				"it": "Creazione nuova richiesta...",
				"en": "Creating a new request...",
				"de": "Neue Anfrage erstellen...",
				"fr": "Création d'une nouvelle demande...",
				"es": "Creando una nueva solicitud..."
			},
			"Salvataggio in corso...": {
				"it": "Salvataggio in corso...",
				"en": "Saving...",
				"de": "Speicherung läuft",
				"fr": "Enregistrement en cours...",
				"es": "Guardando..."
			},
			"Ti manca qualche dato o documento? Puoi accedere in qualunque momento con la tua email per completare la procedura di acquisto dei CAM Token.": {
				"it": "Ti manca qualche dato o documento? Puoi accedere in qualunque momento con la tua email per completare la procedura di acquisto dei CAM Token.",
				"en": "Are you missing any data or documents? You can log in at any time with your email to complete the CAM Token purchase procedure.",
				"de": "Fehlen dir Daten oder Dokumente? Du kannst dich jederzeit mit deiner E-Mail-Adresse einloggen, um den CAM-Token-Kaufvorgang abzuschließen.",
				"fr": "Il vous manque des données ou des documents ? Vous pouvez vous connecter à tout moment avec votre e-mail pour terminer la procédure d'achat de CAM Token.",
				"es": "¿Falta algún dato o documento? Puede iniciar sesión en cualquier momento con su correo electrónico para completar el procedimiento de compra del CAM Token."
			}
		}
	}

	get DocumentLabels() {
		return {
			ID_CARD: this.Ln("del documento d'identità"),
			DRIVE_LICENCE: this.Ln("della patente di guida"),
			PASSPORT: this.Ln("del passaporto")
		}
	}

	get RequestStatus() {
		return {
			NEW: 0,
			SUBMITTED: 1,
			APPROVED: 2,
			UNAPPROVED: -1
		}
	}

	constructor() {
		super()		

		this.show_help_link = false;

		this.title_label =`<span>${this.Ln("Crea account")}</span>`

		this.modules = []
		this.header_modules = []
		
		this.start_date_time = new Date()
		
		this.Data = {
			first_name: "",
			last_name: "",
			gender: "",
			address: "",
			city: "",
			zip_code: "",
			province: "",
			residence_country_iso_code: "",
			document_type: "",
			document_number: "",
			document_expiration_date: "",
			area_code: "",
			telephone: "",
			born_date: "",
			proof_of_funds: "",
			occupation: "",
 			politically_exposed: "",
			document_photo_front: "",
			document_photo_back: "",
			selfie_with_document: "",
			utility_bill_photo: "",
			admin_review_json: "",
			kyc_approved: false,
			is_voucher: false,


			status: "INSERT",
			step: "INVEST_AMOUNT_INPUT",
			
			invest_amount_euro: 0,
			token_amount: 0, 
			payment_method: "WIRE_TRANSFER", 
			payment_causal_code: "", 
			crypto_payment_type: "BTC", 
			crypto_payment_address: "",
			crypto_payment_paid_amount: 0,
			crypto_payment_exchange_euro_price: 0,
			crypto_payment_exchange_euro_datetime: "",
			payout_iban: "",
			token_wallet_address: "",
			sto_contract_signed: false,

			btc_to_eur: 0,
			eth_to_eur: 0,		
			crypto_exchange_unix_time: 0,	
		}	

		this.CountriesData = []
		this.AreaCodes = []

		this.show_header = true

		this.AuthToken = ""
	}

	RenderHeader() {
		if (this.show_header != true)
			return html``

		let step = 1;
		let vDivs = []
		let passed = true;
		this.header_modules .forEach((el)=>{

			let clcurrent = ""
			let module = this.modules[this.Wizard_CurrentPage]
			if (module != null) {

				if (el == this.modules[this.Wizard_CurrentPage].StepLabel) {
					clcurrent = "current"
					if (passed != false)
						passed = false;
				}
				let clpassed = "passed"
				if (passed == false)
					clpassed = "" 

				vDivs.push(
				html`<div class="step ${clpassed} ${clcurrent}">
					<span>
						${(passed)?html`
						<svg style="width:24px;height:24px" viewBox="0 0 24 24">
							<path fill="currentColor" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"></path>
						</svg>`:`${step}`}
					</span>
					<div>${el}</div>
				</div>`
				)
			}
			step++
		})

		return html`
		<div class="path-container">
			<div class="steps">
				${vDivs.map((div) => div)}
				<span class="progress"></span>
			</div>
		</div>
		`
	}

	async Init() {	
		if (this.Data.status == "INSERT" || this.Data.status == "SUBMITTING" || this.Data.status == "0" ) {
			//:: TODO: GESTIRE MEGLIO IL CASO CHE UNO RIENTRA
			//if (this.Data.invest_amount_euro == 0) {
				setTimeout(()=> {
					this.show_header = false
					if (this.Data.kyc_approved != true)
						this.ShowModule(new CtvStoDialog_StepWelcome(this));
					else
						this.Wizard_Start()
				},1)	
			//}
		}
		else if (this.Data.status == "ACCEPTED") {
			setTimeout(()=> {
				this.show_header = false
				if (this.Data.kyc_approved != true)
					this.ShowModule(new CtvStoDialog_StepWelcome(this));
				else
					this.Wizard_Start()				
			})	
		}
		else if (this.Data.status == "COMPLETED") {
			setTimeout(()=> {
				this.show_header = false
				this.ShowModule(new CtvStoDialog_StepThankYouPage(this));
			})	
		}

		this.Wizard_Start()
	}
	
	async Wizard_Start() {
		let nPage = this.GetPageNumberFromStep(this.Data.step);
		this.InitSteps();
		this.update()
		await this.Wizard_Initialize(nPage)
		
	}

	Wizard_OnPageShow(PageNumber) {
		this.ResetPostFooter()
		this.ShowModule(this.modules[PageNumber])
		
		if (this.show_header != true)
			return 

		let width = 0
		for(let i=0;i<this.header_modules.length;i++) {
			if (this.header_modules[i] == this.modules[PageNumber].StepLabel) {
				width = i;
				break				
			}
		}

		if (this.header_modules.length > 1) {
			try {
			let elSteps = this.querySelector(".steps")
			let vSteps = this.querySelectorAll(".steps .step span")
			let elLeft = 0;
			let elWidth = 0;
			let elTotalWidth
			if (vSteps.length>1) {
				let rc_steps = elSteps.getBoundingClientRect()
				let rc_1 = vSteps[0].getBoundingClientRect()
				let rc_n = vSteps[vSteps.length-1].getBoundingClientRect()
				elLeft = rc_1.x - rc_steps.x + rc_1.width/2 				
				elWidth = (rc_n.x - rc_steps.x) - 5
			}

			let progress = this.querySelector(".path-container .progress")
			progress.style.left = elLeft + "px"
			progress.style.width = elWidth * ((width) / (this.header_modules.length-1) ) + "px"
			}
			catch (ex)
			{ console.log(ex)}
		}
	}

	InitSteps(){
		this.modules = []

		switch (this.Data.status) {
			default:
			case "INSERT":
			case "SUBMITTING":			
			if (this.Data.is_voucher != true)					
				this.modules.push(new CtvStoDialog_StepInvestAmountInput(this));
			if (this.Data.kyc_approved != true) {
				this.modules.push(new CtvStoDialog_StepKycPersonalData(this));
				this.modules.push(new CtvStoDialog_StepKycIDDocFront(this));
				this.modules.push(new CtvStoDialog_StepKycIDDocBack(this));
				//this.modules.push(new CtvKycDialog_Step5(this));
				this.modules.push(new CtvStoDialog_StepKycUtilityBill(this));
				this.modules.push(new CtvStoDialog_StepKycSurvey(this));			
			}			
			if (this.Data.is_voucher != true) {
				this.modules.push(new CtvStoDialog_StepPaymentModeChoice(this));	
				this.modules.push(new CtvStoDialog_StepPaymentSubmit(this));	
			}
			else
				this.modules.push(new CtvStoDialog_StepVoucherKycSubmitted(this));	
					
			break;
			case "PAID":
				this.show_header = false
				this.modules.push(new CtvStoDialog_StepPaymentSuccess(this));
				break;
			case "ACCEPTED":
				this.show_header = true
				this.modules.push(new CtvStoDialog_StepPayOutIbanInput(this));
				this.modules.push(new CtvStoDialog_StepTokenWalletChoice(this));
				this.modules.push(new CtvStoDialog_StepTokenContractSignature(this));	
				break;
			case "COMPLETED":
				this.show_header = false
				this.modules.push(new CtvStoDialog_StepThankYouPage(this));			
				break;
		}

		this.header_modules = []
		let lastLabel = ""
		this.modules.forEach((el)=>{			
			if (el.StepLabel != lastLabel) {
				this.header_modules.push(el.StepLabel)
				lastLabel = el.StepLabel
			}
		})
	}

	GetPageNumberFromStep(StepName) {
		for(let i=0;i<this.modules.length;i++) {
			if (this.modules.StepName == StepName)
				return i;
		}

		return 0;
	}

	async _LoadAreaCodes() {
		
		const COUNTRIES_URL = "https://s3staticassets.cam.tv/data/countries.json";
		
		try {
			let countries = await (await fetch(COUNTRIES_URL)).json()			
			let AreaCodes = []
			Object.keys(countries).forEach((key) => {
				
				if (!countries[key].P)
					return;

				AreaCodes.push({
					iso_code: key,
					area_code: countries[key].P,
					name: countries[key].P  + " " + countries[key].F,
					country_name: countries[key].N[this.lang] 
				})			
			})
			AreaCodes = AreaCodes.sort((a, b) => {
				return (parseInt(a.area_code) > parseInt(b.area_code) ? 1 : -1)
			})

			return AreaCodes;
		}
		catch(Ex) {
			throw Ex
		}
	}

	async _LoadCountriesData() {
		
		const COUNTRIES_URL = "https://s3staticassets.cam.tv/data/countries.json";
		const defaultLang = "en";
		const availableLangs = ["it", "en"];
		const lang = availableLangs.indexOf(this.lang) < 0 ? defaultLang : this.lang;
		try {
			let countries = await (await fetch(COUNTRIES_URL)).json()			
			let CountriesData = []
			Object.keys(countries).forEach((key)=>{
				
				CountriesData.push({
					iso_code: key,
					name: countries[key].N[lang]  + " " + countries[key].F,
					country_name: countries[key].N[lang] 
				})			
			});

			CountriesData = CountriesData
				.sort((a, b) => a.country_name.localeCompare(b.country_name));

			return CountriesData;
		}
		catch(Ex) {
			throw Ex
		}
	}

	async Show(AuthToken, Data) {
		let w = CtvLoadWaiter.Show(null, this.Ln("Caricamento dati"))
		try {

			this.AuthToken = AuthToken;			
			this.CountriesData = await this._LoadCountriesData();
			this.AreaCodes = await this._LoadAreaCodes();
			
			if (Data == null)
				Data = await window.Controller.Api.Kyc.Get(this.AuthToken);

			// let CurrentLang = Types.ToString(Data["Lang"])
			// if (CurrentLang == "")
			// 	CurrentLang = this.lang

			this.Data = {
				email: Types.ToString(Data["Email"]),
				password: Types.ToString(Data["Password"]),
				country_iso_code: Types.ToString(Data["CountryIsoCode"]),
				first_name: Types.ToString(Data["FirstName"]),
				last_name: Types.ToString(Data["LastName"]),
				gender: Types.ToString(Data["Gender"]),
				address: Types.ToString(Data["Address"]),
				city: Types.ToString(Data["City"]),
				zip_code: Types.ToString(Data["ZipCode"]),
				province: Types.ToString(Data["Province"]),
				residence_country_iso_code: Types.ToString(Data["ResidenceCountryIsoCode"]),
				document_type:Types.ToString(Data["DocumentType"]),
				document_number: Types.ToString(Data["DocumentNumber"]),				
				document_expiration_date: Types.ToString(Data["DocumentExpirationDate"]),				
				area_code: Types.ToString(Data["AreaCode"]),
				telephone : Types.ToString(Data["Telephone"]),
				born_date : Types.ToString(Data["BornDate"]),
				proof_of_funds : Types.ToString(Data["ProofOfFunds"]),
				occupation : Types.ToString(Data["Occupation"]),
				politically_exposed :Types.ToString(Data["PoliticallyExposed"]),
				document_photo_front: Types.ToString(Data["DocumentPhotoFrontUrl"]),
				document_photo_back: Types.ToString(Data["DocumentPhotoBackUrl"]),
				selfie_with_document: Types.ToString(Data["SelfieWithDocumentUrl"]),
				utility_bill_photo: Types.ToString(Data["UtilityBillPhotoUrl"]),
				admin_review_json: Types.ToString(Data["AdminReviewJson"]),
				lang: this.lang,

				kyc_approved: Types.ToBool(Data["KycApproved"]),
			
				status: Types.ToString(Data["Status"]),
				step: Types.ToString(Data["Step"]),
				
				invest_amount_euro: Types.ToFloat(Data["InvestAmountEuro"]),
				token_amount: Types.ToInt(Data["TokenAmount"]), 
				payment_method: Types.ToString(Data["PaymentMethod"]),
				payment_causal_code: Types.ToString(Data["PaymentCausalCode"]),				
				crypto_payment_type: Types.ToString(Data["CryptoPaymentType"]),
				crypto_payment_address: Types.ToString(Data["CryptoPaymentAddress"]),
				crypto_payment_paid_amount: Types.ToFloat(Data["CryptoPaymentPaidAmount"]),
				crypto_payment_exchange_euro_price: Types.ToFloat(Data["CryptoPaymentExchangeEuroPrice"]),
				crypto_payment_exchange_euro_datetime: Types.ToString(Data["CryptoPaymentExchangeEuroDateTime"]),	
				payout_iban: Types.ToString(Data["PayoutIban"]),
				token_wallet_address: Types.ToString(Data["TokenWalletAddress"]),
				sto_contract_signed: Types.ToBool(Data["STOContractSigned"]),								
				is_voucher: Types.ToBool(Data["IsVoucher"]),
				
				btc_to_eur: Types.ToFloat(Data["BtcToEUR"]),
				eth_to_eur: Types.ToFloat(Data["EthToEUR"]),
				crypto_exchange_unix_time : Types.ToInt(Data["CriptoExchangeUnixTime"]),
			}				

			w.remove();

			return super.Show();
		}
		catch (Ex) {
			console.log(Ex);
			w.remove();

			await CtvDialogPopup.ShowMessageBox("Ooops", this.Ln("Il caricamento dei dati non è andato a buon fine. Riprova più tardi o contatta l'assistenza {SUPPORT}<br>Numero Verde supporto di Cam.TV 800 303 640.")
				.replace("{SUPPORT}", `<a target="_blank"  style="color:#219FFF" data-lang="${this.lang}" href="mailto:support@camtv.io">support@camtv.io</a>`)
			)

			return "CANCEL";
		}
	}

	_OnNext() {
		this.Wizard_PageNext()
	}

	async NewInvestment() {		
		let w = this.ShowWaiter(this.Ln("Creazione nuova richiesta..."));
		try{
			let res = await window.Controller.Api.Kyc.New(this.AuthToken);
			this.AuthToken = res.AUTH_TOKEN
			setTimeout(()=>{
				this.Ok()
			}, 500)			
		} 
		catch (Ex){
			w.remove();
			await CtvDialogPopup.ShowMessageBox("Ooops", this.Ln("L'operazione non è andata a buon fine. Riprova o contatta l'assistenza {SUPPORT}<br>Numero Verde supporto di Cam.TV 800 303 640.")
				.replace("{SUPPORT}", `<a target="_blank"  style="color:#219FFF" data-lang="${this.lang}" href="mailto:support@camtv.io">support@camtv.io</a>`)
			)
			return false;
		}		
		return true;
	}

	async SaveStep() {		
		let w = this.ShowWaiter(this.Ln("Salvataggio in corso..."));
		try{
			let res = await window.Controller.Api.Kyc.Save(this.AuthToken, this.Data);

			// Salvo il result delle immagini per evitare di ricaricare ogni volta l'immagine
			this.Data.document_photo_front = res.DocumentPhotoFrontUrl;
			this.Data.document_photo_back = res.DocumentPhotoBackUrl;
			this.Data.utility_bill_photo = res.UtilityBillPhotoUrl;
			this.Data.token_amount = res.TokenAmount;			
			this.Data.btc_to_eur = Types.ToFloat(res.BtcToEUR)
			this.Data.eth_to_eur = Types.ToFloat(res.EthToEUR)
			this.Data.crypto_exchange_unix_time = Types.ToInt(res.CriptoExchangeUnixTime)
		} 
		catch (Ex){
			w.remove();
			await CtvDialogPopup.ShowMessageBox("Ooops", this.Ln("L'operazione non è andata a buon fine. Riprova o contatta l'assistenza {SUPPORT}<br>Numero Verde supporto di Cam.TV 800 303 640.")
				.replace("{SUPPORT}", `<a target="_blank"  style="color:#219FFF" data-lang="${this.lang}" href="mailto:support@camtv.io">support@camtv.io</a>`)
			)
			return false;
		}
		w.remove();
		return true;
	}

	SetPostFooter(ShowBackButton,Text) {
		let dpf = document.querySelector("ctv-sto-dialog-post-footer")
		if (dpf == null) {
			dpf = new CtvStoDialogPostFooter()
			this.querySelector(".ctv-dialog-shadow").append(dpf)			
		}
		if (Text == null)
			Text = this.Ln("Ti manca qualche dato o documento? Puoi accedere in qualunque momento con la tua email per completare la procedura di acquisto dei CAM Token.")
		dpf.text = Text
		dpf.show_back_button = ShowBackButton
		dpf.dlg = this				
	}

	ResetPostFooter() {
		let dpf = document.querySelector("ctv-sto-dialog-post-footer")
		if (dpf != null) 
			dpf.remove();
	}

	//:: Funzioni di contorno
	EnsureValidDateOrEmpty(date) {
		let dt = Types.ToDate(date)
		if (Types.IsDateEmpty(dt) == true)
			return "";

		return Types.Timestamp.Format(dt,"DD/MM/YYYY")
	}

	FormattedStringToDate(sDate) {
		// gg/mm/aaaa
		let vs = Types.ToString(sDate).split("/");
		if (vs==null || vs.length <3)
			return null;
		let d = Types.ToInt(vs[0]);
		let m = Types.ToInt(vs[1])-1;
		let y = Types.ToInt(vs[2]);

		let dt = new Date(y,m,d,12,0,0,0)	// mette le 12 per evitare roll di giorno per via del fuso

		return dt;
	}

	_SetFieldError(inputField, errorMessage) {
		if (inputField.tagName.toLowerCase() == "select")
			errorMessage = this.Ln("Effettua una scelta")

		inputField.parentNode.classList.add("error")
		let errLabel = inputField.parentNode.querySelector(".error");
		if (errLabel != null)
			errLabel.innerHTML = errorMessage;
	}	

	GetTokeAmountFromInvestAmountAndDate(InvestAmount, Date) {
		//return Types.ToInt(Math.ceil(InvestAmount / 1.125))
		//return Types.ToInt(Math.ceil(InvestAmount / 1.187500))
		// return Types.ToInt(Math.ceil(InvestAmount / 1.212500))
		// return Types.ToInt(Math.ceil(InvestAmount / 1.231250))
		// return Types.ToInt(Math.ceil(InvestAmount / 1.237500))
		// return Types.ToInt(Math.ceil(InvestAmount / 1.24375))
		return Types.ToInt(Math.ceil(InvestAmount / 1.25000))
	}

	CopyNodeContent (evt) {
		window.getSelection().removeAllRanges();
		const range = document.createRange();
		range.selectNode(evt.currentTarget);
		window.getSelection().addRange(range);
		document.execCommand("copy");
		window.getSelection().removeAllRanges();

		CtvToast.Show(this.Ln("Copiato"), null, 3000);
	}
}
CtvKycDialog.RegisterElement();
