import {html} from "lit-element";
import CtvLitElementBase from "@camtv/front-base-ui/lit/element-base"
import CtvDialogModal from "@camtv/front-base-ui/lit/dialog-modal"
import CtvDialogPopup from "@camtv/front-base-ui/lit/dialog-popup"
import CtvLoadWaiter from '@camtv/front-base-ui/lit/load-waiter'
import CtvDialogModalBodyModule from "@camtv/front-base-ui/lit/dialog-modal-body-module"

import CtvCameraShotDialogModalBodyModule from "./camera-shot-body-module"

import '@camtv/front-base-css/main.scss'
import Types from "@camtv/front-base-lib/utilities/types";
/* carta identita img back  */
let imgPlaceholder_it = `https://s3staticassets.cam.tv/release/2021-06-11/document-back-it.jpg`;
let imgPlaceholder_en = `https://s3staticassets.cam.tv/release/2021-06-11/document-back-en.jpg`;

/* driver license  img back 
let imgPlaceholder_driverLicence_it = `https://s3staticassets.cam.tv/release/2021-05-05/drive-license-back-it.jpg`;
let imgPlaceholder_driverLicence_en = `https://s3staticassets.cam.tv/release/2021-05-05/drive-license-back-en.jpg`;
*/

export default  class CtvStoDialog_StepKycIDDocBack extends CtvCameraShotDialogModalBodyModule {

	static get DomTag() { return  'ctv-sto-dialog-step-kyc-iddoc-back'; }

	get StepName() { return  'KYC_IDDOC_BACK'; }
	get StepLabel() { return  this.Ln('Documento'); }
	
	GetTranslations() {
		return {
			"Documento": {
				"it": "Documento",
				"en": "Document",
				"de": "Dokument",
				"fr": "Document",
				"es": "Documento"
			},					
			"Scatta una foto del RETRO {0}": {
				"it": "Scatta una foto del RETRO {0}",
				"en": "Take a picture of the BACK side {0}",
				"de": "Fotografieren Sie die Rückseite {0}",
				"fr": "Prenez une photo de dos",
				"es": "Haz una fotografía del reverso {0}"
			},
			"Assicurati di inquadrare tutti e 4 gli angoli del {0} e che i caratteri siano chiari e ben leggibili.": {
				"it": "Assicurati di inquadrare tutti e 4 gli angoli del {0} e che i caratteri siano chiari e ben leggibili.",
				"en": "Make sure you frame all 4 corners of the {0} and that the characters are clear and legible.",
				"de": "Stelle sicher, dass du alle 4 Ecken des {0} einrahmst und die Zeichen klar und lesbar sind.",
				"fr": "Assurez-vous d'encadrer les 4 coins du {0} et que les caractères sont clairs et lisibles.",
				"es": "Asegúrese de enmarcar las 4 esquinas del {0} y de que los caracteres sean claros y legibles."
			},
			"La fotografia {0} è obbligatoria": {
				"it": "La fotografia {0} è obbligatoria",
				"en": "Picture {0} is required",
				"de": "Foto {0} ist erforderlich",
				"fr": "La photo {0} est obligatoire",
				"es": "La fotografía {0} es obligatoria"
			},
			"Immagine obbligatoria": {
				"it": "Immagine obbligatoria",
				"en": "Image is required",
				"de": "Foto ist erforderlich",
				"fr": "Une image est obligatoire",
				"es": "Imagen obligatoria"
			},
			"Scatta": {
				"it": "Scatta",
				"en": "Take",
				"de": "Aufnehmen",
				"fr": "Prendre",
				"es": "Hacer foto"
			},
			"Annulla": {
				"it": "Annulla",
				"en": "Cancel",
				"de": "Abbrechen",
				"fr": "Annuler",
				"es": "Cancelar"
			},
			"Avanti": {
				"it": "Avanti",
				"en": "Next",
				"de": "Weiter",
				"fr": "Suivant",
				"es": "Siguiente"
			}
		}
	}

	constructor(ParentModalDialog) {
		super(ParentModalDialog);

		this.doc_label = "";
		this.img_placeholder = imgPlaceholder_en
		if (Types.ToString(this.lang).toLowerCase() == "it")
			this.img_placeholder = imgPlaceholder_it		
	}

	async OnShow() {
		let dlg = this.GetModalDialog()

		this.image_data = dlg.Data.document_photo_back	

		let dt = dlg.Data.document_type
		let doc_label = dlg.DocumentLabels[dt]

		this.heading_text = html`
			<h3 class="title">${this.Ln("Scatta una foto del RETRO {0}").replace("{0}", doc_label)}</h3>
			<p class="description">${this.Ln("Assicurati di inquadrare tutti e 4 gli angoli del {0} e che i caratteri siano chiari e ben leggibili.").replace("{0}", doc_label)}</p>
			`
		this.error_text = `${this.Ln("La fotografia {0} è obbligatoria").replace("{0}", doc_label)}`
		dlg.SetPostFooter(true)

		await super.OnShow()
	}

	async _SaveAndValidateData(IsAdmin) {
		let dlg = this.GetModalDialog();

		let isValid = await super._OnNext();
		if (IsAdmin != true && isValid == false)
			return false;

		dlg.Data.document_photo_back = this.image_data
		dlg.Data.step = this.StepName

		return true;
	}

	async _OnNext() {

		let dlg = this.GetModalDialog();

		let isValid = await super._OnNext();
		if (isValid == false)
			return false;

		dlg.Data.document_photo_back = this.image_data
		dlg.Data.step = this.StepName

		if(await dlg.SaveStep() == false)
			return false;

		dlg.Wizard_PageNext()
	}

}
CtvStoDialog_StepKycIDDocBack.RegisterElement();
