import {html} from "lit-element";
import CtvLitElementBase from "@camtv/front-base-ui/lit/element-base"
import CtvDialogModal from "@camtv/front-base-ui/lit/dialog-modal"
import CtvDialogPopup from "@camtv/front-base-ui/lit/dialog-popup"
import CtvLoadWaiter from '@camtv/front-base-ui/lit/load-waiter'
import CtvDialogModalBodyModule from "@camtv/front-base-ui/lit/dialog-modal-body-module"

import '@camtv/front-base-css/main.scss'
import CtvStoDialog_StepTokenContractSignature from "./sto-dialog-step-token-contract-signature";

export default  class CtvStoDialog_StepThankYouPage extends CtvDialogModalBodyModule {

	static get DomTag() { return  'ctv-kyc-dialog-step-thankyou-page'; }

	get StepName() { return  'FLOW_SUCCESS_SCREEN'; }	

	constructor(ParentModalDialog) {
		super(ParentModalDialog);
	}

	GetTranslations() {
		return {
			"Congratulazioni!": {
				"it": "Congratulazioni!",
				"en": "Congratulations!",
				"de": "Herzlichen Gluckwünsch!",
				"fr": "Toutes nos félicitations!",
				"es": "¡Felicidades!"
			},
			"Grazie per aver deciso di investire in Cam.TV.": {
				"it": "Grazie per aver deciso di investire in Cam.TV.",
				"en": "Thank you for deciding to invest in Cam.TV.",
				"de": "Vielen Dank, dass du dich für Cam.TV entschieden hast.",
				"fr": "Merci d'avoir décidé d'investir dans Cam.TV.",
				"es": "Gracias por decidir invertir en Cam.TV."
			},
			"La procedura di richiesta CAM Token è stata completata con successo! Nei prossimi giorni ti saranno accereditati sul wallet ethereum con indirizzo che hai specificato.": {
				"it": "La procedura di richiesta CAM Token è stata completata con successo! Nei prossimi giorni ti saranno accereditati sul wallet ethereum con indirizzo che hai specificato.",
				"en": "The CAM Token request procedure has been completed successfully! In the next few days they will be credited to the ethereum wallet with the address you specified.",
				"de": "Das CAM-Token-Anforderungsverfahren wurde erfolgreich abgeschlossen! In den nächsten Tagen werden sie dem Ethereum Wallet mit der von dir angegebenen Adresse gutgeschrieben.",
				"fr": "La procédure de demande de jeton CAM Token s'est terminée avec succès ! Dans les prochains jours, ils seront crédités sur le portefeuille ethereum avec l'adresse que vous avez spécifiée.",
				"es": "¡El procedimiento de solicitud de CAM Token se ha completado con éxito! En los próximos días, se acreditarán en la billetera ethereum con la dirección que especificó."
			},
			"Nuovo investimento": {
				"it": "Nuovo investimento",
				"en": "New investment",
				"de": "Neue Investition",
				"fr": "Nouvel investissement",
				"es": "Nueva inversión"
			},
			"Se desideri ulteriori CAM Token e approfittare dello sconto previsto in questo mese, clicca sul bottone sottostante": {
				"it": "Se desideri ulteriori CAM Token e approfittare dello sconto previsto in questo mese, clicca sul bottone sottostante",
				"en": "If you want more CAM Tokens and to take advantage of this month's discount, click on the button below",
				"de": "Wenn du dir mehr CAM-Tokens wünschst und den Rabatt des Monats nutzen möchtest, klicke auf die Taste unten",
				"fr": "Si vous voulez plus de CAM Token et profitez de la remise de ce mois-ci, cliquez sur le bouton ci-dessous",
				"es": "Si quieres más CAM Token y aprovechar el descuento de este mes, haz clic en el botón de abajo"
			},
			"Se desideri ulteriori CAM Token e approfittare dello sconto previsto in questo mese, {CLICK_HERE_LINK}": {
				"it": "Se desideri ulteriori CAM Token e approfittare dello sconto previsto in questo mese, {CLICK_HERE_LINK}",
				"en": "If you want more CAM Tokens and to take advantage of this month's discount, {CLICK_HERE_LINK}",
				"de": "Wenn du dir mehr CAM-Tokens wünschst und den Rabatt des Monats nutzen möchtest, {CLICK_HERE_LINK}",
				"fr": "Si vous souhaitez plus de jetons CAM et profiter de la remise de ce mois-ci, {CLICK_HERE_LINK}",
				"es": "Si desea obtener más Tokens CAM y aprovechar el descuento de este mes, haga {CLICK_HERE_LINK}"
			},
			"CLICCA QUI": {
				"it": "CLICCA QUI",
				"en": "CLICK HERE",
				"de": "KLICKE HIER",
				"fr": "CLIQUEZ ICI",
				"es": "CLIC AQUÍ"
			}, 
			"Torna a Cam.TV": {
				"it": "Torna a Cam.TV",
				"en": "Return to Cam.TV",
				"de": "Zurück zu Cam.TV",
				"fr": "Retour à Cam.TV",
				"es": "Volver a Cam.TV"
			}
		}
	}

	render() {
		return html`
		<div class="success">
			<div class="success-icon">
				<svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg"><path d="m54 30c0 13.254834-10.745166 24-24 24s-24-10.745166-24-24 10.745166-24 24-24c2.28 0 4.5.33 6.6.93l4.71-4.71c-3.48-1.44-7.29-2.22-11.31-2.22-16.5685425 0-30 13.4314575-30 30 0 7.9564947 3.16070521 15.5871121 8.78679656 21.2132034 5.62609134 5.6260914 13.25670874 8.7867966 21.21320344 8.7867966s15.5871121-3.1607052 21.2132034-8.7867966c5.6260914-5.6260913 8.7867966-13.2567087 8.7867966-21.2132034m-42.27-5.76-4.23 4.26 13.5 13.5 30-30-4.23-4.26-25.77 25.77z" fill="#5fb900"/></svg>
			</div>
			<h3 class="title center">${this.Ln("Congratulazioni!")}</h3>
			<p class="description">
				${this.Ln("Grazie per aver deciso di investire in Cam.TV.")}
			</p>
			<p class="description">
				${this.renderDescription()}
			</p>
		</div>
		`
	}

	renderDescription() {
		let dlg = this.GetModalDialog();
		if (dlg.Data.is_voucher == true) {
			return html`
				${this.Ln("Se desideri ulteriori CAM Token e approfittare dello sconto previsto in questo mese, {CLICK_HERE_LINK}").replace("{CLICK_HERE_LINK}", "")}
				<a @click="${(e)=>{ e.preventDefault(); this.New()}}" data-lang="${this.lang}" href="#">${this.Ln("CLICCA QUI")}</a>.
			`;
		} else {
			return html`
				${this.Ln("Se desideri ulteriori CAM Token e approfittare dello sconto previsto in questo mese, clicca sul bottone sottostante")}
			`;
		}
	}

	async OnShow() {
		let dlg = this.GetModalDialog();
		dlg.SetRightLink("")
		if (dlg.Data.is_voucher == true)
			dlg.SetButton(this.Ln("Torna a Cam.TV"), ()=>{ document.location.href = "https://www.cam.tv/founderarea" })		
		else
			dlg.SetButton(this.Ln("Nuovo investimento"), ()=>{ this.New() })		
		dlg.show_back_link = false;
	}

	async New() {
		let dlg = this.GetModalDialog();
		dlg.NewInvestment()
	}

}
CtvStoDialog_StepThankYouPage.RegisterElement();
