import {html} from "lit-element";
import CtvLitElementBase from "@camtv/front-base-ui/lit/element-base"
import CtvDialogModal from "@camtv/front-base-ui/lit/dialog-modal"
import CtvDialogPopup from "@camtv/front-base-ui/lit/dialog-popup"
import CtvLoadWaiter from '@camtv/front-base-ui/lit/load-waiter'
import CtvDialogModalBodyModule from "@camtv/front-base-ui/lit/dialog-modal-body-module"
import Validate from "@camtv/front-base-lib/utilities/validate"

import Inputmask from "inputmask"


import '@camtv/front-base-css/main.scss'


export default  class CtvStoDialog_StepKycPersonalData extends CtvDialogModalBodyModule {

	static get DomTag() { return  'ctv-sto-dialog-step-kyc-personal-data'; }

	get StepName() { return  'KYC_PERSONAL_DATA'; }
	get StepLabel() { return  this.Ln('Identità'); }


	GetTranslations() {
		return {
			"Identità": {
				"it": "Identità",
				"en": "Identity",
				"de": "Identität",
				"fr": "Identité",
				"es": "Identidad"
			},
			"Le Autorità di Regolamentazione Finanziaria ci impongono la verifica della tua identità:": {
				"it": "Le Autorità di Regolamentazione Finanziaria ci impongono la verifica della tua identità:",
				"en": "The Financial Regulatory Authorities require us to verify your identity:",
				"de": "Die Finanzaufsichtsbehörden verlangen von uns, deine Identität zu prüfen:",
				"fr": "Les Autorités de Régulation Financière nous demandent de vérifier votre identité :",
				"es": "Las autoridades reguladoras financieras nos exigen que verifiquemos su identidad:"
			},
			"Nazionalità": {
				"it": "Nazionalità",
				"en": "Nationality",
				"de": "Nationalität",
				"fr": "Nationalité",
				"es": "Nacionalidad"
			},
			"Scegli la tua nazione": {
				"it": "Scegli la tua nazione",
				"en": "Choose your country",
				"de": "Wählen Sie Ihr Land",
				"fr": "Choisis ton pays",
				"es": "Selecciona el país"
			},
			"Nome": {
				"it": "Nome",
				"en": "First Name",
				"de": "Vorname",
				"fr": "Prénom",
				"es": "Nombre"
			},
			"Cognome": {
				"it": "Cognome",
				"en": "Last Name",
				"de": "Nachname",
				"fr": "Nom de famille",
				"es": "Apellido"
			},
			"Genere": {
				"it": "Genere",
				"en": "Gender",
				"de": "Geschlecht",
				"fr": "Genre",
				"es": "Sexo"
			},
			"Seleziona": {
				"it": "Seleziona",
				"en": "Select",
				"de": "Auswahl",
				"fr": "Sélectionner",
				"es": "Seleccionar"
			},
			"Maschio": {
				"it": "Maschio",
				"en": "Male",
				"de": "Männlich",
				"fr": "Masculin",
				"es": "Hombre"
			},
			"Femmina": {
				"it": "Femmina",
				"en": "Female",
				"de": "Weiblich",
				"fr": "Féminin",
				"es": "Mujer"
			},
			"Preferisco non specificarlo": {
				"it": "Preferisco non specificarlo",
				"en": "I prefer not to say",
				"de": "Ich ziehe es vor, es nicht zu sagen",
				"fr": "Je ne me prononce pas",
				"es": "Prefiero no especificarlo"
			},
			"Indirizzo e numero": {
				"it": "Indirizzo e numero",
				"en": "Address",
				"de": "Adresse",
				"fr": "Adresse",
				"es": "Dirección"
			},
			"Città": {
				"it": "Città",
				"en": "City",
				"de": "Stadt",
				"fr": "Ville",
				"es": "Localidad"
			},
			"CAP": {
				"it": "CAP",
				"en": "Postal Code",
				"de": "Postleitzahl",
				"fr": "Code postal",
				"es": "Código postal"
			},
			"Provincia": {
				"it": "Provincia",
				"en": "Province/Region/County",
				"de": "Bundesland",
				"fr": "Région",
				"es": "Provincia"
			},
			"Tipo di documento": {
				"it": "Tipo di documento",
				"en": "Type of document",
				"de": "Dokumententyp",
				"fr": "Type de document",
				"es": "Tipo de documento"
			},
			"Passaporto": {
				"it": "Passaporto",
				"en": "Passport",
				"de": "Reisepass",
				"fr": "Passeport",
				"es": "Pasaporte"
			},
			"Patente di guida": {
				"it": "Patente di guida",
				"en": "Driving license",
				"de": "Führerschein",
				"fr": "Permis de conduire",
				"es": "Permiso de conducir"
			},
			"Carta d'identità": {
				"it": "Carta d'identità",
				"en": "Identity card",
				"de": "Identitätsnachweis",
				"fr": "Carte d’identité",
				"es": "Carné de identidad"
			},
			"Numero di documento": {
				"it": "Numero di documento",
				"en": "Document number",
				"de": "Dokumentennummer",
				"fr": "Numéro du document",
				"es": "Número de documento"
			},
			"Data di scadenza del documento (gg/mm/aaaa)": {
				"it": "Data di scadenza del documento (gg/mm/aaaa)",
				"en": "Document expiry date (dd/mm/yyyy)",
				"de": "Ablaufdatum des Dokuments (tt/mm/jjjj)",
				"fr": "Date d’expiration du document (jj/mm/aaaa)",
				"es": "Fecha de caducidad del documento (dd/mm/aaaa)"
			},
			"Annulla": {
				"it": "Annulla",
				"en": "Cancel",
				"de": "Abbrechen",
				"fr": "Annuler",
				"es": "Cancelar"
			},
			"Avanti": {
				"it": "Avanti",
				"en": "Next",
				"de": "Weiter",
				"fr": "Suivant",
				"es": "Siguiente"
			},
			"Verifica Identità": {
				"it": "Verifica Identità",
				"en": "Verify Identity",
				"de": "Identität überprüfen",
				"fr": "Vérifier l'identité",
				"es": "Vérifier l'identité"
			},
			"Data non valida": {
				"it": "Data non valida",
				"en": "Invalid date value",
				"de": "Ungültiges Datum",
				"fr": "Date invalide",
				"es": "Fecha invalida"
			},
			"Paese di residenza": {
				"it": "Paese di residenza",
				"en": "Country of residence",
				"de": "Land des Wohnsitzes",
				"fr": "Pays de résidence",
				"es": "País de residencia"
			},
			"Prefisso": {
				"it": "Prefisso",
				"en": "Area code",
				"de": "Vorwahl",
				"fr": "Indicatif régional",
				"es": "Codigo de AREA"
			},
			"Telefono": {
				"it": "Telefono",
				"en": "Phone",
				"de": "Telefon",
				"fr": "Téléphone",
				"es": "Teléfono"
			},
			"Data di nascita": {
				"it": "Data di nascita",
				"en": "Date of birth",
				"de": "Geburtsdatum",
				"fr": "Date de naissance",
				"es": "Fecha de nacimiento"
			},
		}
	}

	constructor(ParentModalDialog) {
		super(ParentModalDialog);
	}

	render() {

		let data = this.GetModalDialog().Data;
		return html`
			<h3 class="title">${this.Ln("Verifica Identità")}</h3>
			<p class="description">
				${this.Ln("Le Autorità di Regolamentazione Finanziaria ci impongono la verifica della tua identità:")}
			</p>
			<form @submit="${async (evt) => { evt.preventDefault(); await this._OnNext() }}">
				<div class="form-row">
					<div class="ctv-input-field select-field ">
						<label class="active">${this.Ln("Nazionalità")}</label>
						<select 
							class="Country" 
							data-validate="required" 
							@click="${(evt) => { evt.target.parentNode.classList.remove("error"); }}"
							>	
							<option selected disabled value="">${this.Ln("Scegli la tua nazione")}</option>						
						</select>
						<div class="error">Messagge Error!</div>
					</div>							
				</div>
				<div class="form-row">
					<div class="ctv-input-field">
						<input id="ctv-sto-dialog-step-kyc-personal-data_FNAME" 
							class="FirstName" 
							type="text" 
							data-validate="firstname, repeatedchar, names, required" 
							required 
							@focus="${(evt) => { evt.target.parentNode.classList.remove("error"); }}"
							/>
						<label for="ctv-sto-dialog-step-kyc-personal-data_FNAME">${this.Ln("Nome")}</label>
						<div class="error">Messagge Error!</div>
					</div>			
				</div>	
				<div class="form-row">
					<div class="ctv-input-field">
						<input id="ctv-sto-dialog-step-kyc-personal-data_LNAME" 
							class="LastName" 
							type="text" 
							data-validate="lastname, repeatedchar, names, required" 
							required 
							@focus="${(evt) => { evt.target.parentNode.classList.remove("error"); }}"
							/>
						<label for="ctv-sto-dialog-step-kyc-personal-data_LNAME">${this.Ln("Cognome")}</label>
						<div class="error">Messagge Error!</div>
					</div>			
				</div>		
				<div class="form-row group-2-cols">
					<div class="ctv-input-field">
						<input id="ctv-sto-dialog-step-kyc-survey_BORNDATE" 
							class="BornDate" 
							type="text" 
							data-inputmask="'mask': '99/99/9999'"
							data-validate="date, repeatedchar, required" 
							required 
							@focus="${(evt) => { evt.target.parentNode.classList.remove("error"); }}"
							/>
						<label for="ctv-sto-dialog-step-kyc-survey_BORNDATE">${this.Ln("Data di nascita")}</label>
						<div class="error">Messagge Error!</div>
					</div>	
					<div class="ctv-input-field select-field ">
						<label class="active">${this.Ln("Genere")}</label>
						<select 
							class="Gender" 
							data-validate="required" 
							@click="${(evt) => { evt.target.parentNode.classList.remove("error"); }}"
							>	
							<option selected disabled value="">${this.Ln("Seleziona")}</option>						
							<option value="M">${this.Ln("Maschio")}</option>
							<option value="F">${this.Ln("Femmina")}</option>
							<option value="N">${this.Ln("Preferisco non specificarlo")}</option>
						</select>
						<div class="error">Messagge Error!</div>
					</div>							
				</div>	
				<div class="form-row">
					<div class="ctv-input-field select-field ">
						<label class="active">${this.Ln("Paese di residenza")}</label>
						<select 
							class="CountryOfResidence" 
							data-validate="required" 
							@click="${(evt) => { evt.target.parentNode.classList.remove("error"); }}"
							>
							<option selected disabled value="">${this.Ln("Seleziona")}</option>						
						</select>
						<div class="error">Messagge Error!</div>
					</div>							
				</div>				
				<div class="form-row">
					<div class="ctv-input-field">
						<input id="ctv-sto-dialog-step-kyc-personal-data_ADDRESS" 
							class="Address" 
							type="text" 
							data-validate="address, repeatedchar, required" 
							required 
							@focus="${(evt) => { evt.target.parentNode.classList.remove("error"); }}"
							/>
						<label for="ctv-sto-dialog-step-kyc-personal-data_ADDRESS">${this.Ln("Indirizzo e numero")}</label>
						<div class="error">Messagge Error!</div>
					</div>			
				</div>		
				<div class="form-row">
					<div class="ctv-input-field">
						<input id="ctv-sto-dialog-step-kyc-personal-data_CITY" 
							class="City" 
							type="text" 
							data-validate="city, repeatedchar, required" 
							required 
							@focus="${(evt) => { evt.target.parentNode.classList.remove("error"); }}"
							/>
						<label for="ctv-sto-dialog-step-kyc-personal-data_CITY">${this.Ln("Città")}</label>
						<div class="error">Messagge Error!</div>
					</div>			
				</div>			
				<div class="form-row group-2-cols">
					<div class="ctv-input-field">
						<input id="ctv-sto-dialog-step-kyc-personal-data_ZIP" 
							class="ZipCode" 
							type="text" 
							data-validate="zipcode, repeatedchar" 							 
							@focus="${(evt) => { evt.target.parentNode.classList.remove("error"); }}"							
							/>
						<label for="ctv-sto-dialog-step-kyc-personal-data_ZIP">${this.Ln("CAP")}</label>
						<div class="error">Messagge Error!</div>
					</div>			

					<div class="ctv-input-field">
						<input id="ctv-sto-dialog-step-kyc-personal-data_PROVINCE" 
							class="Province" 
							type="text" 
							data-validate="province, repeatedchar" 
							@focus="${(evt) => { evt.target.parentNode.classList.remove("error"); }}"
							/>
						<label for="ctv-sto-dialog-step-kyc-personal-data_PROVINCE">${this.Ln("Provincia")}</label>
						<div class="error">Messagge Error!</div>
					</div>			
				</div>
				<div class="form-row group-2-cols">
					<div class="ctv-input-field select-field ">
						<label class="active">${this.Ln("Prefisso")}</label>
						<select 
							class="AreaCode" 
							@click="${(evt) => { evt.target.parentNode.classList.remove("error"); }}"
							>
							<option selected disabled value="">${this.Ln("Seleziona")}</option>						
						</select>
						<div class="error">Messagge Error!</div>
					</div>
					<div class="ctv-input-field">
						<input id="ctv-sto-dialog-step-kyc-survey_PHONE" 
							class="Phone" 
							type="tel" 
							data-inputmask-regex="([0-9 ]+)"
							data-validate="required" 
							required 
							@focus="${(evt) => { evt.target.parentNode.classList.remove("error"); }}"
							/>
						<label for="ctv-sto-dialog-step-kyc-survey_PHONE">${this.Ln("Telefono")}</label>
						<div class="error">Messagge Error!</div>
					</div>
		
				</div>					
				<div class="form-row">
					<div class="ctv-input-field select-field ">
						<label class="active">${this.Ln("Tipo di documento")}</label>
						<select 
							class="DocumentType" 
							data-validate="required" 
							@click="${(evt) => { evt.target.parentNode.classList.remove("error"); }}"
							>	
							<option selected disabled value="">${this.Ln("Seleziona")}</option>						
							<option value="PASSPORT">${this.Ln("Passaporto")}</option>	
							<option value="DRIVE_LICENCE">${this.Ln("Patente di guida")}</option>	
							<option value="ID_CARD">${this.Ln("Carta d'identità")}</option>	
						</select>
						<div class="error">Messagge Error!</div>
					</div>							
				</div>							
				<div class="form-row group-2-cols">
					<div class="ctv-input-field">
						<input id="ctv-sto-dialog-step-kyc-personal-data_DOCUMENT_NUM" 
							class="DocumentNumber" 
							type="text" 
							data-validate="required" 
							required 
							@focus="${(evt) => { evt.target.parentNode.classList.remove("error"); }}"
							/>
						<label for="ctv-sto-dialog-step-kyc-personal-data_DOCUMENT_NUM">${this.Ln("Numero di documento")}</label>
						<div class="error">Messagge Error!</div>
					</div>			
					<div class="ctv-input-field">
						<input id="ctv-sto-dialog-step-kyc-personal-data_DOCUMENT_SCAD" 
							class="DocumentExpirationDate" 
							type="text" 
							data-validate="required" 
							data-inputmask="'mask': '99/99/9999'"
							required 
							@focus="${(evt) => { evt.target.parentNode.classList.remove("error"); }}"
							/>
						<label for="ctv-sto-dialog-step-kyc-personal-data_DOCUMENT_SCAD">${this.Ln("Data di scadenza del documento (gg/mm/aaaa)")}</label>
						<div class="error">Messagge Error!</div>
					</div>			
				</div>					
			</form>
			`
			
	}

	async OnShow() {
		let dlg = this.GetModalDialog();		

		dlg.SetRightLink()
		dlg.SetButton(this.Ln("Avanti"),async ()=>{await this._OnNext()})		
		dlg.show_back_link = false;
		dlg.SetPostFooter(true)

		Inputmask().mask(document.querySelectorAll("input"));

		let selCountry = this.querySelector("select.Country")
		dlg.CountriesData.forEach((item)=>{
			let option = document.createElement("option")
			option.value = item.iso_code;
			option.innerHTML = item.name
			selCountry.append(option)
		})

		let selCountryOfResidence = this.querySelector("select.CountryOfResidence")
		dlg.CountriesData.forEach((item)=>{
			let option = document.createElement("option")
			option.value = item.iso_code;
			option.innerHTML = item.name
			selCountryOfResidence.append(option)
		})

		let cisoc = Types.ToString(dlg.Data.country_iso_code).trim().toUpperCase()
		if (selCountry.querySelector("[value='"+cisoc+"']") != null)
			selCountry.value = 	cisoc
		let residence_country_iso_code = Types.ToString(dlg.Data.residence_country_iso_code).trim().toUpperCase()
		if (selCountryOfResidence.querySelector("[value='"+residence_country_iso_code+"']") != null)
			selCountryOfResidence.value = residence_country_iso_code
		this.querySelector("input.FirstName").value = dlg.Data.first_name
		this.querySelector("input.LastName").value = dlg.Data.last_name
		this.querySelector("select.Gender").value = dlg.Data.gender
		this.querySelector("input.Address").value = dlg.Data.address
		this.querySelector("input.City").value = dlg.Data.city
		this.querySelector("input.ZipCode").value = dlg.Data.zip_code
		this.querySelector("input.Province").value = dlg.Data.province
		this.querySelector("select.DocumentType").value = dlg.Data.document_type
		this.querySelector("input.DocumentNumber").value = dlg.Data.document_number 
		this.querySelector("input.DocumentExpirationDate").value = dlg.EnsureValidDateOrEmpty(dlg.Data.document_expiration_date) 
		
		let selAreaCode = this.querySelector("select.AreaCode")
		dlg.AreaCodes.forEach((item) => {
			let option = document.createElement("option")
			option.value = item.iso_code;
			option.innerHTML = "+" + item.name
			selAreaCode.append(option)
		})

		let area_code = Types.ToString(dlg.Data.area_code).trim().toUpperCase()
		if (selAreaCode.querySelector("[value='"+area_code+"']") != null)
			selAreaCode.value = area_code
		this.querySelector("input.Phone").value = Types.ToString(dlg.Data.telephone)
		this.querySelector("input.BornDate").value = dlg.EnsureValidDateOrEmpty(dlg.Data.born_date)		

	}

	async _SaveAndValidateData(IsAdmin) {

		let dlg = this.GetModalDialog();

		if (IsAdmin != true && Validate.ValidateForm(this.querySelector("form"), true, false, (inputField, errorMessage) => { dlg._SetFieldError(inputField, errorMessage) }) != true)
			return false;
		
		let dt = dlg.FormattedStringToDate(this.querySelector("input.DocumentExpirationDate").value)
		if (IsAdmin != true && (dt==null || isFinite(dt) ==false || dt< new Date())) {
			dlg._SetFieldError(this.querySelector("input.DocumentExpirationDate"), this.Ln("Data non valida"))
			return false;
		}	

		dlg.Data.country_iso_code = this.querySelector("select.Country").value;	
		dlg.Data.residence_country_iso_code = this.querySelector("select.CountryOfResidence").value;		
		dlg.Data.first_name = this.querySelector("input.FirstName").value;
		dlg.Data.last_name = this.querySelector("input.LastName").value;
		dlg.Data.gender = this.querySelector("select.Gender").value;
		dlg.Data.address = this.querySelector("input.Address").value;
		dlg.Data.city = this.querySelector("input.City").value;
		dlg.Data.zip_code = this.querySelector("input.ZipCode").value;
		dlg.Data.province = this.querySelector("input.Province").value;
		dlg.Data.document_type = this.querySelector("select.DocumentType").value;
		dlg.Data.document_number = this.querySelector("input.DocumentNumber").value;
		dlg.Data.document_expiration_date = dlg.FormattedStringToDate(this.querySelector("input.DocumentExpirationDate").value)

		dt = dlg.FormattedStringToDate(this.querySelector("input.BornDate").value)
		if (dt == null || isFinite(dt) == false || dt < new Date(1920,0,1) || this.getAge(dt) < 18) {
			dlg._SetFieldError(this.querySelector("input.BornDate"), this.Ln("Data non valida"))
			return false;
		}		
		
		dlg.Data.area_code = this.querySelector("select.AreaCode").value;		
		dlg.Data.telephone = this.querySelector("input.Phone").value;		
		dlg.Data.born_date = dlg.FormattedStringToDate(this.querySelector("input.BornDate").value);

		dlg.Data.step = this.StepName

		return true;
	}

	getAge(birthDate) {
		var today = new Date();
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}

	async _OnNext() {

		if (await this._SaveAndValidateData() != true)
			return false;
		
		let dlg = this.GetModalDialog();
		if(await dlg.SaveStep() == false)
			return false;

		dlg.Wizard_PageNext()
	}
}
CtvStoDialog_StepKycPersonalData.RegisterElement();
