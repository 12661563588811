import {html} from "lit-element";
import CtvLitElementBase from "@camtv/front-base-ui/lit/element-base"
import CtvDialogModal from "@camtv/front-base-ui/lit/dialog-modal"
import CtvDialogPopup from "@camtv/front-base-ui/lit/dialog-popup"
import CtvLoadWaiter from '@camtv/front-base-ui/lit/load-waiter'
import CtvLightbox from '@camtv/front-base-ui/lit/lightbox'
import CtvDialogModalBodyModule from "@camtv/front-base-ui/lit/dialog-modal-body-module"
import CtvCameraCapturerDialog from "./camera-capturer-dialog"

import '@camtv/front-base-css/main.scss'


export default  class CtvCameraShotDialogModalBodyModule extends CtvDialogModalBodyModule {

	static get properties() {
        return { 
			image_data: {type: String},	
			heading_text: {type: Object},	
			error_text: {type: String}
        }
    }

	constructor(ParentModalDialog) {
		super(ParentModalDialog);

		this.image_data = "";
		this.img_placeholder = ""
		this.error_text = this.Ln("Immagine obbligatoria")

		this.force_file_load=false
	}

	render() {
		let lblAction = this.Ln("Scatta")
		if (window.ADMIN_MODE == true)
			lblAction = this.Ln("Carica")

		return html`
			<div class="hidden">
				<p>
					${this.heading_text}
				</p>

				<div class="ctv-input-field selfie">
				${Types.ToString(this.image_data)==""?
					html`<div class="selfie_placeholder">
							<img src="${this.img_placeholder}" />					
							<button class="ctv-button shot" @click="${()=>{ this._OnShot() }}">
								<svg style="width:24px;height:24px" viewBox="0 0 24 24">
									<path fill="currentColor" d="M4,4H7L9,2H15L17,4H20A2,2 0 0,1 22,6V18A2,2 0 0,1 20,20H4A2,2 0 0,1 2,18V6A2,2 0 0,1 4,4M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9Z" />
								</svg>
								${lblAction}
							</button>
						</div>`:
					html`<div class="selfie_image">
						<img src="${this.image_data}" @click="${()=>{this._OnShowLitebox()}}" style="cursor:pointer"/>					
						<div class="delete" @click="${()=>{ this._OnDeleteImage() }}">
							<svg style="width:24px;height:24px" viewBox="0 0 24 24">
								<path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
							</svg>
						</div>
					</div>`}			
					<div class="error">${this.error_text}</div>
				<div>
			<div>
		`
	}


	async OnShow() {
		let dlg = this.GetModalDialog();
		dlg.SetRightLink(this.Ln("Annulla"), dlg.Cancel)
		dlg.SetButton(this.Ln("Avanti"), () => { this._OnNext() })		
		dlg.show_back_link = false;
		
		let w = this.ShowWaiter();
		let elContainer = this.querySelector(":scope > div")
		elContainer.classList.add("hidden")

		return new Promise((resolve) => {
			let img = new Image();
			img.onload = () => { w.remove(); elContainer.classList.remove("hidden"); resolve() }
			img.onerror = () => { w.remove(); elContainer.classList.remove("hidden"); resolve() }
			if (Types.ToString(this.image_data) != "")
				img.src = this.image_data
			else 
				img.src = this.img_placeholder;
		})
	}

	async _OnNext() {

		let dlg = this.GetModalDialog();

		if (Types.ToString(this.image_data).trim() == "") {
			this.querySelector(".selfie").classList.add("error")
			return false;
		}		

		return true;
	}

	async _OnShowLitebox() {
		await CtvLightbox.Show(this.image_data)
	}

	_OnDeleteImage() {
		this.image_data = "";
	}	

	async _OnShot() {

		let btn = this.querySelector("button.shot")
		if (btn != null)
			btn.setAttribute("disabled","disabled")		

		this.querySelector(".selfie").classList.remove("error")

		let dlg = new CtvCameraCapturerDialog();
		dlg.force_file_load = this.force_file_load
		let res = await dlg.Show();
		if (res == "OK") {
			this.image_data = dlg.image_data;			
		}

		if (btn != null)
			btn.removeAttribute("disabled")
		

	}	

	
}

