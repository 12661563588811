import {html} from "lit-element";
import CtvLitElementBase from "@camtv/front-base-ui/lit/element-base"
import CtvDialogModal from "@camtv/front-base-ui/lit/dialog-modal"
import CtvDialogPopup from "@camtv/front-base-ui/lit/dialog-popup"
import CtvLoadWaiter from '@camtv/front-base-ui/lit/load-waiter'
import CtvDialogModalBodyModule from "@camtv/front-base-ui/lit/dialog-modal-body-module"
import CtvCameraShotDialogModalBodyModule from "./camera-shot-body-module"


import '@camtv/front-base-css/main.scss'
import Types from "@camtv/front-base-lib/utilities/types";

let imgPlaceholder_it = `https://s3staticassets.cam.tv/release/2021-05-05/light-it.jpg`;
let imgPlaceholder_en = `https://s3staticassets.cam.tv/release/2021-05-05/light-en.jpg`;


export default  class CtvStoDialog_StepKycUtilityBill extends CtvCameraShotDialogModalBodyModule {

	static get DomTag() { return  'ctv-sto-dialog-step-kyc-utility-bill'; }

	get StepName() { return  'KYC_UTILITYBILL'; }
	get StepLabel() { return  this.Ln('Bolletta'); }
	
	GetTranslations() {
		return {
			"Bolletta": {
				"it": "Bolletta",
				"en": "Utility bill",
				"de": "Rechnung",
				"fr": "Facture",
				"es": "Factura"
			},					
			"Scatta una foto della Bolletta (acqua, luce, gas) o dell'Estratto conto bancario": {
				"it": "Scatta una foto della Bolletta (acqua, luce, gas) o dell'Estratto conto bancario",
				"en": "Take a picture of your bill (water, electricity, gas) or bank statement",
				"de": "Mache ein Foto von deiner Rechnung (Wasser, Strom, Gas) oder deinem Kontoauszug",
				"fr": "Prenez une photo de votre facture de services publics (eau, électricité, gaz) ou de votre relevé bancaire",
				"es": "Tome una foto de su factura (agua, luz, gas) o extracto bancario"
			},
			"Assicurati che il documento che invii sia a te intestato e che il tuo nome e cognome ed il tuo indirizzo di residenza siano chiaramente leggibili.": {
				"it": "Assicurati che il documento che invii sia a te intestato e che il tuo nome e cognome ed il tuo indirizzo di residenza siano chiaramente leggibili.",
				"en": "Make sure that the document you send is in your name and that your name and surname and your residential address are clearly legible.",
				"de": "Stellen Sie sicher, dass das von Ihnen gesendete Dokument auf Ihren Namen lautet und dass Ihr Vor- und Nachname sowie Ihre Wohnadresse deutlich lesbar sind.",
				"fr": "Assurez-vous que le document que vous envoyez est à votre nom et que vos nom et prénom ainsi que votre adresse résidentielle sont clairement lisibles.",
				"es": "Asegúrese de que el documento que envía esté a su nombre y que su nombre y apellido y su dirección de residencia sean claramente legibles."
			},
			"ATTENZIONE: NON inviare bollette di utenze telefoniche o altre tipologie di bollette o documentazione al di fuori di quelle indicate perché NON verranno accettate.": {
				"it": "ATTENZIONE: NON inviare bollette di utenze telefoniche o altre tipologie di bollette o documentazione al di fuori di quelle indicate perché NON verranno accettate.",
				"en": "ATTENTION: DO NOT send telephone bills or other types of bills or documentation other than those indicated because they will NOT be accepted.",
				"de": "ACHTUNG: Senden Sie KEINE Telefonrechnungen oder andere Arten von Rechnungen oder Unterlagen als die angegebenen, da diese NICHT akzeptiert werden.",
				"fr": "ATTENTION : N'ENVOYEZ PAS de factures de téléphone ou d'autres types de factures ou de documents autres que ceux indiqués car ils ne seront PAS acceptés.",
				"es": "ATENCIÓN: NO envíe facturas telefónicas u otro tipo de facturas o documentación distinta a las indicadas porque NO serán aceptadas."
			},
			"La fotografia della bolletta è obbligatoria": {
				"it": "La fotografia della bolletta è obbligatoria",
				"en": "A copy of the utility bill is required",
				"de": "Das Foto von Ihrer Rechnung ist erforderlich",
				"fr": "La photo de la facture est obligatoire",
				"es": "La fotografía de la factura es obligatoria"
			},
			"Immagine obbligatoria": {
				"it": "Immagine obbligatoria",
				"en": "Image is required",
				"de": "Foto ist erforderlich",
				"fr": "Une image est obligatoire",
				"es": "Imagen obligatoria"
			},
			"Scatta": {
				"it": "Scatta",
				"en": "Take",
				"de": "Aufnehmen",
				"fr": "Prendre",
				"es": "Hacer foto"
			},		
			"Annulla": {
				"it": "Annulla",
				"en": "Cancel",
				"de": "Abbrechen",
				"fr": "Annuler",
				"es": "Cancelar"
			},
			"Avanti": {
				"it": "Avanti",
				"en": "Next",
				"de": "Weiter",
				"fr": "Suivant",
				"es": "Siguiente"
			}
		}
	}

	constructor(ParentModalDialog) {
		super(ParentModalDialog);

		this.doc_label = "";
		this.img_placeholder = imgPlaceholder_en
		if (Types.ToString(this.lang).toLowerCase() == "it")
			this.img_placeholder = imgPlaceholder_it	
	}

	async OnShow() {
		let dlg = this.GetModalDialog()	

		this.image_data = dlg.Data.utility_bill_photo	
		let dt = dlg.Data.document_type
		let doc_label = dlg.DocumentLabels[dt]

		this.heading_text = html`
			<h3 class="title">${this.Ln("Scatta una foto della Bolletta (acqua, luce, gas) o dell'Estratto conto bancario")}</h3>
			<p class="description">${this.Ln("Assicurati che il documento che invii sia a te intestato e che il tuo nome e cognome ed il tuo indirizzo di residenza siano chiaramente leggibili.")}</p>
			<p class="description" style="padding-top:0;">${this.Ln("ATTENZIONE: NON inviare bollette di utenze telefoniche o altre tipologie di bollette o documentazione al di fuori di quelle indicate perché NON verranno accettate.")}</p>
			`
		this.error_text = `${this.Ln("La fotografia della bolletta è obbligatoria")}`

		dlg.SetPostFooter(true)
		
		super.OnShow()
	}

	async _SaveAndValidateData(IsAdmin) {
		let dlg = this.GetModalDialog();

		let isValid = await super._OnNext();
		if (IsAdmin != true && isValid == false)
			return false;

		dlg.Data.utility_bill_photo = this.image_data
		dlg.Data.step = this.StepName

		return true;
	}

	async _OnNext() {
		let dlg = this.GetModalDialog()	

		let isValid = await super._OnNext();
		if (isValid == false)
			return false;

		dlg.Data.utility_bill_photo = this.image_data
		dlg.Data.step = this.StepName

		if(await dlg.SaveStep() == false)
			return false;

		dlg.Wizard_PageNext()
	}
}
CtvStoDialog_StepKycUtilityBill.RegisterElement();
