import {html} from "lit-element";
import CtvLitElementBase from "@camtv/front-base-ui/lit/element-base"
import CtvDialogModal from "@camtv/front-base-ui/lit/dialog-modal"
import CtvDialogPopup from "@camtv/front-base-ui/lit/dialog-popup"
import CtvLoadWaiter from '@camtv/front-base-ui/lit/load-waiter'
import CtvDialogModalBodyModule from "@camtv/front-base-ui/lit/dialog-modal-body-module"

import '@camtv/front-base-css/main.scss'
import CtvStoDialog_StepTokenContractSignature from "./sto-dialog-step-token-contract-signature";

export default  class CtvStoDialog_StepVoucherKycSubmitted extends CtvDialogModalBodyModule {

	static get DomTag() { return  'ctv-kyc-dialog-step-voucher-kyc-submitted'; }

	get StepName() { return  'SUBMITTING'; }	

	constructor(ParentModalDialog) {
		super(ParentModalDialog);
	}

	GetTranslations() {
		return {
			"Grazie!": {
				"it": "Grazie!",
				"en": "Thank you!",
				"de": "Vielen Dank!",
				"fr": "Merci!",
				"es": "¡Gracias!"
			},
			"I dati relativi alla procedura di KYC sono stati inviati. Appena completeremo le verifche, riceverai una email con un link per proseguire la procedura.": {
				"it": "I dati relativi alla procedura di KYC sono stati inviati. Appena completeremo le verifche, riceverai una email con un link per proseguire la procedura.",
				"en": "The data relating to the KYC procedure has been sent. As soon as we complete the checks, you will receive an email with a link to continue the procedure.",
				"de": "Die Daten für das KYC-Verfahren wurden übermittelt. Sobald wir die Prüfungen abgeschlossen haben, erhältst du eine E-Mail mit einem Link, um den Vorgang fortzusetzen.",
				"fr": "Les données relatives à la procédure KYC ont été envoyées. Dès que nous aurons terminé les vérifications, vous recevrez un e-mail avec un lien pour poursuivre la procédure.",
				"es": "Se han enviado los datos relacionados con el procedimiento KYC. Tan pronto como completemos las verificaciones, recibirá un correo electrónico con un enlace para continuar con el procedimiento."
			},
			"Ti consigliamo di verificare la presenza della email nello spam, se entro qualche giorno non dovessi ricevere alcuna email, non esitare a contattare: support@camtv.io.": {
				"it": "Ti consigliamo di verificare la presenza della email nello spam, se entro qualche giorno non dovessi ricevere alcuna email, non esitare a contattare: support@camtv.io.",
				"en": "We advise you to check for the email in your spam folder, if within a few days you do not receive an email, do not hesitate to contact: support@camtv.io.",
				"de": "Wir empfehlen dir, das Vorhandensein der E-Mail im Spam zu überprüfen. Wenn du innerhalb weniger Tage keine E-Mail erhalten hast, wende dich bitte an: support@camtv.io.",
				"fr": "Nous vous conseillons de vérifier la présence de l'email dans les spams, si d'ici quelques jours vous ne recevez aucun email, n'hésitez pas à contacter : support@camtv.io.",
				"es": "Le recomendamos que compruebe la presencia del correo electrónico en el correo no deseado, si en unos días no recibe ningún correo electrónico, no dude en ponerse en contacto con: support@camtv.io."
			},
		}
	}

	render() {
		return html`
		<div class="success">
			<div class="success-icon">
				<svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg"><path d="m54 30c0 13.254834-10.745166 24-24 24s-24-10.745166-24-24 10.745166-24 24-24c2.28 0 4.5.33 6.6.93l4.71-4.71c-3.48-1.44-7.29-2.22-11.31-2.22-16.5685425 0-30 13.4314575-30 30 0 7.9564947 3.16070521 15.5871121 8.78679656 21.2132034 5.62609134 5.6260914 13.25670874 8.7867966 21.21320344 8.7867966s15.5871121-3.1607052 21.2132034-8.7867966c5.6260914-5.6260913 8.7867966-13.2567087 8.7867966-21.2132034m-42.27-5.76-4.23 4.26 13.5 13.5 30-30-4.23-4.26-25.77 25.77z" fill="#5fb900"/></svg>
			</div>
			<h3 class="title center">${this.Ln("Grazie!")}</h3>
			<p>
				${this.Ln("I dati relativi alla procedura di KYC sono stati inviati. Appena completeremo le verifche, riceverai una email con un link per proseguire la procedura.")}
				<br><br>
				${this.Ln("Ti consigliamo di verificare la presenza della email nello spam, se entro qualche giorno non dovessi ricevere alcuna email, non esitare a contattare: support@camtv.io.")}
			</p>
		</div>
		`
	}

	async OnShow() {
		let dlg = this.GetModalDialog();
		dlg.SetRightLink("")
		dlg.SetButton("", null)		
		dlg.show_back_link = false;
	}

	async New() {
		let dlg = this.GetModalDialog();
		dlg.NewInvestment()
	}

}
CtvStoDialog_StepVoucherKycSubmitted.RegisterElement();
