import {html} from "lit-element";
import CtvLitElementBase from "@camtv/front-base-ui/lit/element-base"

import '@camtv/front-base-css/main.scss'

import "./sto-dialog.scss"

export default class CtvStoDialogPostFooter extends CtvLitElementBase {

    static get DomTag() { return  'ctv-sto-dialog-post-footer'; }

	GetTranslations() {
		return {
			"Indietro": {
				"it": "Indietro",
				"en": "Back",
				"de": "Zurück",
				"fr": "En arrière",
				"es": "Atrás"
			}
		}
	}

	static get properties() {
        return { 
			text: {type: String},	
			show_back_button: {type: String},	
        }
    }

	constructor() {
		super()		


		this.text = ""
		this.show_back_button = false
		this.dlg = null;

	}

	render() {	
		return html`
			<p>${this.text}</p>

			${Types.ToBool(this.show_back_button)== true? html`<a href="#" @click="${()=>{ 
				window.history.back()
			}}">
				<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" /></svg>
				<span>${this.Ln("Indietro")}</span></a>
			`:``}
		`
		
	}

	static async Show(dlg, text, show_back_button) {
		document.querySelectorAll("ctv-sto-dialog-post-footer").forEach((el)=>{ el.remove();})

		let parent = dlg.parentNode
		let dpf = document.createElement("ctv-sto-dialog-post-footer")
		dpf.text = text
		dpf.show_back_button = show_back_button
		dpf.dlg= dlg
		parent.append(dpf)

		return dpf
	}
}
CtvStoDialogPostFooter.RegisterElement();
