import {html} from "lit-element";
import CtvLitElementBase from "@camtv/front-base-ui/lit/element-base"
import CtvDialogModal from "@camtv/front-base-ui/lit/dialog-modal"
import CtvDialogPopup from "@camtv/front-base-ui/lit/dialog-popup"
import CtvLoadWaiter from '@camtv/front-base-ui/lit/load-waiter'
import CtvDialogModalBodyModule from "@camtv/front-base-ui/lit/dialog-modal-body-module"
import QRCode from 'qrcode'

import '@camtv/front-base-css/main.scss'

export default  class CtvStoDialog_StepPaymentSubmit extends CtvDialogModalBodyModule {

	static get DomTag() { return  'ctv-kyc-dialog-step-payment-submit'; }

	get StepName() { return  'PAYMENT_SUBMIT'; }	
	get StepLabel() { return  this.Ln('Pagamento'); }	

	constructor(ParentModalDialog) {
		super(ParentModalDialog);
	}

	GetTranslations() {
		return {
			"Pagamento": {
				"it": "Pagamento",
				"en": "Payment",
				"de": "Zahlung",
				"fr": "Paiement",
				"es": "Pago"
			},
			"Pagamento con Bonifico Bancario": {
				"it": "Pagamento con Bonifico Bancario",
				"en": "Payment by bank transfer",
				"de": "Zahlung per Banküberweisung",
				"fr": "Paiement par virement bancaire",
				"es": "Pago por transferencia bancaria"
			},
			"Pagamento con Criptovaluta": {
				"it": "Pagamento con Criptovaluta",
				"en": "Payment with Cryptocurrency",
				"de": "Zahlung mit Kryptowährung",
				"fr": "Paiement avec Crypto-monnaie",
				"es": "Pago con Criptomoneda"
			},
			"Per completare la procedura di acquisto di {{ TokenAmount }} CAM Token devi effettuare un bonifico da un conto corrente intestato a {{ FirstName }} {{ LastName }} alle seguenti coordinate:": {
				"it": "Per completare la procedura di acquisto di {{ TokenAmount }} CAM Token devi effettuare un bonifico da un conto corrente intestato a {{ FirstName }} {{ LastName }} alle seguenti coordinate:",
				"en": "To complete the {{ TokenAmount }} CAM Token purchase procedure, you must make a transfer from a current account in the name of {{ FirstName }} {{ LastName }} to the following bank account:",
				"de": "Um den Kaufvorgang von {{ TokenAmount }} CAM Token abzuschließen, musst du eine Überweisung von einem Girokonto im Namen von {{ FirstName }} {{ LastName }} an das folgende Bankkonto vornehmen:",
				"fr": "Pour compléter la procédure d'achat de {{ TokenAmount }} CAM Token, vous devez effectuer un virement depuis un compte courant au nom de {{ FirstName }} {{ LastName }} aux coordonnées suivantes :",
				"es": "Para completar el trámite de compra de {{ TokenAmount }} CAM Token debes realizar una transferencia desde una cuenta corriente a nombre de {{ FirstName }} {{ LastName }} en las siguientes coordenadas:"
			},
			"Attenzione": {
				"it": "Attenzione",
				"en": "Attention",
				"de": "Achtung",
				"fr": "Attention",
				"es": "Atención"
			},
			"Beneficiario:": {
				"it": "Beneficiario:",
				"en": "Recipient:",
				"de": "Empfänger:",
				"fr": "Bénéficiaire:",
				"es": "Beneficiario:"
			},			
			"IBAN:": {
				"it": "IBAN:",
				"en": "IBAN:",
				"de": "IBAN:",
				"fr": "IBAN:",
				"es": "IBAN:"
			},
			"Swift:": {
				"it": "Swift:",
				"en": "Swift:",
				"de": "Swift:",
				"fr": "Swift:",
				"es": "Swift:"
			},
			"Causale:": {
				"it": "Causale:",
				"en": "Purpose:",
				"de": "Kausal:",
				"fr": "Causal:",
				"es": "Causal:"
			},
			"Importo:": {
				"it": "Importo:",
				"en": "Amount:",
				"de": "Menge:",
				"fr": "Montant:",
				"es": "Monto:"
			},
			"Indirizzo:": {
				"it": "Indirizzo:",
				"en": "Address:",
				"de": "Adresse",
				"fr": "Adresse",
				"es": "Dirección"
			},			
			"Per agevolare la procedura di acquisto assicurati di inserire correttamente il codice presente nella causale sopra indicata, e di inviare conferma del pagamento alla email: support@camtv.io": {
				"it": "Per agevolare la procedura di acquisto assicurati di inserire correttamente il codice presente nella causale sopra indicata, e di inviare conferma del pagamento alla email: support@camtv.io",
				"en": "To facilitate the purchase process, make sure to correctly enter the code present in the payment purpose indicated above, and to send confirmation of payment to the email: support@camtv.io",
				"de": "Um den Kaufvorgang zu erleichtern, stelle sicher, dass du den Code, der oben im kausalen Indikator angegeben ist, korrekt eingibst und die Zahlungsbestätigung an die E-Mail-Adresse support@camtv.io sendest",
				"fr": "Pour faciliter le processus d'achat, assurez-vous de bien saisir le code présent dans le motif indiqué ci-dessus, et d'envoyer la confirmation de paiement à l'email : support@camtv.io",
				"es": "Para facilitar el proceso de compra, asegúrese de ingresar correctamente el código presente en el motivo indicado anteriormente, y de enviar la confirmación de pago al correo electrónico: support@camtv.io"
			},
			"Per tua comodità abbiamo inviato una email di riepilogo al tuo indirizzo {{ email }}": {
				"it": "Per tua comodità abbiamo inviato una email di riepilogo al tuo indirizzo {{ email }}",
				"en": "For your convenience we have sent a summary email to your address {{email}}",
				"de": "Zu deiner Information haben wir eine zusammenfassende E-Mail an deine E-Mail-Adresse {{ email }} gesendet.",
				"fr": "Pour votre commodité, nous avons envoyé un e-mail récapitulatif à votre adresse e-mail {{ email }}",
				"es": "Para su comodidad, hemos enviado un correo electrónico de resumen a su dirección de correo electrónico {{ email }}"
			},
			"Pagamento in Bitcoin": {
				"it": "Pagamento in Bitcoin",
				"en": "Bitcoin payment",
				"de": "Bitcoin-Zahlung",
				"fr": "Paiement Bitcoin",
				"es": "Pago Bitcoin"
			},
			"Pagamento in Ethereum": {
				"it": "Pagamento in Ethereum",
				"en": "Ethereum payment",
				"de": "Zahlung in Ethereum",
				"fr": "Paiement en Ethereum",
				"es": "Pago en Ethereum"
			},
			"Per completare la procedura di acquisto di {{ TokenAmount }} CAM Token devi effettuare un pagamento in criptovaluta al seguente indirizzo:": {
				"it": "Per completare la procedura di acquisto di {{ TokenAmount }} CAM Token devi effettuare un pagamento in criptovaluta al seguente indirizzo:",
				"en": "To complete the {{ TokenAmount }} CAM Token purchase procedure you must make a cryptocurrency payment to the following address:",
				"de": "Um das Kaufverfahren für {{ TokenAmount }} CAM Token abzuschließen, musst du eine Kryptowährungszahlung an die folgende Adresse leisten:",
				"fr": "Pour terminer la procédure d'achat de {{ TokenAmount }} CAM Token, vous devez effectuer un paiement en crypto-monnaie à l'adresse suivante :",
				"es": "Para completar el procedimiento de compra de {{ TokenAmount }} CAM Token debes realizar un pago en criptomoneda a la siguiente dirección:"
			},
			"Per tua comodità abbiamo inviato una mail di riepilogo con tutti i dati all’indirizzo {{ email }}.": {
				"it": "Per tua comodità abbiamo inviato una mail di riepilogo con tutti i dati all’indirizzo {{ email }}.",
				"en": "For your convenience, we have sent a summary email with all the data to {{ email }}.",
				"de": "Zu deiner Information haben wir eine zusammenfassende E-Mail mit allen Daten an {{ email }} gesendet.",
				"fr": "Pour votre commodité, nous avons envoyé un e-mail récapitulatif avec toutes les données à {{ email }}.",
				"es": "Para su comodidad, hemos enviado un correo electrónico de resumen con todos los datos a {{ email }}."
			},
			"Indirizzo di destinazione:": {
				"it": "Indirizzo di destinazione:",
				"en": "Destination address:",
				"de": "Zieladresse:",
				"fr": "Adresse de destination:",
				"es": "Dirección de destino:"
			},
			"Per agevolare la procedura di acquisto invia la conferma del pagamento alla email: support@camtv.io":
			{
				"it": "Per agevolare la procedura di acquisto invia la conferma del pagamento alla email: support@camtv.io",
				"en": "To facilitate the purchase process, send confirmation of payment to the email: support@camtv.io",
				"de": "Um den Kaufvorgang zu erleichtern, sende eine Zahlungsbestätigung an die E-Mail: support@camtv.io",
				"fr": "Pour faciliter le processus d'achat, envoyez une confirmation de paiement à l'email : support@camtv.io",
				"es": "Para facilitar el proceso de compra, envíe la confirmación de pago al correo electrónico: support@camtv.io"
			},
			"Pari a Euro:": {
				"it": "Pari a Euro:",
				"en": "Equal to Euro:",
				"de": "Entspricht dem Euro:",
				"fr": "Égal à l'euro :",
				"es": "Igual a euro:"
			},
			"Validità pagamento entro:": {
				"it": "Validità pagamento entro:",
				"en": "Payment validity within:",
				"de": "Gültigkeit der Zahlung innerhalb:",
				"fr": "Validité du paiement dans :",
				"es": "Validez del pago dentro de:"
			},
			"Copiato": {
				"it": "Copiato",
				"en": "Copied",
				"de": "Kopiert",
				"fr": "Copié",
				"es": "Copiado"
			}
		}
	}

	render() {
		if (this.GetModalDialog().Data.payment_method == "WIRE_TRANSFER")
			return this.RenderWiredTransfer()

		return this.RenderCrypto()
	}
	
	RenderWiredTransfer() {
		let dlg = this.GetModalDialog();
		return html`
		<div class="success">
			<h3 class="title">${this.Ln("Pagamento con Bonifico Bancario")}</h3>
			<p class="description">
				${this.Ln("Per completare la procedura di acquisto di {{ TokenAmount }} CAM Token devi effettuare un bonifico da un conto corrente intestato a {{ FirstName }} {{ LastName }} alle seguenti coordinate:",
					{
						TokenAmount: dlg.Data.token_amount,
						FirstName: dlg.Data.first_name,
						LastName: dlg.Data.last_name,
					}
				)}
			</p>

			<ul class="wiretransfer-data">
				<li>${this.Ln("Beneficiario:")} <span style="cursor: pointer;" @click="${this._OnCopyContent}}">Digital Fastlane GmbH</span></li>
				<li>${this.Ln("IBAN:")} <span style="cursor: pointer;" @click="${this._OnCopyContent}}">IT91C0306964054100000004669</span></li>
				<li>${this.Ln("Swift:")} <span style="cursor: pointer;" @click="${this._OnCopyContent}}">BCITITMM</span></li>
				<li>${this.Ln("Importo:")} <span style="cursor: pointer;" @click="${this._OnCopyContent}}">${dlg.Data.invest_amount_euro} EUR</span></li>
				<li>${this.Ln("Causale:")} <span style="cursor: pointer;" @click="${this._OnCopyContent}}">${dlg.Data.payment_causal_code}</span></li>
			</ul>

			<h3 class="title" style="padding-top:0">${this.Ln("Attenzione")}</h3>
			<p class="description" style="padding-top: 0;">
				${this.ToHtml(this.Ln("Per agevolare la procedura di acquisto assicurati di inserire correttamente il codice presente nella causale sopra indicata, e di inviare conferma del pagamento alla email: support@camtv.io"))}
			</p>
			<p class="description">
				${this.Ln("Per tua comodità abbiamo inviato una mail di riepilogo con tutti i dati all’indirizzo {{ email }}.", { email: dlg.Data.email })}				
			</p>
		</div>
		`
	}

	RenderCrypto() {
		let dlg = this.GetModalDialog();
		return html`
		<div class="success">
			<h3 class="title">${this.Ln("Pagamento con Criptovaluta")}</h3>
			<p class="description">
				${this.Ln("Per completare la procedura di acquisto di {{ TokenAmount }} CAM Token devi effettuare un pagamento in criptovaluta al seguente indirizzo:",{
					TokenAmount: dlg.Data.token_amount,
				})}
			</p>
			<div class="select-payment-crypto">
				<div class="ctv-input-field select-field">
					<label>&nbsp;</label>
					<select 
						class="CRYPTO_SELECTOR" 
						data-validate="required" 
						@click=""
						@change="${()=>{ this._OnSetCrypto() }}"
						>	
						<option value="BTC">${this.Ln("Pagamento in Bitcoin")}</option>						
						<option value="ETH">${this.Ln("Pagamento in Ethereum")}</option>	
					</select>
					<div class="error">Messagge Error!</div>
				</div>	

				<div class="box-payment-crypto">
					<div>
						<ul class="cryptotransfer-data">
							<li>
								${this.Ln("Indirizzo di destinazione:")} 
								<div class="crypto-address-box">
									<span class="CRYPTO_ADDRESS" style="cursor: pointer;" @click="${this._OnCopyContent}}"></span>
									<svg viewBox="0 0 24 24"><path fill="currentColor" d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" /></svg>
								</div>
							</li>
							<li>${this.Ln("Importo:")} <span class="CRYPTO_AMOUNT" style="cursor: pointer;" @click="${this._OnCopyContent}}">${dlg.Data.invest_amount_euro}</span></li>
							<li>${this.Ln("Pari a Euro:")} <span class="EURO_AMOUNT" style="cursor: pointer;" @click="${this._OnCopyContent}}">${dlg.Data.invest_amount_euro}</span></li>
							<li>${this.Ln("Causale:")} <span style="cursor: pointer;" @click="${this._OnCopyContent}}">${dlg.Data.payment_causal_code}</span></li>
							<li>${this.Ln("Validità pagamento entro:")} <span class="CRYPTO_PAYMENT_VALID_DATETIME" style="cursor: pointer;" @click="${this._OnCopyContent}}"></span></li>
						</ul>
					</div>
					<div>
						<img class="ADDRESS_QRCODE" src="" />
					</div>
				</div>
			</div>
			<h3 class="title">${this.Ln("Attenzione")}</h3>
			<p style="padding-top:0;" class="description">
				${this.ToHtml(this.Ln("Per agevolare la procedura di acquisto assicurati di inserire correttamente il codice presente nella causale sopra indicata, e di inviare conferma del pagamento alla email: support@camtv.io"))}
			</p>
			<p class="description">
				${this.Ln("")}
			</p>
		</div>
		`
		//${this.Ln("Per tua comodità abbiamo inviato una mail di riepilogo con tutti i dati all’indirizzo {{ email }}.", { email: dlg.Data.email })}
	}

	async OnShow() {
		let dlg = this.GetModalDialog();
		dlg.SetRightLink("")
		dlg.SetButton(null)
		dlg.show_back_link = true;
		dlg.SetPostFooter(true)
		
		// Gestisce la modifica del metodo di pagamento se non definito a WIRE_TRANSFER
		if (Types.ToString(dlg.Data.payment_method) == "")
			dlg.Data.payment_method = "WIRE_TRANSFER"			

		this.update()

		setTimeout(async ()=>{
			if (dlg.Data.payment_method == "WIRE_TRANSFER")
				await this._OnSetWireTransfer()
			else
				await this._OnSetCrypto()	
		},1)
	}

	async _OnSetWireTransfer() {
		let dlg = this.GetModalDialog();

		dlg.Data.crypto_payment_type = ""
		dlg.Data.crypto_payment_address = ""
		dlg.Data.crypto_payment_exchange_euro_price = 0
		dlg.Data.crypto_payment_exchange_euro_datetime = null
		dlg.Data.crypto_payment_paid_amount = 0
		dlg.Data.step = this.StepName

		if(await dlg.SaveStep() == false)
			return false;	
		
	}

	async _OnSetCrypto() {
		let dlg = this.GetModalDialog();
		let choice = this.querySelector(".CRYPTO_SELECTOR").value

		const BTCAddress = "bc1q83trmk3qq4krc77ktrvx95wejg2aq0w0fxsrgv"
		const ETHAddress = "0xA33c5B6b60fFE7E2C8D59aB8bA72510e7619B303"

		if (choice == "BTC") {
			let amount = dlg.Data.invest_amount_euro / dlg.Data.btc_to_eur
			this.querySelector(".CRYPTO_ADDRESS").innerHTML = BTCAddress
			this.querySelector(".ADDRESS_QRCODE").src = await QRCode.toDataURL(
				`bitcoin:${BTCAddress}?amount=${amount.toFixed(6)}&message=${dlg.Data.payment_causal_code}`,
				{ errorCorrectionLevel: 'H' }
			)
			this.querySelector(".CRYPTO_AMOUNT").innerHTML = amount.toFixed(6) + " BTC"			
			this.querySelector(".CRYPTO_PAYMENT_VALID_DATETIME").innerHTML = Types.Timestamp.Format(new Date((dlg.Data.crypto_exchange_unix_time+20*60)*1000), "YYYY-MM-DD HH:mm")		
			dlg.Data.crypto_payment_type = "BTC"
			dlg.Data.crypto_payment_address = BTCAddress
			dlg.Data.crypto_payment_exchange_euro_price = dlg.Data.btc_to_eur
			dlg.Data.crypto_payment_exchange_euro_datetime = new Date(dlg.Data.crypto_exchange_unix_time*1000)
			dlg.Data.crypto_payment_paid_amount = amount
		}
		else {
			let amount = dlg.Data.invest_amount_euro / dlg.Data.eth_to_eur
			this.querySelector(".CRYPTO_ADDRESS").innerHTML = ETHAddress
			this.querySelector(".ADDRESS_QRCODE").src = await QRCode.toDataURL(
				`ethereum:${ETHAddress}?amount=${amount.toFixed(6)}&message=${dlg.Data.payment_causal_code}`,
				{ errorCorrectionLevel: 'H' }
			)
			this.querySelector(".CRYPTO_AMOUNT").innerHTML = amount.toFixed(6) + " ETH"			
			this.querySelector(".CRYPTO_PAYMENT_VALID_DATETIME").innerHTML = Types.Timestamp.Format(new Date((dlg.Data.crypto_exchange_unix_time+20*60)*1000), "YYYY-MM-DD HH:mm")	
					
			dlg.Data.crypto_payment_type = "ETH"
			dlg.Data.crypto_payment_address = ETHAddress
			dlg.Data.crypto_payment_exchange_euro_price = dlg.Data.eth_to_eur
			dlg.Data.crypto_payment_exchange_euro_datetime = new Date(dlg.Data.crypto_exchange_unix_time*1000)
			dlg.Data.crypto_payment_paid_amount = amount
		}	

		dlg.Data.step = this.StepName

		if(await dlg.SaveStep() == false)
			return false;	
		
	}

	async _OnCopyContent(evt) {
		let dlg = this.GetModalDialog();

		dlg.CopyNodeContent(evt);
	}
}
CtvStoDialog_StepPaymentSubmit.RegisterElement();
