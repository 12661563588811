import {html} from "lit-element";
import CtvLitElementBase from "@camtv/front-base-ui/lit/element-base"
import CtvDialogBase from "@camtv/front-base-ui/lit/dialog-base"
import CtvDialogPopup from "@camtv/front-base-ui/lit/dialog-popup"

import '@camtv/front-base-css/main.scss'

import "./camera-capturer-dialog.scss"

import Types from "@camtv/front-base-lib/utilities/types";
import BrowsersWhitelist from "@camtv/front-base-lib/utilities/browser-whitelist";
import CtvUploadPlaceholder from "@camtv/front-base-ui/lit/upload-placeholder"

export default class CtvCameraCapturerDialog extends CtvDialogBase {

    static get DomTag() { return  'ctv-camera-capturer-dialog'; }

	GetTranslations() {
		return {
			"Cattura Immagine": {
				"it": "Cattura Immagine",
				"en": "Capture Image",
				"de": "Bildaufnahme",
				"fr": "Capture d’image",
				"es": "Capturar imagen"
			},
			"esempio": {
				"it": "esempio",
				"en": "example",
				"de": "Beispiel",
				"fr": "exemple",
				"es": "ejemplo"
			},
			"Ok, ho capito": {
				"it": "Ok, ho capito",
				"en": "I understood",
				"de": "Ok, alles klar",
				"fr": "Ok, j’ai compris",
				"es": "¡OK! Entendido"
			},
			"Attivazione Camera": {
				"it": "Attivazione Camera",
				"en": "Camera activation",
				"de": "Kamera-Aktivierung",
				"fr": "Activation de la caméra",
				"es": "Activación cámara"
			},
			"Attivazione della webcam fallita. Riprova o contatta l'assistenza {SUPPORT}<br>Numero Verde supporto di Cam.TV 800 303 640.": {
				"it": "Attivazione della webcam fallita. Riprova o contatta l'assistenza {SUPPORT}<br>Numero Verde supporto di Cam.TV 800 303 640.",
				"en": "Webcam activation failed. Please try again or contact {SUPPORT}",
				"de": "Aktivierung der Kamera ist fehlgeschlagen. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support {SUPPORT}",
				"fr": "Echec de l’activation de la webcam. Veuillez réessayer ou contactez le support {SUPPORT}",
				"es": "Error en la activación de la webcam. Inténtalo de nuevo o contacta con soporte."
			}
		}
	}

	static get properties() {
        return { 
			image_data: {type: String},	
			mode: {type: String},		
        }
    }

	constructor() {
		super()		

		this.show_help_link = false;

		this.title_label =`${this.Ln("Cattura Immagine")}`

		this.hint_label = "";

		this.hint_image_src = "";

		this.image_data = "";

		this.mode = "CAMERA_ACTIVATION"	

		this.camera = null;

		this.force_file_load = false;
	}

	RenderHeader() {
		return html`
			<div>${this.title_label}</div>
		`;
	}

	RenderBody() {
		return html`
		<canvas></canvas>
		<video autoplay playsinline></video>	
		<div>					
			${this.RenderButton()}				
		</div>
		`;
	}

	RenderFooter() {
		return "";
	}

	RenderButton() {
		switch (this.mode) {
		
		case "CAMERA_ACTIVATION":
			return html``;	

		case "CAPTURE":
			return html`
					<div class="tip-blue-balloon">
						<div class="title">
							<h3>
							${this.title_label}
							</h3>
							<div class="close">
								<svg style="width:24px;height:24px" viewBox="0 0 24 24">
									<path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
								</svg>
							</div>
						</div>
						<p>${this.hint_label}</p>
						<span>${this.Ln("esempio")}:</span>
						<img src="${this.hint_image_src}" />
						<div class="row-accept">${this.Ln("Ok, ho capito")}</div>
					</div>
					<div class="btn shot" @click="${this.ShotClicked}">
						<svg style="width:24px;height:24px" viewBox="0 0 24 24">
							<path fill="currentColor" d="M4,4H7L9,2H15L17,4H20A2,2 0 0,1 22,6V18A2,2 0 0,1 20,20H4A2,2 0 0,1 2,18V6A2,2 0 0,1 4,4M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9Z" />
						</svg>
					</div>`;	

		case "SHOTTED":
			return html`
					<div class="btn discard" @click="${this.Discard}">
						<svg style="width:24px;height:24px" viewBox="0 0 24 24">
							<path fill="currentColor" d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z" />
						</svg>
					</div>
					<div class="btn keep" @click="${this.Keep}">
						<svg style="width:24px;height:24px" viewBox="0 0 24 24">
							<path fill="currentColor" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
						</svg>
					</div>					
					`;

		}
							
	}

	async Init() {

		let w = this.ShowWaiter(this.Ln("Attivazione Camera"))
		w.show_top_right_hider = false;

		this.canvas = this.querySelector("canvas");
		this.video = this.querySelector("video");
		this.video.onloadedmetadata = (event) => {
			this.video.style.visibility = "visible";

			this.canvas.width = this.video.videoWidth;
			this.canvas.height = this.video.videoHeight;
			this.mode = "CAPTURE"	
			w.remove();
		}

		return new Promise((resolve, reject) => {
			setTimeout( async()=>{
				try
				{
					this.camera = await navigator.mediaDevices.getUserMedia({
						video: { width: 1280, height: 720 },
						audio: false
					});
					this.video.srcObject = this.camera;							

					this.camera_started = true;	

					resolve();
					return;
				}
				catch(Ex) {
					console.log(Ex)	
					await CtvDialogPopup.ShowMessageBox("Ooops", this.Ln("Attivazione della webcam fallita. Riprova o contatta l'assistenza {SUPPORT}<br>Numero Verde supporto di Cam.TV 800 303 640.")
						.replace("{SUPPORT}", `<a target="_blank" style="color:#219FFF" data-lang="${this.lang}" href="mailto:support@camtv.io">support@camtv.io</a>`)
					)
					reject()			
					this.Cancel()
				}
			},10);
		});
	}

	async UnInit() {
		try
		{
			if (this.camera == null)
				return;
			
			let vTracks = this.camera.getTracks()
			if (vTracks == null)
				return;

			vTracks.forEach( (track) => {
				track.stop();
			});
		}
		catch(Ex) {
			console.log(Ex)				
		}
	}

	_OnDeleteImage() {
		this.image_data = "";
		this.body = this._RenderFormBody()
	}

	ShotClicked() {
		this.video.pause();
		this.mode = "SHOTTED"	
	}
	
	Discard() {
		this.video.play();
		this.mode = "CAPTURE"
	}

	Keep() {
		let rccanvas = this.canvas.getBoundingClientRect();
		this.canvas.style.visibility="hidden"
		var context = this.canvas.getContext('2d');		
		context.drawImage(this.video, 0, 0, this.video.videoWidth, this.video.videoHeight,
								 0,0,this.canvas.width,this.canvas.height);
					
		this.canvas.toBlob((data)=>{		
			
			this.mode = "SHOTTED"	

			var reader = new FileReader();
			reader.readAsDataURL(data); 
			reader.onloadend = async () => {
				this.image_data = reader.result
				this.Ok();
			}					
		},'image/png', 1);
	}	

	async _ProcessNateiveOnMobile() {
		return new Promise((resolve) =>  {
			let upp = new CtvUploadPlaceholder();

			setTimeout( async () =>  {
				try {				
					upp.accepted_media_type = upp.MEDIA_TYPE.IMAGE;
					upp.capture_media_type = "user"
					upp.image_max_pixel = 1024 * 768;
					let res = await upp.OpenFileDialogAndProcess();

					if (res == null)
						return resolve("CANCEL")

					let reader = new FileReader();					
					reader.onloadend = async () => {
						this.image_data = reader.result
						return resolve("OK")
					}							
					reader.readAsDataURL(res.Blob); 		
				}
				catch (Ex) {
					return resolve("ERROR")
				}
				finally {
					upp.remove();
				}
			},10)
		});		
	}

	async Show() {
		
		BrowsersWhitelist.GetBroswerProps()
		if (BrowsersWhitelist.IsMobile == true || this.force_file_load==true) {
			return this._ProcessNateiveOnMobile()
		}		
		
		return CtvCameraCapturerDialog.Show(this);
	}

}
CtvCameraCapturerDialog.RegisterElement();