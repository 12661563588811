import {html} from "lit-element";
import CtvLitElementBase from "@camtv/front-base-ui/lit/element-base"
import CtvDialogModal from "@camtv/front-base-ui/lit/dialog-modal"
import CtvDialogPopup from "@camtv/front-base-ui/lit/dialog-popup"
import CtvLoadWaiter from '@camtv/front-base-ui/lit/load-waiter'
import CtvDialogModalBodyModule from "@camtv/front-base-ui/lit/dialog-modal-body-module"

import '@camtv/front-base-css/main.scss'

export default  class CtvStoDialog_StepPaymentModeChoice extends CtvDialogModalBodyModule {

	static get DomTag() { return  'ctv-kyc-dialog-step-payment-mode-choice'; }

	get StepName() { return  'PAYMENT_MODE_CHOICE'; }
	get StepLabel() { return  this.Ln('Pagamento'); }	

	constructor(ParentModalDialog) {
		super(ParentModalDialog);
	}

	GetTranslations() {
		return {
			"Pagamento": {
				"it": "Pagamento",
				"en": "Payment",
				"de": "Zahlung",
				"fr": "Paiement",
				"es": "Pago"
			},						
			"Seleziona il metodo con cui effettuare il pagamento:": {
				"it": "Seleziona il metodo con cui effettuare il pagamento:",
				"en": "Select the payment method:",
				"de": "Wähle eine Zahlungsmethode:",
				"fr": "Sélectionnez le mode de paiement :",
				"es": "Seleccione la forma de pago:"
			},
			"Bonifico bancario": {
				"it": "Bonifico bancario",
				"en": "Bank transfer",
				"de": "Banküberweisung",
				"fr": "Virement",
				"es": "Transferencia bancaria"
			},
			"Criptovaluta (BTC o ETH)": {
				"it": "Criptovaluta (BTC o ETH)",
				"en": "Cryptocurrency (BTC or ETH)",
				"de": "Kryptowährung (BTC oder ETH)",
				"fr": "Crypto-monnaie (BTC ou ETH)",
				"es": "Criptomoneda (BTC o ETH)"
			},
			"Carta di Credito": {
				"it": "Carta di Credito",
				"en": "Credit Card",
				"de": "Kreditkarte",
				"fr": "Carte de crédit",
				"es": "Tarjeta de crédito"
			}
		}
	}

	render() {
		let dlg = this.GetModalDialog();
		return html`
		<div class="success">
			<h3 class="title center">${this.Ln("Seleziona il metodo con cui effettuare il pagamento:")}</h3>
			<ul class="payment-options">
				<li>
					<button class="ctv-button outline" @click="${()=>{ this.OnChoice("WIRE_TRANSFER")}}">
						<svg width="19" height="21" xmlns="http://www.w3.org/2000/svg"><path d="M9.5 0L0 5v2h19V5m-5 4v7h3V9M0 21h19v-3H0m8-9v7h3V9M2 9v7h3V9H2z" fill="#FF7D02" fill-rule="nonzero"/></svg>
						<span>${this.Ln("Bonifico bancario")}</span>
					</button>
				</li>
				<li>
					<button class="ctv-button outline" @click="${()=>{ this.OnChoice("CRYPTO")}}">
						<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M12.24 8.56C11.93 9.8 10 9.17 9.4 9l.55-2.18c.62.18 2.61.44 2.29 1.74m-3.11 1.56l-.6 2.41c.74.19 3.03.92 3.37-.44.36-1.42-2.03-1.79-2.77-1.97m10.57 2.3c-1.34 5.36-6.76 8.62-12.12 7.28C2.22 18.36-1.037 12.94.3 7.58A9.996 9.996 0 0112.42.3c5.35 1.34 8.61 6.76 7.28 12.12m-7.49-6.37l.45-1.8-1.1-.25-.44 1.73c-.29-.07-.58-.14-.88-.2l.44-1.77-1.09-.26-.45 1.79c-.24-.06-.48-.11-.7-.17l-1.51-.38-.3 1.17s.82.19.8.2c.45.11.53.39.51.64l-1.23 4.93c-.05.14-.21.32-.5.27.01.01-.8-.2-.8-.2L4.87 13l1.42.36c.27.07.53.14.79.2l-.46 1.82 1.1.28.45-1.81c.3.08.59.15.87.23l-.45 1.79 1.1.28.46-1.82c1.85.35 3.27.21 3.85-1.48.5-1.35 0-2.15-1-2.66.72-.19 1.26-.64 1.41-1.62.2-1.33-.82-2.04-2.2-2.52z" fill="#FF7D02" fill-rule="nonzero"/></svg>
						<span>${this.Ln("Criptovaluta (BTC o ETH)")}</span>
					</button>
				</li>
				<!--li>
					<button class="ctv-button outline" @click="${()=>{ this.OnChoice("CREDIT_CARD")}}">
						<svg width="20" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M18 0H2a2 2 0 00-2 2v12a2 2 0 002 2h16a2 2 0 002-2V2a2 2 0 00-2-2m0 7H2V4h16v3z" fill="#FF7D02" fill-rule="nonzero"/></svg>
						<span>${this.Ln("Carta di Credito")}</span>
					</button>
				</li-->
			</ul>
		</div>
		`
	}

	async OnShow() {
		let dlg = this.GetModalDialog();
		dlg.SetRightLink("")
		dlg.SetButton(null)		
		dlg.show_back_link = true;
		dlg.SetPostFooter(true)

		if (dlg.Data.btc_to_eur == 0 || dlg.Data.eth_to_eur == 0 )
			dlg.Wizard_PageNext()
	}

	async OnChoice(PaymentMethod) {
		let dlg = this.GetModalDialog();
		dlg.Data.payment_method = PaymentMethod;

		dlg.Wizard_PageNext()
	}

}
CtvStoDialog_StepPaymentModeChoice.RegisterElement();
