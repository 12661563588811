import {html} from "lit-element";
import CtvLitElementBase from "@camtv/front-base-ui/lit/element-base"
import CtvDialogModal from "@camtv/front-base-ui/lit/dialog-modal"
import CtvDialogPopup from "@camtv/front-base-ui/lit/dialog-popup"
import CtvLoadWaiter from '@camtv/front-base-ui/lit/load-waiter'
import CtvDialogModalBodyModule from "@camtv/front-base-ui/lit/dialog-modal-body-module"
import CtvKycDialog from "./sto-dialog"
import Validate from "@camtv/front-base-lib/utilities/validate"

import Inputmask from "inputmask"


import '@camtv/front-base-css/main.scss'


//export default  class CtvStoAdminDialog_Review extends CtvDialogModalBodyModule {
export default  class CtvStoAdminDialog_Review extends CtvDialogModal {

	static get DomTag() { return  'ctv-kyc-admin-dialog-review'; }

	GetTranslations() {
		return {
			"KYC review": {
				"it": "KYC review",
				"en": "KYC review",
				"de": "KYC Überprüfung",
				"fr": "Examen de connaissances des clients (KYC)",
				"es": "Revisión KYC"
			},
			"Esito review": {
				"it": "Esito review",
				"en": "Review outcome",
				"de": "Ergebnis der Überprüfung",
				"fr": "Examen du résultat",
				"es": "Resultado revisión"
			},
			"Seleziona": {
				"it": "Seleziona",
				"en": "Select",
				"de": "Auswahl",
				"fr": "Sélectionner",
				"es": "Seleccionar"
			},
			"Approvato": {
				"it": "Approvato",
				"en": "Approved",
				"de": "Zugelassen",
				"fr": "Approuvé",
				"es": "Aprobado"
			},
			"NON Approvato": {
				"it": "NON Approvato",
				"en": "NOT Approved",
				"de": "NICHT zugelassen",
				"fr": "NON Approuvé",
				"es": "NO Aprobado"
			},
			"Messaggio di errore!": {
				"it": "Messaggio di errore!",
				"en": "Error message!",
				"de": "Fehlermeldung",
				"fr": "Message d’erreur!",
				"es": "¡Mensaje de error!",
			},
			"Dati personali incongruenti": {
				"it": "Dati personali incongruenti",
				"en": "Inconsistent personal data",
				"de": "Inkonsistente persönliche Daten",
				"fr": "Données personnelles incohérentes",
				"es": "Datos personales incoherentes"
			},
			"Dati personali incompleti": {
				"it": "Dati personali incompleti",
				"en": "Incomplete personal data",
				"de": "Unvollständige persönliche Daten",
				"fr": "Données personnelles incomplètes",
				"es": "Datos personales incompletos"
			},
			"Documento scaduto": {
				"it": "Documento scaduto",
				"en": "Document expired",
				"de": "Dokument ist abgelaufen",
				"fr": "Document expiré",
				"es": "Documento caducado"
			},
			"Foto del documento fronte sfocata": {
				"it": "Foto del documento fronte sfocata",
				"en": "Front of the document photo is blurry",
				"de": "Vorderseite des Dokuments ist unscharf",
				"fr": "Le recto de la photo du document est flou",
				"es": "Foto del anverso del documento desenfocada"
			},
			"Foto del documento fronte tagliata": {
				"it": "Foto del documento fronte tagliata",
				"en": "Copy of the front side is cropped",
				"de": "Die Kopie der Ausweis-Vorderseite ist zugeschnitten",
				"fr": "Le recto de la photo du document est coupé",
				"es": "Foto del anverso del documento cortada"
			},
			"Foto del documento retro sfocata": {
				"it": "Foto del documento retro sfocata",
				"en": "Copy of the back side is blurry",
				"de": "Rückseite des Dokuments ist unscharf",
				"fr": "Le verso du document est flou",
				"es": "Foto del reverso del documento desenfocada"
			},
			"Foto del documento retro tagliata": {
				"it": "Foto del documento retro tagliata",
				"en": "Copy of the back side is cropped",
				"de": "Die Kopie der Ausweis-Rückseite ist zugeschnitten",
				"fr": "Le verso du document est coupé",
				"es": "Foto del reverso del documento cortada"
			},
			"Selfie con documento sfocato": {
				"it": "Selfie con documento sfocato",
				"en": "Selfie with blurry ID",
				"de": "Selfie mit unscharfem Dokument",
				"fr": "Selfie avec un document flou",
				"es": "Selfi con documento desenfocado"
			},
			"Selfie con documento tagliato": {
				"it": "Selfie con documento tagliato",
				"en": "Selfie with cropped ID",
				"de": "Selfie mit ausgeschnittenem Dokument",
				"fr": "Selfie avec un document coupé",
				"es": "Selfi con documento cortado"
			},
			"Foto della bolletta sfocata": {
				"it": "Foto della bolletta sfocata",
				"en": "Blurred utility bill copy",
				"de": "Unscharfes Rechnungsfoto",
				"fr": "Photo de facture floue",
				"es": "Foto de la factura desenfocada"
			},
			"Foto della bolletta tagliata": {
				"it": "Foto della bolletta tagliata",
				"en": "Copy of utility bill is cropped",
				"de": "Ausgeschnittenes Rechnungsfoto",
				"fr": "Photo de facture coupée",
				"es": "Foto de la factura cortada"
			},
			"Dati incongruenti": {
				"it": "Dati incongruenti",
				"en": "Inconsistent survey data",
				"de": "Inkonsitente Umfragedaten",
				"fr": "Données du questionnaire incohérentes",
				"es": "Datos del cuestionario incoherentes"
			},
			"Dati incompleti": {
				"it": "Dati incompleti",
				"en": "Incomplete survey data",
				"de": "Unvollständige Umfragedaten",
				"fr": "Données du questionnaire incomplètes",
				"es": "Datos del cuestionario incompletos"
			},
			"Note": {
				"it": "Note",
				"en": "Notes",
				"de": "Anmerkungen",
				"fr": "Notes",
				"es": "Notas"
			}
		}
	}

	constructor() {
		super()

		this.Data = { }
		this.review = {}
	}

//	constructor(ParentModalDialog) {
//		super(ParentModalDialog);	
//	}

	async Init() {	
		let elBody = this.querySelector(".ctv-dialog-body");
		this.RenderObject(this.RenderBody(), elBody)
		
		this.title_label = "Review KYC"
		this.title = "Revisione del KYC, imposta l'esito della valutazione ed eventualmente indica la causa della non approvazione."

		this.SetRightLink(this.Ln("Annulla"), this.Cancel)
		this.SetButton(this.Ln("Salva"), async () => { await this._OnSave() })
		this.show_back_link = false;

		setTimeout(async ()=>{
			await this.OnShow()
		},10)
	}

	GetModalDialog() {
		return this
	}

	async _OnSave() {
		if (await this._SaveAndValidateData(true) == true) {
			this.Ok()		
		}
	}

//	render() {	
	RenderBody() {
		let data = this.GetModalDialog().Data;
		return html`
			<p>${this.Ln("KYC review")}</p>
			<form @submit="${(evt) => { evt.preventDefault(); return false; }}">
				<div class="form-row">
					<div class="ctv-input-field select-field">
						<label class="active">${this.Ln("Esito review")}</label>
						<select 
							class="Result" 
							data-validate="required" 
							@click="${(evt) => { evt.target.parentNode.classList.remove("error"); }}"
							@change="${(evt)=> {  this._UpdateReasonsVisibility()  }}"
							>	
							<option selected disabled value="">${this.Ln("Seleziona")}</option>						
							<option value="ACCEPTED">${this.Ln("Approvato")}</option>	
							<option value="REJECTED">${this.Ln("NON Approvato")}</option>								
						</select>
						<div class="error">${this.Ln("Messaggio di errore!")}</div>
					</div>							
				</div>		

				<div class="form-row">
					<div class="ctv-input-field motivations hidden">
						<div class="options">
							<div>
								<input type="checkbox" class="PersonalDataWrong" id="ctv-kyc-admin-dialog-review_PERSONAL_DATA_WRONG">
								<label for="ctv-kyc-admin-dialog-review_PERSONAL_DATA_WRONG">${this.Ln("Dati personali incongruenti")}</label>
							</div>
							<div>
								<input type="checkbox" class="PersonalDataIncomplete" id="ctv-kyc-admin-dialog-review_PERSONAL_DATA_INCOMPLETE">
								<label for="ctv-kyc-admin-dialog-review_PERSONAL_DATA_INCOMPLETE">${this.Ln("Dati personali incompleti")}</label>
							</div>		
							<div>
								<input type="checkbox" class="DocumentExpired" id="ctv-kyc-admin-dialog-review_DOCUMENT_EXPIRED">
								<label for="ctv-kyc-admin-dialog-review_DOCUMENT_EXPIRED">${this.Ln("Documento scaduto")}</label>
							</div>	

							<div>
								<input type="checkbox" class="DocumentFrontUnfocused" id="ctv-kyc-admin-dialog-review_DOCUMENT_FRONT_UNFOCUSED">
								<label for="ctv-kyc-admin-dialog-review_DOCUMENT_FRONT_UNFOCUSED">${this.Ln("Foto del documento fronte sfocata")}</label>
							</div>		
							<div>
								<input type="checkbox" class="DocumentFrontCutted" id="ctv-kyc-admin-dialog-review_DOCUMENT_FRONT_CUTTED">
								<label for="ctv-kyc-admin-dialog-review_DOCUMENT_FRONT_CUTTED">${this.Ln("Foto del documento fronte tagliata")}</label>
							</div>	
								
							<div>
								<input type="checkbox" class="DocumentBackUnfocused" id="ctv-kyc-admin-dialog-review_DOCUMENT_BACK_UNFOCUSED">
								<label for="ctv-kyc-admin-dialog-review_DOCUMENT_BACK_UNFOCUSED">${this.Ln("Foto del documento retro sfocata")}</label>
							</div>		
							<div>
								<input type="checkbox" class="DocumentBackCutted" id="ctv-kyc-admin-dialog-review_DOCUMENT_BACK_CUTTED">
								<label for="ctv-kyc-admin-dialog-review_DOCUMENT_BACK_CUTTED">${this.Ln("Foto del documento retro tagliata")}</label>
							</div>	
							
							<!--<div>
								<input type="checkbox" class="DocumentSelfieUnfocused" id="ctv-kyc-admin-dialog-review_DOCUMENT_SELFIE_UNFOCUSED">
								<label for="ctv-kyc-admin-dialog-review_DOCUMENT_SELFIE_UNFOCUSED">${this.Ln("Selfie con documento sfocato")}</label>
							</div>		
							<div>
								<input type="checkbox" class="DocumentSelfieCutted" id="ctv-kyc-admin-dialog-review_DOCUMENT_SELFIE_CUTTED">
								<label for="ctv-kyc-admin-dialog-review_DOCUMENT_SELFIE_CUTTED">${this.Ln("Selfie con documento tagliato")}</label>
							</div>-->					
							
							<div>
								<input type="checkbox" class="UtilityBillUnfocused" id="ctv-kyc-admin-dialog-review_UTILITY_BILL_UNFOCUSED">
								<label for="ctv-kyc-admin-dialog-review_UTILITY_BILL_UNFOCUSED">${this.Ln("Foto della bolletta sfocata")}</label>
							</div>		
							<div>
								<input type="checkbox" class="UtilityBillCutted" id="ctv-kyc-admin-dialog-review_UTILITY_BILL_CUTTED">
								<label for="ctv-kyc-admin-dialog-review_UTILITY_BILL_CUTTED">${this.Ln("Foto della bolletta tagliata")}</label>
							</div>	

							<div>
								<input type="checkbox" class="SurveyDataWrong" id="ctv-kyc-admin-dialog-review_SURVEY_DATA_WRONG">
								<label for="ctv-kyc-admin-dialog-review_SURVEY_DATA_WRONG">${this.Ln("Dati incongruenti")}</label>
							</div>
							<div>
								<input type="checkbox" class="SurveyDataIncomplete" id="ctv-kyc-admin-dialog-review_SURVEY_DATA_INCOMPLETE">
								<label for="ctv-kyc-admin-dialog-review_SURVEY_DATA_INCOMPLETE">${this.Ln("Dati incompleti")}</label>
							</div>
							
						</div>
						<div class="error">${this.Ln("Messaggio di errore!")}</div>
						<div>
							<label class="active">Note aggiuntive da allegare all'email</label>
							<textarea class="EmailNotes" id="ctv-kyc-admin-dialog-review_EMAIL_NOTES" placeholder="Nota aggiuntiva da allegare all'email" length="4096" maxlength="4096" required=""></textarea>
						</div>
					</div>
				</div>	

				<div class="form-row">
					<div class="ctv-input-field">
						<textarea class="Notes" id="ctv-kyc-admin-dialog-review_REVIEW_NOTES" placeholder="${this.Ln("Note")}" length="4096" maxlength="4096" required=""></textarea>
						<label for="ctv-kyc-admin-dialog-review_REVIEW_NOTES" class="active">${this.Ln("Note")}</label>
						<div class="error">${this.Ln("Messaggio di errore!")}</div>
						<span class="character-counter" style="float: right; font-size: 12px; height: 1px;"></span>
					</div>
				</div>		
			
			</form>
			`
			
	}

	async OnShow() {
		let dlg = this.GetModalDialog();

		let data = {};
		try
		{
			data = JSON.parse(dlg.Data.admin_review_json)
		}
		catch {
			data = {}
		}

		this.querySelector("select.Result").value = data.Result
		this.querySelector("input.PersonalDataWrong").checked = Types.ToBool(data.PersonalDataWrong)
		this.querySelector("input.PersonalDataIncomplete").checked = Types.ToBool(data.PersonalDataIncomplete)
		this.querySelector("input.DocumentExpired").checked = Types.ToBool(data.DocumentExpired)
		this.querySelector("input.DocumentFrontUnfocused").checked = Types.ToBool(data.DocumentFrontUnfocused)
		this.querySelector("input.DocumentFrontCutted").checked = Types.ToBool(data.DocumentFrontCutted)
		this.querySelector("input.DocumentBackUnfocused").checked = Types.ToBool(data.DocumentBackUnfocused)
		this.querySelector("input.DocumentBackCutted").checked = Types.ToBool(data.DocumentBackCutted)
		// this.querySelector("input.DocumentSelfieUnfocused").checked = Types.ToBool(data.DocumentSelfieUnfocused)
		// this.querySelector("input.DocumentSelfieCutted").checked = Types.ToBool(data.DocumentSelfieCutted)
		this.querySelector("input.UtilityBillUnfocused").checked = Types.ToBool(data.UtilityBillUnfocused)
		this.querySelector("input.UtilityBillCutted").checked = Types.ToBool(data.UtilityBillCutted)
		this.querySelector("input.SurveyDataWrong").checked = Types.ToBool(data.SurveyDataWrong)
		this.querySelector("input.SurveyDataIncomplete").checked = Types.ToBool(data.SurveyDataIncomplete)
		this.querySelector("textarea.Notes").value = Types.ToString(data.Notes)
		this.querySelector("textarea.EmailNotes").value = Types.ToString(data.EmailNotes)

		this._UpdateReasonsVisibility();
	}

	async _SaveAndValidateData(IsAdmin) {

		let dlg = this.GetModalDialog();

		if (IsAdmin != true && Validate.ValidateForm(this.querySelector("form"), true, false, (inputField, errorMessage) => { dlg._SetFieldError(inputField, errorMessage) })!= true) 
			return false;

		
		let data = {}
		data.Result = this.querySelector("select.Result").value;		
		data.PersonalDataWrong = this.querySelector("input.PersonalDataWrong").checked;
		data.PersonalDataIncomplete = this.querySelector("input.PersonalDataIncomplete").checked;
		data.DocumentExpired = this.querySelector("input.DocumentExpired").checked;
		data.DocumentFrontUnfocused = this.querySelector("input.DocumentFrontUnfocused").checked;
		data.DocumentFrontCutted = this.querySelector("input.DocumentFrontCutted").checked;
		data.DocumentBackUnfocused = this.querySelector("input.DocumentBackUnfocused").checked;
		data.DocumentBackCutted = this.querySelector("input.DocumentBackCutted").checked;
		// data.DocumentSelfieUnfocused = this.querySelector("input.DocumentSelfieUnfocused").checked;
		// data.DocumentSelfieCutted = this.querySelector("input.DocumentSelfieCutted").checked;
		data.UtilityBillUnfocused = this.querySelector("input.UtilityBillUnfocused").checked;
		data.UtilityBillCutted = this.querySelector("input.UtilityBillCutted").checked;
		data.SurveyDataWrong = this.querySelector("input.SurveyDataWrong").checked;
		data.SurveyDataIncomplete = this.querySelector("input.SurveyDataIncomplete").checked;
		data.Notes = this.querySelector("textarea.Notes").value;	
		data.EmailNotes = this.querySelector("textarea.EmailNotes").value;	

		this.review = data

		dlg.Data.admin_review_json = JSON.stringify(data)

		return true;
	}

	_UpdateReasonsVisibility() {
		this.querySelector(".motivations").classList.add("hidden"); 
		if (this.querySelector("select.Result").value == "REJECTED"){
			this.querySelector(".motivations").classList.remove("hidden")
		}
	}
}
CtvStoAdminDialog_Review.RegisterElement();
