import {html} from "lit-element";
import CtvLitElementBase from "@camtv/front-base-ui/lit/element-base"
import CtvDialogModal from "@camtv/front-base-ui/lit/dialog-modal"
import CtvDialogPopup from "@camtv/front-base-ui/lit/dialog-popup"
import CtvLoadWaiter from '@camtv/front-base-ui/lit/load-waiter'
import CtvDialogModalBodyModule from "@camtv/front-base-ui/lit/dialog-modal-body-module"
import CtvLegalDialog from "@camtv/front-base-ui/lit/legal-dialog"
import Validate from "@camtv/front-base-lib/utilities/validate"
import Cookies from "@camtv/front-base-lib/utilities/cookies"

import '@camtv/front-base-css/main.scss'

export default class CtvStoDialog_StepAuthCodeInput extends CtvDialogModalBodyModule {

	static get DomTag() { return  'ctv-kyc-dialog-step-authcode-input'; }

	GetTranslations() {
		return {
			"Annulla": {
				"it": "Annulla",
				"en": "Cancel",
				"de": "Abbrechen",
				"fr": "Annuler",
				"es": "Cancelar"
			},
			"Avanti": {
				"it": "Avanti",
				"en": "Next",
				"de": "Weiter",
				"fr": "Suivant",
				"es": "Siguiente"
			},
			"Conferma il tuo indirizzo email": {
				"it": "Conferma il tuo indirizzo email",
				"en": "Confirm your email address",
				"de": "Bestätige E-Mail-Adresse",
				"fr": "Confirmez votre adresse email",
				"es": "Confirme su dirección de correo electrónico"
			},
			"Inserisci il codice di verifica che hai ricevuto su {0} per confermare il tuo indirizzo email:": {
				"it": "Inserisci il codice di verifica che hai ricevuto su {0} per confermare il tuo indirizzo email:",
				"en": "Enter the verification code you received on {0} to confirm your email address:",
				"de": "Gib den Bestätigungscode ein, der an {0} gesendet wurde, um deine E-Mail-Adresse zu bestätigen:",
				"fr": "Saisissez le code de vérification que vous avez reçu à {0} pour confirmer votre adresse e-mail:",
				"es": "Ingrese el código de verificación que recibió en {0} para confirmar su dirección de correo electrónico:"
			},
			"Codice": {
				"it": "Codice",
				"en": "Code",
				"de": "Code",
				"fr": "Code",
				"es": "Código"
			},
			"Non hai ricevuto il codice? Attendi qualche minuto e <b>controlla la cartella dello SPAM</b>. Se non l’hai ricevuto ": {
				"it": "Non hai ricevuto il codice? Attendi qualche minuto e <b>controlla la cartella dello SPAM</b>. Se non l’hai ricevuto ",
				"en": "Didn't receive the code? Wait a few minutes and <b>check your SPAM folder</b>. If you haven't received it ",
				"de": "Code nicht erhalten? Warte einige Minuten und <b>überprüfe SPAM-Ordner</b>. Wenn Sie ihn erhalten haben ",
				"fr": "Vous n'avez pas reçu le code ? Attendez quelques minutes et <b>vérifiez votre dossier SPAM</b>. Si vous ne l'avez pas reçu ",
				"es": "¿No recibiste el código? Espere unos minutos y <b>revise su carpeta de SPAM</b>. Si no lo ha recibido "
			},
			"Richiedi un nuovo codice": {
				"it": "Richiedi un nuovo codice",
				"en": "Request a new code",
				"de": "Fordere einen neuen Code an",
				"fr": "Demander un nouveau code",
				"es": "Solicita un nuevo código"
			},
			"cambia email": {
				"it": "cambia email",
				"en": "change email",
				"de": "ändere die E-Mail",
				"fr": "changer l'e-mail",
				"es": "cambiar e-mail"
			},
			"o": {
				"it": "o",
				"en": "or",
				"de": "oder",
				"fr": "ou alors",
				"es": "o"
			},
			"Ricarica": {
				"it": "Ricarica",
				"en": "Refresh",
				"de": "Neu laden",
				"fr": "Recharger",
				"es": "Recargar"
			},
			"Codice non valido": {
				"it": "Codice non valido",
				"en": "Invalid code",
				"de": "Ungültiger Code",
				"fr": "Code invalide",
				"es": "Codigo invalido"
			}
		}
	}

	constructor(ParentModalDialog) {
		super(ParentModalDialog);
	}

	render() {
		return html`
			<h3 class="title center">${this.Ln("Conferma il tuo indirizzo email")}</h3>
			<p class="description">${this.Ln("Inserisci il codice di verifica che hai ricevuto su {0} per confermare il tuo indirizzo email:").replace("{0}", this.GetModalDialog().Data.email)}</p>
			<form @submit="${(evt) => { evt.preventDefault(); this._OnNext() }}">
				<div class="form-row">
					<div class="ctv-input-field">
						<input id="ctv-kyc-dialog-step-auth-code-input_CODE" 
							class="Code" 
							type="text" 							
							maxlength="6"
							required
							@focus="${(evt) => { evt.target.parentNode.classList.remove("error"); }}"
							/>
						<label for="ctv-kyc-dialog-step-auth-code-input_CODE">${this.Ln("Codice")}</label>
						<div class="error">Messagge Error!</div>
					</div>			
					<div class="small">
						${
							this.ToHtml(
								this.Ln("Non hai ricevuto il codice? Attendi qualche minuto e <b>controlla la cartella dello SPAM</b>. Se non l’hai ricevuto ")
							)
						}
						<a href="#" @click="${this._ResendCode}">${this.Ln("Richiedi un nuovo codice")}</a> ${this.Ln("o")} 
						<a href="#" @click="${this._Restart}">${this.Ln("cambia email")}</a>
					</div>
				</div>							
				
			</form>`			
	}

	async OnShow() {		
		let dlg = this.GetModalDialog();

		dlg.SetRightLink(null)
		dlg.SetButton(this.Ln("Avanti"), async () => { await this._OnNext() })
		dlg.show_back_link = true;
		this.querySelector("input.Code").focus()
		// this.querySelector(".TermsLink").addEventListener("click", this._ShowTerms);
		// this.querySelector(".PrivacyLink").addEventListener("click", this._ShowPrivacy);

	}

	async _Restart(evt) {
		evt.preventDefault()
		this.ShowWaiter(this.Ln("Ricarica"))
		document.location.reload()
	}

	async _ResendCode(evt) {
		evt.preventDefault()
		let dlg = this.GetModalDialog();
		dlg.SubmitEmail(true)
	}

	async _OnNext() {

		let dlg = this.GetModalDialog();
		
		let code = Types.ToString(this.querySelector("input.Code").value).trim() ;
		if (code.length != 6)
			dlg._SetFieldError(this.querySelector("input.Code"), this.Ln("Codice non valido"))

		dlg.Data.code = this.querySelector("input.Code").value;		

		if (await dlg.SubmitAuthCode() == false)
			dlg._SetFieldError(this.querySelector("input.Code"), this.Ln("Codice non valido"))
	}

}
CtvStoDialog_StepAuthCodeInput.RegisterElement();
