import { html } from 'lit-element';
import CtvKycDialog from "../sto-dialog"
import CtvAuthDialog from "../sto-dialog-auth"
import CtvStoAdminDialog from "../sto-admin-dialog"
import CtvStoDialogPostFooter from "../sto-dialog-post-footer"
import CtvLitElementBase from "@camtv/front-base-ui/lit/element-base"
import CtvDialogPopup from "@camtv/front-base-ui/lit/dialog-popup"
import CtvDropDown from "@camtv/front-base-ui/lit/dropdown"
import Controller from "./controller"
import CtvTestDialog from "../sto-dialog-test"
import Cookies from "@camtv/front-base-lib/utilities/cookies"
import LocalStorage from "@camtv/front-base-lib/utilities/localstorage"
import UrlQueryParams from "@camtv/front-base-lib/utilities/url-query-params"
import Lang from '@camtv/front-base-lib/utilities/lang';
import CtvLoadWaiter from '@camtv/front-base-ui/lit/load-waiter'


class CtvDropDownLang extends CtvLitElementBase {
    static get DomTag() { return  'ctv-dropdown-lang'; }

    static get properties() {
        return {
			image_num: {type: Number},
			text: { type: String },
        }
    }

    constructor() {
		super();
    }


    render() {

		let img_size = 60;
		let posx = -6 + this.image_num % 4 * (img_size+8+6);
		let posy = parseInt(this.image_num / 4) * (img_size+8);

		return html`
			<div class="item-row">
				<div style="
					background-image:url(https://image.freepik.com/free-vector/user-avatar-collection_23-2147546968.jpg);
					background-position: ${posx}px ${posy}px;
					background-size: 235px;
					width:${img_size}px;
					height:${img_size}px;					
					"></div>
				<div>${this.text}</div>
			</div>
		`
	}
}

CtvDropDownLang.RegisterElement();


window.addEventListener("load",async ()=>{

	//:: Da rimuovere
	if (Types.ToString(Cookies.Get("test")) != "") {
		await (new CtvTestDialog()).Show()
		return 
	}

	if (Types.ToString(Cookies.Get("test1")) != "") {
		window.dialog = new CtvKycDialog();	
		let ret = await window.dialog.Show(window.AuthToken);	
		return;
	}
	//:: Da rimuovere

	// Caso ingresso da camtv
	if(Types.ToString(UrlQueryParams.Get("AuthToken")) != ""){
		let ATk = UrlQueryParams.Get("AuthToken")
		LocalStorage.Set("AuthToken", ATk);
		Cookies.Set("AUTH_TOKEN", ATk)
	}

	let Data = null
	try {
		window.AuthToken = Types.ToString(LocalStorage.Get("AuthToken"))
		Data = await window.Controller.Api.Kyc.Get(window.AuthToken);
		Cookies.Set("AUTH_TOKEN", window.AuthToken)

		if (Data != null && Data.Status=="COMPLETED"){
			let dt = Types.ToDate(Data["CompletedDateTime"])
			if (Types.IsDateEmpty(dt) == false) {
				let currentTime = (new Date().getTime())
				if ( (currentTime - dt.getTime()) > 1 * 60 *1000) {
					let res = await window.Controller.Api.Kyc.New(window.AuthToken);
					LocalStorage.Set("AuthToken", res.AUTH_TOKEN)
					Cookies.Set("AUTH_TOKEN", res.AUTH_TOKEN)
					document.location.href = document.location.origin + document.location.pathname
				}
			}
		}
	}
	catch {}

	if (Data == null) {

		// let env = "https://www.cam.tv"
		// if (document.location.href.indexOf("test.") >= 0)
		// 	env = "https://www.testcam.tv"

		// CTV.ExternalLogin(env+"/api/legal/sto/login", null, null, null, null, null, true);
		// return		

		let DgAuth = new CtvAuthDialog();
		DgAuth.Data.auth_token = window.AuthToken

		while (await DgAuth.Show() != "OK") { } 
		
		window.AuthToken = DgAuth.Data.auth_token;
		LocalStorage.Set("AuthToken",window.AuthToken)
		Cookies.Set("AUTH_TOKEN", window.AuthToken)
	}		
		
	let dlg = new CtvKycDialog();	
	document.querySelector(".logout-link").classList.remove("hidden")

	let ret = await dlg.Show(window.AuthToken,Data);
	LocalStorage.Set("AuthToken",dlg.AuthToken)
	Cookies.Set("AUTH_TOKEN", dlg.AuthToken)
	document.location.href = document.location.origin + document.location.pathname
})

window.Logout = async ()=>{
	let ret = ""
	if (Lang.Get() == "it")
		ret = await CtvDialogPopup.ShowYesNoBox("Logout", "Confermi il logout?", "Si", "Annulla")
	else
		ret = await CtvDialogPopup.ShowYesNoBox("Logout", "Do you want to logout?", "Yes", "Cancel")

	if (ret == "YES") {
		let w = CtvLoadWaiter.Show();
		Cookies.Delete("AUTH_TOKEN");
		LocalStorage.Delete("AuthToken")		
		document.location.href = document.location.origin + document.location.pathname
		return;
	}
}

