import {html} from "lit-element";
import CtvLitElementBase from "@camtv/front-base-ui/lit/element-base"
import CtvDialogModal from "@camtv/front-base-ui/lit/dialog-modal"
import CtvDialogPopup from "@camtv/front-base-ui/lit/dialog-popup"
import CtvLoadWaiter from '@camtv/front-base-ui/lit/load-waiter'
import CtvDialogModalBodyModule from "@camtv/front-base-ui/lit/dialog-modal-body-module"
import CtvLegalDialog from "@camtv/front-base-ui/lit/legal-dialog"
import Validate from "@camtv/front-base-lib/utilities/validate"
import Cookies from "@camtv/front-base-lib/utilities/cookies"


import '@camtv/front-base-css/main.scss'
import Controller from "./tests/controller";

export default class CtvStoDialog_StepWelcome extends CtvDialogModalBodyModule {

	static get DomTag() { return  'ctv-kyc-dialog-step-welcome'; }

	get StepName() { return  'INVEST_AMOUNT_INPUT'; }
	get StepLabel() { return  this.Ln('Benvenuto'); }

	GetTranslations() {
		return {
			"Avanti": {
				"it": "Avanti",
				"en": "Next",
				"de": "Weiter",
				"fr": "Suivant",
				"es": "Siguiente"
			},
			"Procedura avviata correttamente": {
				"it": "Procedura avviata correttamente",
				"en": "Procedure started successfully",
				"de": "Verfahren erfolgreich gestartet",
				"fr": "Procédure démarrée avec succès",
				"es": "El procedimiento se inició con éxito"
			},
			"Prima di procedere prepara tutti i documenti che ti serviranno per completare la procedura di acquisto dei tuoi CAM Token:": {
				"it": "Prima di procedere prepara tutti i documenti che ti serviranno per completare la procedura di acquisto dei tuoi CAM Token:",
				"en": "Before proceeding, prepare all the documents you will need to complete the purchase procedure of your CAM Token:",
				"de": "Bereite vor dem Fortfahren alle Dokumente vor, die du benötigst, um den Kaufvorgang deiner CAM-Token abzuschließen:",
				"fr": "Avant de continuer, préparez tous les documents dont vous aurez besoin pour compléter la procédure d'achat de vos CAM Token :",
				"es": "Antes de continuar, prepare todos los documentos que necesitará para completar el procedimiento de compra de sus CAM Token:"
			},
			"IBAN su cui accreditare le royalties": {
				"it": "IBAN su cui accreditare le royalties",
				"en": "IBAN on which to credit the royalties",
				"de": "IBAN, auf die die Lizenzgebühren gutgeschrieben werden",
				"fr": "IBAN sur lequel créditer les royalties",
				"es": "IBAN sobre el que acreditar las regalías"
			},
			"indirizzo ETH di accredito dei CAM Token (se non ce l’hai ti aiutiamo a crearlo)": {
				"it": "indirizzo ETH di accredito dei CAM Token (se non ce l’hai ti aiutiamo a crearlo)",
				"en": "ETH address for crediting the CAM Token (if you don't have it, we'll help you create it)",
				"de": "ETH-Adresse für die Gutschrift der CAM-Token (wenn du keine hast, helfen wir dir bei der Erstellung)",
				"fr": "Adresse ETH pour créditer les CAM Token (si vous ne l'avez pas, nous vous aiderons à le créer)",
				"es": "Dirección ETH para acreditar los CAM Token (si no la tiene, le ayudaremos a crearla)"
			},
			"Prepara i seguenti dati e documenti per ottenere più velocemente i tuoi CAM Token": {
				"it": "Prepara i seguenti dati e documenti per ottenere più velocemente i tuoi CAM Token",
				"en": "Prepare the following data and documents to get your CAM Token faster",
				"de": "Bereite die folgenden Daten und Dokumente vor, um deine CAM-Token schneller zu erhalten",
				"fr": "Préparez les données et documents suivants pour obtenir vos CAM Token plus rapidement",
				"es": "Prepare los siguientes datos y documentos para obtener sus CAM Token más rápido"
			},
			"Prepara i seguenti dati per completare la procedura": {
				"it": "Prepara i seguenti dati per completare la procedura",
				"en": "Prepare the following data to complete the procedure",
				"de": "Bereite die folgenden Daten vor, um das Verfahren abzuschließen",
				"fr": "Préparez les données suivantes pour terminer la procédure",
				"es": "Prepare los siguientes datos para completar el procedimiento"
			},
			"Documento di identità, patente o passaporto": {
				"it": "Documento di identità, patente o passaporto",
				"en": "Identity document, driving license or passport",
				"de": "Ausweis, Führerschein oder Reisepass",
				"fr": "Pièce d'identité, permis de conduire ou passeport",
				"es": "Documento de identidad, carnet de conducir o pasaporte"
			},
			"Bolletta recente (luce, acqua o gas) oppure estratto conto bancario": {
				"it": "Bolletta recente (luce, acqua o gas) oppure estratto conto bancario",
				"en": "Recent bill (electricity, water or gas) or bank statement",
				"de": "Aktuelle Rechnung (Strom, Wasser oder Gas) oder Kontoauszug",
				"fr": "Facture récente (électricité, eau ou gaz) ou relevé bancaire",
				"es": "Factura reciente (luz, agua o gas) o extracto bancario"
			}
		}
	}

	constructor(ParentModalDialog) {
		super(ParentModalDialog);

	}

	render() {
		

		return html`
		<div class="success-icon">
			<svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg"><path d="m54 30c0 13.254834-10.745166 24-24 24s-24-10.745166-24-24 10.745166-24 24-24c2.28 0 4.5.33 6.6.93l4.71-4.71c-3.48-1.44-7.29-2.22-11.31-2.22-16.5685425 0-30 13.4314575-30 30 0 7.9564947 3.16070521 15.5871121 8.78679656 21.2132034 5.62609134 5.6260914 13.25670874 8.7867966 21.21320344 8.7867966s15.5871121-3.1607052 21.2132034-8.7867966c5.6260914-5.6260913 8.7867966-13.2567087 8.7867966-21.2132034m-42.27-5.76-4.23 4.26 13.5 13.5 30-30-4.23-4.26-25.77 25.77z" fill="#5fb900"/></svg>
		</div>
		<h3 class="title center">${this.Ln("Procedura avviata correttamente")}</h3>
		${this._RenderBullets()}
		`		
	}

	_RenderBullets() {
		let dlg = this.GetModalDialog();
		if (dlg.Data.status == "ACCEPTED") {
			return html`
				<p class="description">${this.Ln("Prepara i seguenti dati per completare la procedura")}</p>
				<ul class="document-list">
					<li>
						<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><rect fill="#FF7D00" width="22" height="22" rx="10.8"/><path fill="#FFF" fill-rule="nonzero" d="M8.939 16L5 12.004l1.795-1.821 2.144 2.181L15.205 6 17 7.821z"/></g></svg>
						<span>${this.Ln("IBAN su cui accreditare le royalties")}</span>
					</li>
					<li>
						<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><rect fill="#FF7D00" width="22" height="22" rx="10.8"/><path fill="#FFF" fill-rule="nonzero" d="M8.939 16L5 12.004l1.795-1.821 2.144 2.181L15.205 6 17 7.821z"/></g></svg>
						<span>${this.Ln("indirizzo ETH di accredito dei CAM Token (se non ce l’hai ti aiutiamo a crearlo)")}</span>
					</li>
				</ul>		
			`
		}

		return html`
				<p class="description">${this.Ln("Prima di procedere prepara tutti i documenti che ti serviranno per completare la procedura di acquisto dei tuoi CAM Token:")}</p>
				<ul class="document-list">
					<li>
						<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><rect fill="#FF7D00" width="22" height="22" rx="10.8"/><path fill="#FFF" fill-rule="nonzero" d="M8.939 16L5 12.004l1.795-1.821 2.144 2.181L15.205 6 17 7.821z"/></g></svg>
						<span>${this.Ln("Documento di identità, patente o passaporto")}</span>
					</li>
					<li>
						<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><rect fill="#FF7D00" width="22" height="22" rx="10.8"/><path fill="#FFF" fill-rule="nonzero" d="M8.939 16L5 12.004l1.795-1.821 2.144 2.181L15.205 6 17 7.821z"/></g></svg>
						<span>${this.Ln("Bolletta recente (luce, acqua o gas) oppure estratto conto bancario")}</span>
					</li>
				</ul>		
			` 
	}

	async OnShow() {		
		let dlg = this.GetModalDialog();

		dlg.SetRightLink()
		dlg.SetButton(this.Ln("Avanti"), async () => { await this._OnNext() })
		dlg.show_back_link = false;
	}

	async _OnNext() {

		let dlg = this.GetModalDialog();

		dlg.Wizard_Start()
	}

}
CtvStoDialog_StepWelcome.RegisterElement();
