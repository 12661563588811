import { html } from "lit-element";
import CtvLitElementBase from "@camtv/front-base-ui/lit/element-base"
import CtvDialogModal from "@camtv/front-base-ui/lit/dialog-modal"
import CtvDialogPopup from "@camtv/front-base-ui/lit/dialog-popup"
import CtvLoadWaiter from '@camtv/front-base-ui/lit/load-waiter'
import CtvDialogModalBodyModule from "@camtv/front-base-ui/lit/dialog-modal-body-module"
import CtvLegalDialog from "@camtv/front-base-ui/lit/legal-dialog"
import Validate from "@camtv/front-base-lib/utilities/validate"
import Cookies from "@camtv/front-base-lib/utilities/cookies"


import '@camtv/front-base-css/main.scss'
import Controller from "./tests/controller";

export default class CtvStoDialog_StepInvestAmountInput extends CtvDialogModalBodyModule {

	static get DomTag() { return 'ctv-kyc-dialog-step-invest-amount-input'; }

	get StepName() { return 'INVEST_AMOUNT_INPUT'; }
	get StepLabel() { return this.Ln('Importo'); }

	GetTranslations() {
		return {
			"Importo €.": {
				"it": "Importo €.",
				"en": "Amount €.",
				"de": "Betrag €.",
				"fr": "Montant €.",
				"es": "Importe €."
			},
			"Annulla": {
				"it": "Annulla",
				"en": "Cancel",
				"de": "Abbrechen",
				"fr": "Annuler",
				"es": "Cancelar"
			},
			"Avanti": {
				"it": "Avanti",
				"en": "Next",
				"de": "Weiter",
				"fr": "Suivant",
				"es": "Siguiente"
			},
			"Le password non coincidono": {
				"it": "Le password non coincidono",
				"en": "Passwords do not match",
				"de": "Passwörter stimmen nicht überein",
				"fr": "Mot de passe erroné",
				"es": "Las contraseñas no coinciden"
			},
			"Devi accettare per proseguire": {
				"it": "Devi accettare per proseguire",
				"en": "You have to accept to continue",
				"de": "Sie müssen akzeptieren, um fortzufahren",
				"fr": "Vous devez accepter pour continuer",
				"es": "Tienes que aceptar antes de continuar"
			},
			"Quanto desideri investire?": {
				"it": "Quanto desideri investire?",
				"en": "How much do you want to invest?",
				"de": "Wie viel möchtest du investieren?",
				"fr": "Combien voulez-vous investir ?",
				"es": "¿Cuánto quieres invertir?"
			},
			"Importo non valido": {
				"it": "Importo non valido",
				"en": "Amount not valid",
				"de": "Ungültige Menge",
				"fr": "Montant invalide",
				"es": "Monto invalido"
			},
			"Importo minimo € 100": {
				"it": "Importo minimo € 100",
				"en": "Minimum amount € 100",
				"de": "Mindestbetrag 100 €",
				"fr": "Montant minimum 100 €",
				"es": "Importe mínimo 100 €"
			},
			"Scrivi l’importo in EURO che desideri investire:": {
				"it": "Scrivi l’importo in EURO che desideri investire:",
				"en": "Write the amount in EURO you wish to invest:",
				"de": "Schreibe den Betrag in EURO, den du investieren möchtest:",
				"fr": "Inscrivez le montant en EURO que vous souhaitez investir :",
				"es": "Escriba la cantidad en EURO que desea invertir:"
			},
			"Pari a {{ tk }} CAM Token": {
				"it": "Pari a {{ tk }} CAM Token",
				"en": "Equal to {{ tk }} CAM Token",
				"de": "Entspricht {{ tk }} CAM-Token",
				"fr": "Égal à {{ tk }} CAM Token",
				"es": "Igual a {{ tk }} CAM Token"
			}
		}
	}

	constructor(ParentModalDialog) {
		super(ParentModalDialog);
	}

	render() {
		let dlg = this.GetModalDialog();
		let value = ""
		if (dlg.Data.invest_amount_euro > 0)
			value = dlg.Data.invest_amount_euro

		return html`
			<h3 class="title">${this.Ln("Quanto desideri investire?")}</h3>
			<p class="description">${this.Ln("Scrivi l’importo in EURO che desideri investire:")}</p>
			<form autocomplete="off" @submit="${(evt) => { evt.preventDefault(); this._OnNext() }}" >
				<div class="form-row">
					<div class="ctv-input-field">
						<input id="ctv-kyc-dialog-step-invest-amount-input_AMOUNT" 
							class="Amount" 
							type="amount" 							
							maxlength="100" 
							data-validate="number" 
							required
							autocomplete="off"
							value="${value}"
							@focus="${(evt) => { evt.target.parentNode.classList.remove("error"); }}"
							@keyup="${() => { this.UpdateTokenAmount() }}"
							/>
						<label for="ctv-kyc-dialog-step-invest-amount-input_AMOUNT">${this.Ln("Importo €.")}</label>
						<div class="error">Messagge Error!</div>					
					</div>			
				</div>							
				<p class="description" style="margin-top:0;">
					<b class="TokenEquivalent hidden"></b>
				</p>
			</form>
			`
	}

	async UpdateTokenAmount() {
		let dlg = this.GetModalDialog();

		let elTokenEquivalent = this.querySelector(".TokenEquivalent")
		let val = Types.ToFloat(this.querySelector("input.Amount").value);
		if (val >= 100) {
			elTokenEquivalent.classList.remove("hidden")
			let tk = dlg.GetTokeAmountFromInvestAmountAndDate(val)
			elTokenEquivalent.innerHTML = this.Ln("Pari a {{ tk }} CAM Token", { "tk": tk })
		}
		else
			elTokenEquivalent.classList.add("hidden")
	}

	async OnShow() {
		let dlg = this.GetModalDialog();

		dlg.SetRightLink()
		dlg.SetButton(this.Ln("Avanti"), async () => { await this._OnNext() })
		dlg.show_back_link = false;
		dlg.SetPostFooter(false)
		setTimeout(() => {
			this.UpdateTokenAmount()
			this.querySelector(".Amount").focus()
		}, 250)
	}

	async _SaveAndValidateData(IsAdmin) {
		let dlg = this.GetModalDialog();

		if (IsAdmin != true && Validate.ValidateForm(this.querySelector("form"), true, false, dlg._SetFieldError) != true)
			return false;

		let val = this.querySelector("input.Amount").value;
		if (IsAdmin != true && val < 100) {
			dlg._SetFieldError(this.querySelector("input.Amount"), this.Ln("Importo minimo € 100"))
			return false
		}

		dlg.Data.invest_amount_euro = val
		dlg.Data.step = this.StepName

		return true;
	}

	async _OnNext() {

		if (await this._SaveAndValidateData() != true)
			return false;

		let dlg = this.GetModalDialog();
		if (await dlg.SaveStep() == false)
			return false;

		if (dlg.Data.invest_amount_euro >= 100000) {
			document.location.href = "https://kyc.blackmanta.capital"
			let w = this.ShowWaiter()
			return
		}


		dlg.Wizard_PageNext()
	}

}
CtvStoDialog_StepInvestAmountInput.RegisterElement();
