
import ApiCaller from "@camtv/front-base-lib/io/api-caller"

let Controller = 
{
	Api : {

		Kyc: {

			New: (AuthCode) => {
				return ApiCaller.DoIO("/api/kyc/v1/new",{ },30000,{"Authorization": "Bearer "+AuthCode})
			},

			Get: (AuthCode) => {
				// return new Promise((resolve)=>{
				// 	setTimeout(()=>{
				// 		resolve({
				// 			Email: "ivpcode@gmail.com",
				// 			Password: "",
				// 			CountryIsoCode : "ITA",
				// 			FirstName: "Ivan",
				// 			LastName: "Prez",
				// 			Gender: "M",
				// 			Address: "via De Gasperi, 24",
				// 			City: "Gonars",
				// 			ZipCode: "33050",
				// 			Province: "UD",
				// 			DocumentType:"ID_CARD",
				// 			DocumentNumber: "12345",
				// 			DocumentExpirationDate: "30/02/2030",
				// 			Telephone : "3405451443",
				// 			BornDate : "22/06/1973",
				// 			FiscalCode : "PRZVNI...",
				// 			ProofOfFunds : "SAVINGS",
				// 			Occupation : "SELF_EMPLOYED",
				// 			PlatformUse : "INVESTMENTS",
				// 			ExchangeAmount : "EUR_1000_TO_9999",
				// 			PoliticallyExposed : "NO",
				// 			DocumentPhotoFrontUrl: "",
				// 			DocumentPhotoBackUrl: "",
				// 			SelfieWithDocumentUrl: "",
				// 			UtilityBillPhotoUrl: "",
				// 			AdminReviewJson: "",				
				// 		}	);
				// 	},500)
				// })
				return ApiCaller.DoIO("/api/kyc/v1/get",{},30000,{"Authorization": "Bearer "+AuthCode})
			},

			Save: (AuthCode, Data) => {
				return ApiCaller.DoIOJson("/api/kyc/v1/save",Data,60000,{"Authorization": "Bearer "+AuthCode})
			},

			RegisterEmail: (Email,Lang) => {
				return ApiCaller.DoIO("/api/kyc/v1/auth/get",
					{ Email : Email, Lang:Lang},)
			},

			CodeCheck: (Email, Code) => {
				return ApiCaller.DoIO("/api/kyc/v1/auth/check",
					{ Email : Email, Code: Code },
				)
			},

			Admin: {
				Login: (_Email, _Password) => {
					return ApiCaller.DoIO("/api/kyc/v1/admin/login",{Email: _Email, Password: _Password})
				},

				Save: (AuthCode, Data) => {
					return ApiCaller.DoIOJson("/api/kyc/v1/save",Data,60000,{"Authorization": "Bearer "+AuthCode})
				},
				
				SetAccepted: (AuthCode, Accepted) => {
					return ApiCaller.DoIO("/api/kyc/v1/admin/accepted/set",{"Accepted":Accepted},60000,{"Authorization": "Bearer "+AuthCode})
				},				

				List: (AuthCode, Filters) => {
					return ApiCaller.DoIO("/api/kyc/v1/admin/get/list",{ DevExpressDataSourceFilters: JSON.stringify(Filters) },60000,{"Authorization": "Bearer "+AuthCode})
				},

				Note: (AuthCode, Note) => {
					return ApiCaller.DoIO("/api/kyc/v1/admin/set/notes", {Note: Note} ,60000,{"Authorization": "Bearer "+AuthCode})
				},
				
				HideRow: (AuthCode) => {
					return ApiCaller.DoIO("/api/kyc/v1/admin/set/hide_row", { } ,60000,{"Authorization": "Bearer "+AuthCode})
				},

				UpdateTokenStatus:(AuthCode, Status) =>{
					return ApiCaller.DoIO("/api/kyc/v1/admin/set/token_deploy_status", { TokenStatus: Status} ,60000,{"Authorization": "Bearer "+AuthCode})
				}

			}
		}
	}
}

export default Controller;
window.Controller = Controller;