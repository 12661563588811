import {html} from "lit-element";
import CtvLitElementBase from "@camtv/front-base-ui/lit/element-base"
import CtvDialogModal from "@camtv/front-base-ui/lit/dialog-modal"
import CtvDialogPopup from "@camtv/front-base-ui/lit/dialog-popup"
import CtvLoadWaiter from '@camtv/front-base-ui/lit/load-waiter'
import CtvDialogModalBodyModule from "@camtv/front-base-ui/lit/dialog-modal-body-module"
import CtvLegalDialog from "@camtv/front-base-ui/lit/legal-dialog"
import Validate from "@camtv/front-base-lib/utilities/validate"
import Cookies from "@camtv/front-base-lib/utilities/cookies"

import '@camtv/front-base-css/main.scss'

export default class CtvStoDialog_StepEmailInput extends CtvDialogModalBodyModule {

	static get DomTag() { return  'ctv-kyc-dialog-step-email-input'; }

	GetTranslations() {
		return {
			"Inizia facilmente a fare trading in Hashrate Labs": {
				"it": "Inizia facilmente a fare trading in Hashrate Labs",
				"en": "Start trading easily in Hashrate Labs",
				"de": "Einfacher Einstieg ins trading mit Hashrate Labs",
				"fr": "Commencer à trader facilement dans Hashrate Labs",
				"es": "Empieza a operar fácilmente en Hashrate Labs"
			},
			"E-mail": {
				"it": "E-mail",
				"en": "E-mail",
				"de": "E-Mail",
				"fr": "E-mail",
				"es": "E-mail"
			},
			"Accetta termini": {
				"it": "Accetta termini",
				"en": "Accept terms", 
				"de": "Bedingungen akzeptieren",
				"fr": "Accepter les termes",
				"es": "Aceptar condiciones"
			},
			"Dichiaro di aver compiuto 18 anni e di accettare i {TERMS} e la {PRIVACY_POLICY}.": {
				"it": "Dichiaro di aver compiuto 18 anni e di accettare i {TERMS} e la {PRIVACY_POLICY}.",
				"en": "I declare that I am over the age of eighteen (18) years and that I accept the {TERMS} and the {PRIVACY_POLICY}.",
				"de": "Ich bestätige, dass ich 18 Jahre alt oder älter bin und die {TERMS} und die {PRIVACY_POLICY} akzeptiere",
				"fr": "Je certifie avoir 18 ans ou plus et accepte les {TERMS} et la {PRIVACY_POLICY}",
				"es": "Tengo una edad mínima de 18 años y acepto los {TERMS} y la {PRIVACY_POLICY}"
			},
			"Politica sui Cookie": {
				"it": "Politica sui Cookie",
				"en": "Cookie Policy",
				"de": "Cookie-Richtlinie",
				"fr": "Politique relative aux cookies",
				"es": "Política de cookies"
			},
			"Politica sulla Privacy": {
				"it": "Politica sulla Privacy",
				"en": "Privacy Policy",
				"de": "Datenschutzrichtlinie",
				"fr": "Politique de confidentialité",
				"es": "Política de privacidad"
			},
			"Annulla": {
				"it": "Annulla",
				"en": "Cancel",
				"de": "Abbrechen",
				"fr": "Annuler",
				"es": "Cancelar"
			},
			"Avanti": {
				"it": "Avanti",
				"en": "Next",
				"de": "Weiter",
				"fr": "Suivant",
				"es": "Siguiente"
			},
			"Devi accettare per proseguire": {
				"it": "Devi accettare per proseguire",
				"en": "You have to accept to continue",
				"de": "Sie müssen akzeptieren, um fortzufahren",
				"fr": "Vous devez accepter pour continuer",
				"es": "Tienes que aceptar antes de continuar"
			},
			"Indirizzo email": {
				"it": "Indirizzo email",
				"en": "Email address",
				"de": "E-Mail-Adresse",
				"fr": "Adresse e-mail",
				"es": "Dirección de correo electrónico"
			},
			"Scrivi il tuo indirizzo email. Riceverai un codice di verifica da inserire nella pagina seguente:": {
				"it": "Scrivi il tuo indirizzo email. Riceverai un codice di verifica da inserire nella pagina seguente:",
				"en": "Write your email address. You will receive a verification code to enter on the following page:",
				"de": "Schreibe E-Mail-Adresse. Du erhältst einen Bestätigungscode, den du auf der folgenden Seite eingeben musst:",
				"fr": "Écrivez votre adresse e-mail. Vous recevrez un code de vérification à saisir sur la page suivante :",
				"es": "Escriba su dirección de correo electrónico. Recibirá un código de verificación para ingresar en la siguiente página:"
			}
		}
	}

	constructor(ParentModalDialog) {
		super(ParentModalDialog);
	}

	render() {
		return html`
			<h3 class="title">${this.Ln("Indirizzo email")}</h3>
			<p class="description">${this.Ln("Scrivi il tuo indirizzo email. Riceverai un codice di verifica da inserire nella pagina seguente:")}</p>
			<form @submit="${(evt) => { evt.preventDefault(); this._OnNext() }}">
				<div class="form-row">
					<div class="ctv-input-field">
						<input id="ctv-kyc-dialog-step-email-input_EMAIL" 
							class="Email" 
							type="email" 							
							maxlength="100" 
							data-validate="email, repeatedchar" 							
							autocomplete="username"
							@focus="${(evt) => { evt.target.parentNode.classList.remove("error"); }}"
							/>
						<label for="ctv-kyc-dialog-step-email-input_EMAIL">${this.Ln("E-mail")}</label>
						<div class="error">Messagge Error!</div>					
					</div>			
				</div>							
				<div class="terms">
					<div class="ctv-input-field">
						<div class="options">
							<div>
								<input type="checkbox" id="ctv-kyc-dialog-step-email-input_TERMS" 
									class="Terms" 
									required 
									@click="${(evt) => { evt.target.parentNode.parentNode.parentNode.classList.remove("error"); }}"
								/>
								<label for="ctv-kyc-dialog-step-email-input_TERMS" title="${this.Ln("Accetta termini")}">
									${
										this.ToHtml(
											this.Ln("Dichiaro di aver compiuto 18 anni e di accettare i {TERMS} e la {PRIVACY_POLICY}.")
											.replace("{TERMS}", `<a class="TermsLink" data-lang="${this.lang}" href="#">${this.Ln("Politica sui Cookie")}</a>`)
											.replace("{PRIVACY_POLICY}", `<a class="PrivacyLink" data-lang="${this.lang}" href="#">${this.Ln("Politica sulla Privacy")}</a>`)
										)
									}
								</label>
							</div>
						</div>
						<div class="error">Messagge Error!</div>
					</div>
				</div>
			</form>
			`
			
	}

	async OnShow() {		
		let dlg = this.GetModalDialog();

		dlg.SetRightLink(null)
		dlg.SetButton(this.Ln("Avanti"), async () => { await this._OnNext() })
		dlg.show_back_link = false;

		this.querySelector("input.Email").focus()
		this.querySelector(".TermsLink").addEventListener("click", (e)=>{ this._ShowTerms(e) });
		this.querySelector(".PrivacyLink").addEventListener("click", (e)=>{ this._ShowPrivacy(e) });
	}

	async _OnNext() {

		let dlg = this.GetModalDialog();

		if (Validate.ValidateForm(this.querySelector("form"), true, false, dlg._SetFieldError)!= true)
			return false;

		let Terms = this.querySelector("input.Terms").checked
		if (Terms!=true) {
			dlg._SetFieldError(this.querySelector("input.Terms").parentNode.parentNode, this.Ln("Devi accettare per proseguire"))
			return false;
		}

		dlg.Data.email = this.querySelector("input.Email").value;		
		
		await dlg.SubmitEmail();
	}

	async _ShowTerms(evt) {
		evt.preventDefault();

		//let lang = evt.currentTarget.getAttribute("data-lang");
		//await CtvLegalDialog.ShowTerms(lang);

		if (this.lang == "it")
			window.open("https://www.iubenda.com/privacy-policy/39190790/cookie-policy");
		else
			window.open("https://www.iubenda.com/privacy-policy/19315769/cookie-policy");		
	}

	async _ShowPrivacy(evt) {
		evt.preventDefault();

		//let lang = evt.currentTarget.getAttribute("data-lang");
		//await CtvLegalDialog.ShowPrivacy(lang);

		if (this.lang == "it")
			window.open("https://www.iubenda.com/privacy-policy/39190790");
		else
			window.open("https://www.iubenda.com/privacy-policy/19315769");
	}		
}
CtvStoDialog_StepEmailInput.RegisterElement();
